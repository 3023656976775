import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import {
  Button,
  Menu,
  MenuItem,
  Alert,
  Container,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { userService } from "../services/userdetails.service";
import { accountService } from "../services";
interface IdTokenClaims {
  roles: string[];
}
export const AzureSignIn = () => {
  const { instance, accounts } = useMsal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogin = async (loginType: string) => {
    setLoading(true);
    try {
      if (loginType === "popup") {
        const response = await instance.loginPopup(loginRequest);

        let idTokenClaims = response.idTokenClaims as IdTokenClaims;

        let role = idTokenClaims?.roles[0];
        // Successfully signed in using popup
        const email = response.account?.username; // Retrieve email from account object
        const name = response.account?.name;
        // Assuming accountService.adminSignin returns a promise
        const userResponse = await accountService.adminSignin(
          email,
          response.idToken,

          name,
          role
        );
        setLoading(false);
        if (userResponse) {
          navigate("/");
        } else {
          setError("Failed to sign in using popup,Authenticate yourself");
        }
      }
    } catch (error) {
      if (loginType === "popup") {
        setError("Failed to sign in using popup.");
      } else if (loginType === "redirect") {
        setError("Failed to sign in using redirect.");
      }
      console.error(error);
    } finally {
      handleClose();
    }
  };

  return (
    <div>
      {loading ? (
        <Container>
          <CircularProgress />
        </Container>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleLogin("popup")}
        >
          Sign In Using SSO
        </Button>
      )}

      {error && <Alert severity="error">{error}</Alert>}
    </div>
  );
};
