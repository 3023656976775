/** @format */

import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  TextField,
  Button,
  Snackbar,
  Box,
  Typography,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { settingsService } from "../../services/settings.service";
import { accountService } from "../../services";
import { Login } from "@mui/icons-material";

function Learn() {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({ myInput: "" });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const fetchPrompts = async () => {
      setLoading(true);
      try {
        const storageNameResponse = await accountService.getStorageName();

        const response = await fetch(
          `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/learn.json`
        );
        const data = await response.json();

        // Format the questions and answers into a single string
        const formattedData = data
          .map((item: any) => `${item.question}\nAnswer: ${item.answer}\n\n`)
          .join("");
        // Debug: Check the formatted data
        setInitialValues({ myInput: formattedData });
      } catch (error) {
        console.error("Failed to fetch prompts:", error);
        setSnackbarMessage("Failed to fetch prompts. Please try again later.");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchPrompts();
  }, []);
  function parseQAPairs(text: any) {
    const pairs = text.trim().split("\n\n");
    const qaPairs = pairs.map((pair: any) => {
      const parts = pair.split("\nAnswer:");
      return {
        question: parts[0].trim(),
        answer: parts[1].trim(),
      };
    });
    return qaPairs;
  }

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setSubmitLoading(true);

    try {
      // Parse the input text to structured JSON
      const qaPairs = parseQAPairs(values.myInput);

      const updateResponse = await settingsService.updateLearnSettings(qaPairs);
      let { message, updatedData, status } = updateResponse;
      if (status === true) {
        setInitialValues(updatedData);
        setSnackbarOpen(true);
        setSnackbarMessage(message);
      } else {
        setInitialValues(initialValues);
        setSnackbarOpen(true);
        setSnackbarMessage(message);
      }
    } catch (error) {
      console.error("Submission error:", error);
    } finally {
      setSubmitLoading(false);
      setSubmitting(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box p={4} style={{ backgroundColor: "#f0f0f0", height: 500 }}>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: "700" }}>
          Learn Settings
        </Typography>
      </Container>
      {loading ? (
        <CircularProgress />
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize // Make sure Formik resets with new initialValues
          validationSchema={yup.object({
            myInput: yup.string().required("Required"),
          })}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={3} justifyContent="center">
                {/* Form fields for each prompt */}

                <Grid item xs={12} md={10}>
                  <Field
                    as={TextField}
                    label={"Learn Text"} // Enhances readability of labels
                    name={"myInput"}
                    multiline
                    rows={15}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {submitLoading ? (
                    <CircularProgress></CircularProgress>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      style={{ bottom: 10, left: 0, width: "85%" }}
                    >
                      Submit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Box>
  );
}

export default Learn;
