export async function extractJson(text: any) {
  let braceCount = 0;
  let startIndex = text.indexOf("{");
  if (startIndex === -1) {
    return ""; // No JSON object found
  }

  for (let i = startIndex; i < text.length; i++) {
    if (text[i] === "{") {
      braceCount++;
    } else if (text[i] === "}") {
      braceCount--;
      if (braceCount === 0) {
        return text.substring(startIndex, i + 1);
      }
    }
  }

  return ""; // No matching closing brace found
}
