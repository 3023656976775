/** @format */

const initialPhysicalData = {
  steps: [
    {
      dateTime: "",
      steps: 0,
    },
  ],
  water: [
    {
      dateTime: "",
      water: 0,
    },
  ],
  weights: [
    {
      dateTime: "",
      weight: 0,
    },
  ],
  sleep: [
    {
      id: 0,
      sleep_session: "",
      sleep_asleep: "",
      sleep_awake: "",
      sleep_deep: "",
      sleep_rem: "",
      sleep_light: "",
      sleep_out_of_bed: "",
      sleep_in_bed: "",
      dateTime: "",
      start_dt: "",
      end_dt: "",
      date: "",
      day: "",
    },
  ],
};

//// Function for reseting the reseting the physicaldata set-----------------/
export function resetPhysicalData() {
  return initialPhysicalData;
}
