import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";

const baseUrl = `${config.apiUrl}/api`;

//// const notes services --------------------------------------------------------------/
export const notesService = {
  fetchNotes,
  updateNotes,
  deleteNotes,
  editNote,
};

//// Function for fetching the notes from the db --------------------------------------------------------/
async function fetchNotes(userId: string, loggedUserId: string) {
  return fetchWrapper.get(
    `${baseUrl}/get-notes?userId=${userId}&creatorId=${loggedUserId}`
  );
}

//// Function for fetching the notes from the db --------------------------------------------------------/
async function updateNotes(data: any) {
  return fetchWrapper.post(`${baseUrl}/update-notes?`, { ...data });
}

//// Funciton for deleting the notes from the db -------------------------------------------------------/
async function deleteNotes(data: any) {
  return fetchWrapper.post(`${baseUrl}/delete-notes?`, { ...data });
}

//// Funciton for deleting the notes from the db -------------------------------------------------------/
async function editNote(data: any) {
  return fetchWrapper.put(`${baseUrl}/edit-note?`, { ...data });
}
