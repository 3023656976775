import {
  Box,
  Button,
  CircularProgress,
  TextField,
  IconButton,
  Snackbar,
  Container,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CopyAll } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { recipeService } from "../../../services/recipe.service";
import { accountService } from "../../../services/account.service";

function MultiRecipePrompt() {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [multiPrompt, setMultiPrompt] = useState("");
  const [promptLoading, setPromptLoading] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  async function fetchRecipePrompt() {
    setPromptLoading(true);
    try {
      const storageNameResponse = await accountService.getStorageName();
      const response = await fetch(
        `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/recipe-prompts.json`
      );
      const jsonData = await response.json(); // Correctly call the json() method
      setMultiPrompt(jsonData.multiRecipePrompt);
    } catch (error) {
      console.error("Failed to fetch recipe prompt:", error);
    }
    setPromptLoading(false);
  }

  useEffect(() => {
    fetchRecipePrompt();
  }, []);

  const handleCopy = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand("copy");
      alert("Text copied to clipboard!");
    }
  };

  async function updatePrompt(values: any) {
    setLoading(true);
    try {
      const type = "multiRecipePrompt";
      const response = await recipeService.updateRecipePrompt(values, type);
      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      multiRecipePrompt: multiPrompt,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      multiRecipePrompt: Yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      updatePrompt(values);
    },
  });

  function handleCloseSnackbar() {
    setSnackbarOpen(false);
  }

  return (
    <Box
      style={{
        fontFamily: "monospace",
        width: "90%",
        margin: "auto",
        position: "relative",
      }}
    >
      {promptLoading ? (
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <TextField
            inputRef={textAreaRef}
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            multiline
            minRows={20}
            maxRows={30}
            name="multiRecipePrompt"
            value={formik.values.multiRecipePrompt}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.multiRecipePrompt &&
              Boolean(formik.errors.multiRecipePrompt)
            }
            helperText={
              formik.touched.multiRecipePrompt &&
              formik.errors.multiRecipePrompt
            }
            variant="outlined"
          />
          <IconButton
            onClick={handleCopy}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "#007BFF",
              color: "white",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            }}
          >
            <CopyAll />
          </IconButton>
          <Box
            style={{
              textAlign: "center",
            }}
          >
            <Button
              type="submit"
              style={{
                marginTop: "10px",
                padding: "15px 30px",
                backgroundColor: "#007BFF",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                fontSize: "16px",
                display: loading ? "none" : "inline-block",
              }}
            >
              Submit
            </Button>
            {loading && <CircularProgress />}
          </Box>
        </form>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2", // Use primary color from the theme
            color: "white", // Set text color to white
          },
        }}
      />
    </Box>
  );
}

export default MultiRecipePrompt;
