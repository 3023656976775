import { accountService } from "../services";

//// importing the user utils --------------------------------------------------/
export const userUtils = {
  getStorageName,
  convertTimezoneOffsetToReadableFormat,
  convertReadableFormatToTimezoneOffset,
};

//// Funciton for getting the storagename from the local -----------------------/
async function getStorageName() {
  const loggedLocalData = await accountService.userValue;
  if (loggedLocalData && loggedLocalData.storageAccount) {
    return loggedLocalData.storageAccount;
  } else {
    return null;
  }
}

function convertTimezoneOffsetToReadableFormat(offsetString: string): string {
  // Convert the offset string to a number
  const offset = parseInt(offsetString, 10);

  // Validate the conversion
  if (isNaN(offset)) {
    throw new Error("Invalid offset value. Please provide a valid number.");
  }

  const sign = offset >= 0 ? "+" : "-";
  const absoluteOffset = Math.abs(offset);
  const hours = Math.floor(absoluteOffset / 60);
  const minutes = absoluteOffset % 60;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${sign}${formattedHours}:${formattedMinutes}`;
}

function convertReadableFormatToTimezoneOffset(offsetString: string): number {
  // Validate the input format
  const regex = /^([+-])(\d{2}):(\d{2})$/;
  const match = offsetString.match(regex);

  if (!match) {
    throw new Error(
      "Invalid format. Please provide a valid timezone offset in the format ±HH:MM."
    );
  }

  const sign = match[1] === "+" ? 1 : -1;
  const hours = parseInt(match[2], 10);
  const minutes = parseInt(match[3], 10);

  if (isNaN(hours) || isNaN(minutes)) {
    throw new Error("Invalid offset value. Please provide a valid number.");
  }

  return sign * (hours * 60 + minutes);
}
