/** @format */

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { memo } from "react";
import Paper from "@mui/material/Paper";
import {
  SleepBrowseDataInterface,
  SleepInterface,
} from "../../interface/SleepInterface";

function SleepBrowseData({ sleepArray }: SleepBrowseDataInterface) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: 2,
        height: "65vh",
        overflowY: "auto",
        overflowX: "scroll",
      }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              Date Time
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              Sleep-Session
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              Sleep-asleep
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              Sleep-awake
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              Sleep-deep
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              Sleep-rem
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              Sleep-light
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              Sleep-out-of-bed
            </TableCell>
            <TableCell
              style={{
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              Sleep-in-bed
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sleepArray?.map((row: SleepInterface, index: number) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
              style={{ overflowX: "scroll" }}
            >
              <TableCell component="th" scope="row">
                {row.dateTime}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.sleep_session} {row.sleep_session ? "Hrs" : null}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.sleep_asleep} {row.sleep_asleep ? "Hrs" : null}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.sleep_awake} {row.sleep_awake ? "Hrs" : null}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.sleep_deep} {row.sleep_deep ? "Hrs" : null}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.sleep_rem} {row.sleep_rem ? "Hrs" : null}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.sleep_light} {row.sleep_light ? "Hrs" : null}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.sleep_out_of_bed} {row.sleep_out_of_bed ? "Hrs" : null}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.sleep_in_bed} {row.sleep_in_bed ? "Hrs" : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(SleepBrowseData);
