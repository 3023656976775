import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";

const baseUrl = `${config.apiUrl}/api`;
export const notificationService={
 getNotificationUser
};

//// function for calling the notification get user api----------------------------------------/
async function getNotificationUser(title:string){
    return fetchWrapper.get(
        `${baseUrl}/get-userin-notification?title=${title}`
      );
}