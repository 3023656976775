/** @format */

import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  TextField,
  Button,
  Snackbar,
  Box,
  Typography,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { settingsService } from "../../services/settings.service";

function EventCampaign() {
  const [loading, setLoading] = useState(true);
  const [initialEvents, setInitialEvents] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          "https://reshapestorage.blob.core.windows.net/reshape-public/campaignEvents.json"
        );
        const data = await response.json();

        setInitialEvents(data.events.join(", ")); // Join events into a single comma-separated string
      } catch (error) {
        console.error("Failed to fetch events:", error);
        setSnackbarMessage("Failed to fetch events. Please try again later.");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleSubmit = async (values: any) => {
    setSubmitLoading(true);
    try {
      const eventsArray = values.events
        .split(",")
        .map((event: string) => event.trim()); // Convert to array

      let finalData = { ...values, events: eventsArray };

      const response = await settingsService.updateCampaignJson(finalData);
      const { message, status } = response;

      setSnackbarOpen(true);
      setSnackbarMessage(message);
      if (status) {
        setInitialEvents(eventsArray.join(", ")); // Update initialEvents as a string
      }
    } catch (error) {
      console.error("Failed to update events:", error);
      setSnackbarMessage("Failed to update events. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box p={4} style={{ backgroundColor: "#f0f0f0" }}>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: "700" }}>
          Event Campaign
        </Typography>
      </Container>
      {loading ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <Formik
          initialValues={{ events: initialEvents }}
          validationSchema={yup.object({
            events: yup.string().required("Events are required"),
          })}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <Field
                as={TextField}
                label="Events"
                name="events"
                variant="outlined"
                fullWidth
                helperText="Enter events separated by commas (e.g., 'Event 1, Event 2, Event 3')"
                multiline
                rows={4}
              />
              <Box mt={3}>
                {submitLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Box>
  );
}

export default EventCampaign;
