import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Switch,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Paper,
  Grid,
  CircularProgress,
  IconButton,
  Menu,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import DownloadIcon from "@mui/icons-material/Download";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import FreeTextForm from "./FreeTextFormInteraction";
import InformationForm from "./InformationInteraction";
import StarRatingForm from "./StarRatingIntercation";
import MultipleChoiceForm from "./MultipleChoiceInteraction";
import { interactionService } from "../../services/interaction.service";
import { userResponseService } from "../../services/userresponse.service";
import { log } from "node:console";
import { CSVLink } from "react-csv";
import InfoIcon from "@mui/icons-material/Info"; // Optional, to add an info icon
import { campaignService } from "../../services/campaign.service";

interface InteractionProps {
  campaignId: string | null;
  campaignName: string | null;
  isToggled: boolean;
  handleToggleChange: () => void;
  handleSaveClick: () => void;
  addInteraction: (interaction: any) => void; // Function to add interaction
  interactions: any[]; // Array of interactions
  dateRange: {
    startDate: Date | null;
    endDate: Date | null;
  };
  setDateRange: (range: {
    startDate: Date | null;
    endDate: Date | null;
  }) => void; // Function to update date range,
  campaignSaveLoading: boolean;
  setInteractions: any;
  selectedEvents: any;
  setSelectedEvents: any;
}
const padString = (str: string, length: number) => {
  return str.length < length ? str + " ".repeat(length - str.length) : str;
};
const Interaction: React.FC<InteractionProps> = ({
  campaignId,
  campaignName,
  isToggled,
  handleToggleChange,
  handleSaveClick,
  addInteraction,
  interactions,
  dateRange,
  setDateRange,
  campaignSaveLoading,
  setInteractions,
  selectedEvents,
  setSelectedEvents,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [interactionType, setInteractionType] = useState<string>("");
  const [editingInteraction, setEditingInteraction] = useState<any | null>(
    null
  );
  const [eventList, setEventList] = useState<string[]>([]);
  const [editingInteractionId, setEdititngInteractionId] = useState<any>();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [loadingEvents, setLoadingEvents] = useState<boolean>(false);

  const open = Boolean(anchorEl);

  // Function to open the dropdown
  const handleDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the dropdown
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const csvLinkRef = useRef<any>(null);
  // Initialize the csvData with `any` type
  const [csvData, setCsvData] = useState<any>({
    headers: [],
    data: [],
  });

  const [deletingInteractionId, setDeletingInteractionId] = useState<
    string | null
  >(null);
  const [downloadingCampaignId, setDownloadingCampaignId] = useState<
    string | null
  >(null);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);

    setEditingInteraction(null); // Reset edit mode
  };

  // Fetch events when the campaignId changes
  useEffect(() => {
    const fetchCampaignEvents = async () => {
      setLoadingEvents(true);
      const eventNames = await campaignService.fetchAllEventName();
      setEventList(eventNames);
      if (!campaignId) return; // Exit if no campaignId

      try {
        // API call to fetch events for the given campaign
        const response: any = await campaignService.getCampaignEvents(
          campaignId
        );

        if (response.success) {
          // Assuming the API returns an array of event names
          const savedEvents = response.data || [];

          setSelectedEvents(savedEvents);
        } else {
          console.error("Failed to fetch events:", response.message);
        }
      } catch (error: any) {
        console.error("Error fetching events:", error.message);
      } finally {
        setLoadingEvents(false);
      }
    };

    fetchCampaignEvents(); // Trigger the function to fetch events
  }, [campaignId]); // Re-run this effect when campaignId changes
  // Function to handle the selection of an event
  const handleSelectEvent = async (eventName: string) => {
    setLoadingEvents(true);
    try {
      // If the selected event is already the current one, do nothing
      if (selectedEvents[0] === eventName) {
      } else {
        // Update state with the new selected event (only one event can be selected at a time)
        setSelectedEvents([eventName]); // Only one event in the array
      }
    } catch (error: any) {
      console.error("Error selecting event:", error.message);
    } finally {
      setLoadingEvents(false);
      handleCloseDropdown();
    }
  };

  const handleAddInteraction = (interaction: any) => {
    setInteractions((prevInteractions: any) => {
      // Add mode: no index means it's a new interaction, so we add it
      return [...prevInteractions, interaction];
    });

    handleCloseModal();
    setEdititngInteractionId(""); // Reset after editing
  };
  const handleEditingInteraction = (interaction: any) => {
    setInteractions((prevInteractions: any) => {
      // Edit mode: update the existing interaction with matching index
      let value = prevInteractions.map((i: any, index: number) =>
        index === editingInteractionId ? { ...interaction } : i
      );

      return value;
    });

    handleCloseModal();
    setEdititngInteractionId(""); // Reset after editing
  };

  const handleEditInteraction = (interaction: any, index: any) => {
    setEditingInteraction(interaction);
    setEdititngInteractionId(index);
    setInteractionType(interaction.type);
    handleOpenModal();
  };

  const handleDeleteInteraction = async (interactionId: string) => {
    setDeletingInteractionId(interactionId);
    try {
      const response = await interactionService.deleteInteraction(
        interactionId
      );
      if (response.success) {
        // Remove the deleted interaction from the list
        setInteractions((prevInteractions: any) =>
          prevInteractions.filter((i: any) => i.uuid !== interactionId)
        );
      } else {
        // Handle error if deletion failed
        console.error("Failed to delete interaction:", response.message);
      }
    } catch (error: any) {
      console.error("Error deleting interaction:", error.message);
    } finally {
      setDeletingInteractionId(null);
    }
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newStartDate = event.target.value
      ? new Date(event.target.value)
      : null;
    setDateRange({ ...dateRange, startDate: newStartDate });
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value ? new Date(event.target.value) : null;
    setDateRange({ ...dateRange, endDate: newEndDate });
  };

  // Effect hook to trigger the CSV download when csvData is updated
  // Effect hook to trigger the CSV download when csvData is updated
  useEffect(() => {
    // This will run once csvData has been populated
    if (csvData.data.length > 0) {
      csvLinkRef?.current?.link.click(); // Automatically trigger download
    }
  }, [csvData]); // This will run whenever csvData is updated

  const handleDownloadResponses = async (interaction_id: any) => {
    setDownloadingCampaignId(interaction_id);
    try {
      const response: any = await interactionService.getInteractionDetails(
        interaction_id
      );

      if (response.success) {
        const interactionDetails = response.interactionDetails || {};
        const interaction = interactionDetails.interaction || {};
        const userResponses = interactionDetails.userResponses || [];

        // Parse input_fields as it is a JSON string
        const inputFields = JSON.parse(interaction.input_fields || "{}");

        // Safely access `title` and `body`
        const question = `${inputFields.title || "No Title"} - ${
          inputFields.body || "No Body"
        }`;

        // Prepare the CSV headers
        const csvHeaders: any[] = [
          { label: "User ID", key: "user_id" },
          { label: "Action", key: "action" },
          { label: "Created At", key: "created_at" },
          { label: "Question", key: "question" },
        ];

        // Collect all unique keys from user responses to add as columns
        const allUserResponseKeys = new Set<any>();

        userResponses.forEach((item: any) => {
          let userResponseObj = JSON.parse(item.userresponse || "{}");

          if (Array.isArray(userResponseObj)) {
            userResponseObj = userResponseObj[0];
          }
          Object.keys(userResponseObj).forEach((key: any) =>
            allUserResponseKeys.add(key)
          );
        });

        // Add the dynamic user response columns to the CSV headers
        allUserResponseKeys.forEach((key: any) => {
          csvHeaders.push({ label: key, key });
        });

        // Transform the data into CSV format
        const csvFormattedData: any[] = userResponses.map((item: any) => {
          let rawResponse = JSON.parse(item.userresponse || "{}");

          if (Array.isArray(rawResponse)) {
            rawResponse = rawResponse[0];
          }

          const userResponseRow: any = {
            user_id: item.user_id,
            action: item.action,
            created_at: item.createdAt,
            question: question,
          };

          // Add each dynamic key from user responses
          allUserResponseKeys.forEach((key: any) => {
            userResponseRow[key] = rawResponse[key] || ""; // Add value or empty string
          });

          return userResponseRow;
        });

        // Set CSV data (which will trigger the effect to download)
        setCsvData({
          headers: csvHeaders,
          data: csvFormattedData,
        });
      } else {
        setSnackbarMessage("Failed to fetch responses");
        setSnackbarOpen(true);
      }
    } catch (error: any) {
      setSnackbarMessage("Error fetching user responses");
      setSnackbarOpen(true);
    } finally {
      setDownloadingCampaignId(null); // Stop showing loader
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      {/* Top Bar Layout */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          borderBottom: "1px solid #ddd",
          pb: 2,
          marginLeft: 1,
          marginRight: 1,
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: "500", color: "#333" }}>
            Campaign ID: {campaignId}
          </Typography>
        </Box>
        <Box sx={{ flex: 1, textAlign: "center" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
            {campaignName}
          </Typography>
        </Box>
        {/* Dropdown Icon beside campaign name */}
        <Box>
          <IconButton
            aria-controls={open ? "event-menu" : undefined}
            aria-haspopup="true"
            onClick={handleDropdownClick}
          >
            <ArrowDropDownIcon />
          </IconButton>
          <Menu
            id="event-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseDropdown}
          >
            {eventList.length > 0 &&
              eventList.map((eventName) => (
                <MenuItem
                  key={eventName}
                  onClick={() => handleSelectEvent(eventName)}
                  selected={selectedEvents.includes(eventName)} // Highlight already selected events
                >
                  {eventName}
                </MenuItem>
              ))}
          </Menu>
        </Box>
        {/* Loader for events */}
        {loadingEvents && <CircularProgress />}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ mr: 1 }}>Off:</Typography>
          <Switch checked={isToggled} onChange={handleToggleChange} />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginTop: "10px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={10} sm={6}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              value={
                dateRange.startDate
                  ? dateRange.startDate.toISOString().substr(0, 10)
                  : ""
              }
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={10} sm={6}>
            <TextField
              fullWidth
              label="End Date"
              type="date"
              value={
                dateRange.endDate
                  ? dateRange.endDate.toISOString().substr(0, 10)
                  : ""
              }
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {/* Add Interaction Button */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 2,
          marginTop: "10PX",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          sx={{
            backgroundColor: "#007bff",
            "&:hover": {
              backgroundColor: "#0056b3",
            },
          }}
        >
          <AddIcon /> Add Interaction
        </Button>
      </Box>

      {/* Interaction Cards */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          mt: 2,
        }}
      >
        {csvData.data.length > 0 && (
          <CSVLink
            data={csvData.data}
            headers={csvData.headers}
            filename="user_responses.csv"
            ref={csvLinkRef} // Assign the ref for automatic triggering
            style={{ display: "none" }} // Hide the CSVLink button
          >
            Download
          </CSVLink>
        )}

        {/* Interaction cards would be dynamically rendered here */}
        {interactions.map((interaction, index) => (
          <Paper key={index} sx={{ p: 2, width: "100%", maxWidth: 400 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{interaction.type}</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <IconButton
                  edge="end"
                  color="primary"
                  onClick={() => handleEditInteraction(interaction, index)}
                >
                  <EditIcon />
                </IconButton>
                {deletingInteractionId === interaction.uuid ? (
                  <CircularProgress size={24} />
                ) : (
                  <IconButton
                    edge="end"
                    color="error"
                    onClick={() => handleDeleteInteraction(interaction.uuid)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                {interaction.uuid ? (
                  downloadingCampaignId === interaction.uuid ? (
                    <CircularProgress size={24} />
                  ) : (
                    <IconButton
                      edge="end"
                      color="primary"
                      onClick={() => handleDownloadResponses(interaction.uuid)}
                    >
                      <DownloadIcon />
                    </IconButton>
                  )
                ) : null}
              </Box>
            </Box>
            {/* Check if input_fields exist and render its content */}
            {interaction.input_fields ? (
              <>
                {interaction.input_fields.title && (
                  <Typography variant="body1">
                    {interaction.input_fields.title}
                  </Typography>
                )}
                {interaction.input_fields.body && (
                  <Typography variant="body2">
                    {interaction.input_fields.body}
                  </Typography>
                )}
                {interaction.input_fields.imageUrl && (
                  <img
                    src={interaction.input_fields.imageUrl}
                    alt="Interaction"
                    width="100"
                  />
                )}
              </>
            ) : (
              <>
                {/* Fallback rendering if input_fields does not exist */}
                <Typography variant="body1">{interaction.title}</Typography>
                <Typography variant="body2">{interaction.body}</Typography>
                {interaction.imageUrl && (
                  <img
                    src={interaction.imageUrl}
                    alt="Interaction"
                    width="100"
                  />
                )}
              </>
            )}
          </Paper>
        ))}
      </Box>

      {/* Save Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        {campaignSaveLoading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            sx={{
              backgroundColor: "#007bff",
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            }}
          >
            Save
          </Button>
        )}
      </Box>

      {/* Modal */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="interaction-type-label">Type</InputLabel>
            <Select
              labelId="interaction-type-label"
              id="interaction-type-select"
              value={interactionType}
              onChange={(e) => setInteractionType(e.target.value)}
              label="Type"
              disabled={!!editingInteraction} // Disable type change during edit
            >
              <MenuItem value="information">Information</MenuItem>
              <MenuItem value="free_text">Free Text</MenuItem>
              <MenuItem value="multiple_choice">Multiple Choice</MenuItem>
              <MenuItem value="rating">Star Rating</MenuItem>
            </Select>
          </FormControl>
          {interactionType === "information" && (
            <InformationForm
              onClose={handleCloseModal}
              onSave={handleAddInteraction}
              initialData={editingInteraction} // Pass initial data for editing
              campaignId={campaignId}
              type={interactionType}
              sequence={
                editingInteraction?.sequence
                  ? editingInteraction.sequence
                  : undefined
              }
              editingInteractionId={editingInteractionId}
              onEdit={handleEditingInteraction}
            />
          )}
          {interactionType === "free_text" && (
            <FreeTextForm
              onClose={handleCloseModal}
              onSave={handleAddInteraction}
              initialData={editingInteraction}
              campaignId={campaignId}
              type={interactionType}
              sequence={
                editingInteraction?.sequence
                  ? editingInteraction.sequence
                  : undefined
              }
              editingInteractionId={editingInteractionId}
              onEdit={handleEditingInteraction}
            />
          )}
          {interactionType === "multiple_choice" && (
            <MultipleChoiceForm
              onClose={handleCloseModal}
              onSave={handleAddInteraction}
              initialData={editingInteraction}
              campaignId={campaignId}
              type={interactionType}
              sequence={
                editingInteraction?.sequence
                  ? editingInteraction.sequence
                  : undefined
              }
              editingInteractionId={editingInteractionId}
              onEdit={handleEditingInteraction}
            />
          )}
          {interactionType === "rating" && (
            <StarRatingForm
              onClose={handleCloseModal}
              onSave={handleAddInteraction}
              initialData={editingInteraction}
              campaignId={campaignId}
              type={interactionType}
              sequence={
                editingInteraction?.sequence
                  ? editingInteraction.sequence
                  : undefined
              }
              editingInteractionId={editingInteractionId}
              onEdit={handleEditingInteraction}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default Interaction;
