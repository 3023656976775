/** @format */

import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TopAppBar from "../modules/TopAppBar";
import { accountService } from "../services";
import { userService } from "../services/userdetails.service";
import "../css/client.css";
import { useNavigate } from "react-router-dom";
const ClientDetailsTable = ({ clientDetails }: any) => {
  const navigate = useNavigate();
  const formatValue = (value: any) => {
    // Check if value is a number
    if (typeof value === "number") {
      // Check if value has a decimal part
      if (value % 1 !== 0) {
        return value.toFixed(1);
      } else {
        return value.toString();
      }
    }
    return value;
  };

  /// Function For redirecting to the main screen----------------/
  async function handelRoute(id: any, date: any) {
    navigate("/", { state: { userId: id, date: date } });
  }
  return (
    <div style={{ width: "98%" }}>
      {/* Display user rows */}
      <div className="user-section" style={{ maxHeight: "80vh" }}>
        <table className="client-data">
          <thead>
            <tr style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <th></th>
              {clientDetails[0]?.mealDetails.map((meal: any, index: any) => (
                <th key={index}>
                  <div>
                    <p
                      style={{
                        textDecoration: "underline",
                        textAlign: "center",
                      }}
                    >
                      {meal.date}
                    </p>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {clientDetails.map((client: any) => (
              <tr key={client.name}>
                <td style={{ minWidth: 100 }}>{client.name}</td>
                {client.mealDetails.map((meal: any, index: any) => (
                  <td key={index}>
                    <Container
                      style={{
                        display: "flex",

                        flexDirection: "column", // Add this line
                        alignItems: "flex-start", // Add this line
                        justifyContent: "flex-start",

                        padding: "3px",
                        gap: "0px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Card
                          style={{
                            width: "calc(100% / 4 - 20px)", // Divide by 4 for 4 items, minus margin and gap
                            margin: "5px",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",

                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <CardContent style={{ padding: "5px" }}>
                            <div style={{ width: "100%", textAlign: "start" }}>
                              {" "}
                              {/* Add this div */}
                              <Typography
                                variant="body2"
                                component="h2"
                                style={{
                                  marginBottom: "2px",
                                  fontSize: "14px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis", // Use ellipsis for overflow
                                }}
                              >
                                Calories
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              component="p"
                              align="center"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Use ellipsis for overflow
                              }}
                            >
                              {formatValue(meal.totalCalories)}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              align="center"
                              style={{
                                fontSize: "10px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Use ellipsis for overflow
                              }}
                            >
                              kcal
                            </Typography>
                          </CardContent>
                        </Card>
                        <Card
                          style={{
                            width: "calc(100% / 4 - 20px)", // Divide by 4 for 4 items, minus margin and gap
                            margin: "5px",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",

                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <CardContent style={{ padding: "5px" }}>
                            <div style={{ width: "100%", textAlign: "start" }}>
                              {" "}
                              {/* Add this div */}
                              <Typography
                                variant="body2"
                                component="h2"
                                style={{
                                  marginBottom: "2px",
                                  fontSize: "14px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis", // Use ellipsis for overflow
                                }}
                              >
                                Protein
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              component="p"
                              align="center"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Use ellipsis for overflow
                              }}
                            >
                              {formatValue(meal.totalProtein)}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              align="center"
                              style={{
                                fontSize: "10px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Use ellipsis for overflow
                              }}
                            >
                              grams
                            </Typography>
                          </CardContent>
                        </Card>
                        <Card
                          style={{
                            width: "calc(100% / 4 - 20px)", // Divide by 4 for 4 items, minus margin and gap
                            margin: "5px",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",

                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <CardContent style={{ padding: "5px" }}>
                            <div style={{ width: "100%", textAlign: "start" }}>
                              {" "}
                              {/* Add this div */}
                              <Typography
                                variant="body2"
                                component="h2"
                                style={{
                                  marginBottom: "2px",
                                  fontSize: "14px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis", // Use ellipsis for overflow
                                }}
                              >
                                Carbs
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              component="p"
                              align="center"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Use ellipsis for overflow
                              }}
                            >
                              {formatValue(meal.totalCarbs)}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              align="center"
                              style={{
                                fontSize: "10px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Use ellipsis for overflow
                              }}
                            >
                              grams
                            </Typography>
                          </CardContent>
                        </Card>
                        <Card
                          style={{
                            width: "calc(100% / 4 - 20px)", // Divide by 4 for 4 items, minus margin and gap
                            margin: "5px",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",

                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            position: "relative",
                          }}
                        >
                          <CardContent style={{ padding: "5px" }}>
                            <div style={{ width: "100%", textAlign: "start" }}>
                              {" "}
                              {/* Add this div */}
                              <Typography
                                variant="body2"
                                component="h2"
                                style={{
                                  marginBottom: "2px",
                                  fontSize: "14px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis", // Use ellipsis for overflow
                                }}
                              >
                                Fiber
                              </Typography>
                            </div>
                            <Typography
                              variant="body2"
                              component="p"
                              align="center"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Use ellipsis for overflow
                              }}
                            >
                              {formatValue(meal.totalFiber)}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              align="center"
                              style={{
                                fontSize: "10px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Use ellipsis for overflow
                              }}
                            >
                              grams
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                      <Container style={{ marginTop: 5 }}>
                        <Typography
                          style={{
                            textAlign: "center",
                            textDecoration: "underline",
                            fontSize: 10,
                            color: "#1067bf",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handelRoute(clientDetails[0]?.id, meal.date)
                          }
                        >
                          Browse Data
                        </Typography>
                      </Container>
                      {/* <br />
                      Fats: {formatValue(meal.totalFats)}
                      <br />
                      Trans Fat: {formatValue(meal.totalTransFat)}
                      <br />
                      Saturated Fat: {formatValue(meal.totalSaturatedFat)}
                      <br />
                      MonoUnsaturated Fat:{" "}
                      {formatValue(meal.totalMonoUnsaturatedFat)}
                      <br />
                      PolyUnsaturated Fat:{" "}
                      {formatValue(meal.totalPolyUnsaturatedFat)}
                      <br />
                      Fiber: {formatValue(meal.totalFiber)}
                      <br />
                      Sugar: {formatValue(meal.totalSugar)}
                      <br /> */}
                    </Container>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Summary = () => {
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState<any>();
  const [clientDetails, setClientDetails] = useState<any>([]);
  const [mainUser, setMainUser] = useState<any>();
  const [firstDate, setFirstDate] = useState<any>();
  /// Function For calling the clientDetails--------------------------/
  async function getClientDetails() {
    setLoading(true);
    try {
      /// getting the user from localstorage------------------------/
      const user = await accountService.userValue;
      setMainUser(user);
      const date = new Date();
      //// Fetching The ClientDetails--------------------------------/
      const clientResponse = await userService.fetchClientDetails(
        user.id,
        date
      );
      setCurrentDate(date);
      setFirstDate(date);
      if (clientResponse.success === true) {
        setClientDetails(clientResponse.clientDetails);

        setLoading(false);
      } else {
        setLoading(false);
        setClientDetails([]);
      }
    } catch (error) {
      setLoading(false);
      setClientDetails([]);
    }
  }

  useEffect(() => {
    getClientDetails();
  }, []);

  /// Function for handeling the previous week or next week data-------/
  async function handleWeek(type: string) {
    let newDate = new Date(currentDate);

    if (type === "previous") {
      newDate.setDate(newDate.getDate() - 7); // Subtract 7 days for the previous week
    } else {
      // For 'next', check if the new date is after the firstDate
      newDate.setDate(newDate.getDate() + 7); // Add 7 days for the next week
      if (newDate > firstDate) {
        // If the new date is after firstDate, don't proceed
        return;
      }
    }

    setCurrentDate(newDate); // Update the currentDate state

    try {
      setLoading(true);
      // Fetching the ClientDetails with the new date
      const clientResponse = await userService.fetchClientDetails(
        mainUser.id,
        newDate
      );

      if (clientResponse.success === true) {
        setClientDetails(clientResponse.clientDetails);
      } else {
        setClientDetails([]);
      }
    } catch (error) {
      setClientDetails([]);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <TopAppBar />
      <div style={{ display: "flex" }}>
        {loading ? (
          <Container
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 50,
            }}
          >
            <CircularProgress />
          </Container>
        ) : (
          <div style={{ flex: 1, overflowX: "auto" }}>
            {" "}
            {/* Ensure content area takes remaining space and is scrollable */}
            <div style={{ marginTop: 10 }}>
              {clientDetails && clientDetails.length > 0 ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 10,
                      position: "sticky", // Keep the arrow container fixed

                      zIndex: 1000, // Ensure it's above other elements
                    }}
                  >
                    <ArrowBackIosIcon
                      sx={{ cursor: "pointer" }}
                      style={{ marginLeft: 10 }}
                      onClick={() => handleWeek("previous")}
                    />
                    <ArrowForwardIosIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleWeek("next")}
                    />
                  </div>

                  <div
                    style={{
                      overflowX: "auto", // Enable horizontal scrolling
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ClientDetailsTable clientDetails={clientDetails} />
                  </div>
                </>
              ) : (
                <Container
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontSize: 15, fontWeight: "800" }}>
                    No Data available
                  </Typography>
                </Container>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
