import React, { useContext } from "react";

import { Navigate, useLocation } from "react-router-dom";
import { accountService } from "../services";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const user = accountService.userValue;

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
  } else {
    return children;
  }
};

export default PrivateRoute;
