import React from "react";
import { Modal, Box, Typography, Card, CardContent } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  maxHeight: "80vh",
  display: "flex",
  flexDirection: "column",
};

const contentStyle = {
  overflowY: "auto",
  maxHeight: "70vh", // Adjust this value as needed
};

interface ResponseModalProps {
  responseModalOpen: boolean;
  handleCloseResponseModal: () => void;
  nutritionData: any;
}

const ResponseModal = ({
  responseModalOpen,
  handleCloseResponseModal,
  nutritionData,
}: ResponseModalProps) => {
  return (
    <Modal open={responseModalOpen} onClose={handleCloseResponseModal}>
      <Box sx={style}>
        <Typography variant="h4" component="h2" gutterBottom>
          Response
        </Typography>
        <Box sx={contentStyle}>
          <Card
            sx={{ mt: 2, backgroundColor: "#f5f5f5", p: 2, borderRadius: 2 }}
          >
            <CardContent>
              <Typography
                variant="body1"
                component="div"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {typeof nutritionData === "string"
                  ? nutritionData
                  : JSON.stringify(nutritionData, null, 2)}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResponseModal;
