import { Card, CardContent, TextField, Typography } from "@mui/material";

const MacroCard = ({ label, value, editMode, handleInputChange }: any) => (
  <Card
    style={{
      width: 60, // Adjust width as needed
      margin: "5px",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      borderRadius: "10px",
      position: "relative",
    }}
  >
    <CardContent style={{ padding: "5px" }}>
      <Typography
        variant="body2"
        component="h2"
        style={{
          marginBottom: "2px",
          fontSize: "14px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {label}
      </Typography>

      {editMode == true ? (
        <TextField
          value={value}
          onChange={(e) =>
            handleInputChange(
              e,
              label == "Added Sugar" ? "added_sugar" : label.toLowerCase()
            )
          }
          variant="outlined"
          size="small"
        />
      ) : (
        <Typography
          variant="body2"
          component="p"
          align="center"
          style={{
            fontWeight: "bold",
            fontSize: "14px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </Typography>
      )}

      <Typography
        color="textSecondary"
        align="center"
        style={{
          fontSize: "10px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {label === "Calories" ? "kcal" : "g"}
      </Typography>
    </CardContent>
  </Card>
);

export default MacroCard;
