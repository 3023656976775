/** @format */

import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { RecipeLeftBarInterface } from "../../interface/RecipeInterface";
function RecipeLeftNavbar({
  renderMiddleSection,
  setRenderMiddleSection,
}: RecipeLeftBarInterface) {
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        p: 2,
        height: "85vh",
        width: "20%",
        color: "white",
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" sx={{ mb: 1, fontSize: 25 }}>
          Create
        </Typography>
        <List component="nav" aria-label="create sections">
          <ListItem
            button
            onClick={() => setRenderMiddleSection("single")}
            sx={{
              bgcolor: renderMiddleSection === "single" ? "white" : "inherit",
              color:
                renderMiddleSection === "single" ? "primary.main" : "white",
              "&:hover": {
                bgcolor: "white", // White background on hover
                color: "primary.main", // Primary color text on hover
              },
            }}
          >
            <ListItemText primary="single" />
          </ListItem>
          <ListItem
            button
            onClick={() => setRenderMiddleSection("multi")}
            sx={{
              bgcolor: renderMiddleSection === "multi" ? "white" : "inherit",
              color: renderMiddleSection === "multi" ? "primary.main" : "white",
              "&:hover": {
                bgcolor: "white",
                color: "primary.main",
              },
            }}
          >
            <ListItemText primary="multi" />
          </ListItem>
        </List>
      </Box>

      <Box>
        <Typography variant="subtitle1" sx={{ mb: 1, fontSize: 25 }}>
          Browse
        </Typography>
        <List component="nav" aria-label="browse sections">
          <ListItem
            button
            onClick={() => setRenderMiddleSection("prod")}
            sx={{
              bgcolor: renderMiddleSection === "prod" ? "white" : "inherit",
              color: renderMiddleSection === "prod" ? "primary.main" : "white",
              "&:hover": {
                bgcolor: "white",
                color: "primary.main",
              },
            }}
          >
            <ListItemText primary="prod" />
          </ListItem>
          <ListItem
            button
            onClick={() => setRenderMiddleSection("saved")}
            sx={{
              bgcolor: renderMiddleSection === "saved" ? "white" : "inherit",
              color: renderMiddleSection === "saved" ? "primary.main" : "white",
              "&:hover": {
                bgcolor: "white",
                color: "primary.main",
              },
            }}
          >
            <ListItemText primary="Saved" />
          </ListItem>
        </List>
      </Box>
      <Box>
        <Typography variant="subtitle1" sx={{ mb: 1, fontSize: 25 }}>
          Prompts
        </Typography>
        <List component="nav" aria-label="browse sections">
          <ListItem
            button
            onClick={() => setRenderMiddleSection("singlerecipeprompt")}
            sx={{
              bgcolor:
                renderMiddleSection === "singlerecipeprompt"
                  ? "white"
                  : "inherit",
              color:
                renderMiddleSection === "singlerecipeprompt"
                  ? "primary.main"
                  : "white",
              "&:hover": {
                bgcolor: "white",
                color: "primary.main",
              },
            }}
          >
            <ListItemText primary="Single Recipe Prompt" />
          </ListItem>
          <ListItem
            button
            onClick={() => setRenderMiddleSection("multirecipeprompt")}
            sx={{
              bgcolor:
                renderMiddleSection === "multirecipeprompt"
                  ? "white"
                  : "inherit",
              color:
                renderMiddleSection === "multirecipeprompt"
                  ? "primary.main"
                  : "white",
              "&:hover": {
                bgcolor: "white",
                color: "primary.main",
              },
            }}
          >
            <ListItemText primary="Multi Recipe Prompt" />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
}

export default RecipeLeftNavbar;
