import React, { useState } from "react";
import {
  Typography,
  CircularProgress,
  Button,
  TextField,
  Container,
  Box,
  Paper,
  Alert,
} from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { accountService } from "../services";
import logoImage from "../assets/reshape_logo_full.png";
import { useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { AzureSignIn } from "../modules/AzureSignIn";

type Inputs = {
  email: string;
  password: string;
};

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    setLoading(true);
    accountService
      .signin(data.email, data.password)
      .then(() => {
        setLoading(false);
        navigate(location.state?.from?.pathname || "/");
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleSSO = () => {
    instance
      .loginPopup({
        scopes: ["User.Read", "openid", "profile", "email"],
        prompt: "select_account",
      })
      .then((response) => {
        // Handle successful authentication response
        navigate(location.state?.from?.pathname || "/");
      })
      .catch((error) => {
        // Handle authentication error
        console.error(error);
      });
  };

  return (
    <Container
      component="main"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={logoImage}
          alt="Logo"
          style={{ width: "300px", marginBottom: "20px" }}
        />

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 3, width: "100%" }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            type="email"
            {...register("email", { required: true })}
            error={!!errors.email}
            helperText={errors.email ? "Email is required." : ""}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            id="password"
            {...register("password", { required: true })}
            error={!!errors.password}
            helperText={errors.password ? "Password is required." : ""}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Sign In"}
          </Button>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <AzureSignIn />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;
