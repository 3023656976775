import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";
const baseUrl = `${config.apiUrl}/api`;
export const interactionService = {
  createInteraction,
  deleteInteraction,
  getInteractionDetails,
};

/// Function For adding new campagins ------------------------------------/
async function createInteraction(data: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.post(`${baseUrl}/create-interaction`, data);
}
/// Function For adding new campagins ------------------------------------/
async function deleteInteraction(interactionId: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.delete(
    `${baseUrl}/delete-interaction?interactionId=${interactionId}`
  );
}

/// Function For adding new campagins ------------------------------------/
async function getInteractionDetails(interaction_id: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.get(
    `${baseUrl}/get-interactiondetails?interaction_id=${interaction_id}`
  );
}
