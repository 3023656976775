import React from "react";
import { Card, CardContent, Typography, Box, Container } from "@mui/material";
// Define the types for recipe and ingredient with index signatures
type Recipe = {
  image: string;
  name: string;
  description: string;
  direction: string;
  ingredients: Ingredient[];
  [key: string]: string | Ingredient[] | number; // Allowing numbers as well
};

type Ingredient = {
  name: string;
  calories: string | number;
  protein: string | number;
  carbs: string | number;
  fats: string | number;
  added_sugar: string | number;
  [key: string]: string | number; // Allowing both strings and numbers
};
const EmptyMultiRecipeCard = () => {
  // Empty recipe object with default values
  const emptyRecipe: Recipe = {
    image: "",
    name: "- -",
    description: "- -",
    protein: "- -",
    carbs: "- -",
    fats: "- -",
    calories: "- -",
    fiber: "- -",
    sugar: "- -",
    direction: "- -",
    ingredients: [
      {
        name: "-",
        calories: "-",
        protein: "-",
        carbs: "-",
        fats: "-",
        added_sugar: "-",
      },
    ],
  };

  return (
    <Card
      style={{
        borderRadius: "10px",
        boxShadow: "0 12px 24px rgba(0,0,0,0.3)",
        position: "relative",
        zIndex: 2,
        margin: "20px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <CardContent style={{ padding: "20px", borderRadius: "10px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Container sx={{ width: "30%" }}>
            <img
              src={"https://via.placeholder.com/600x400"}
              alt="Recipe"
              style={{
                width: "100%",
                borderRadius: 4,
                height: "100%",
              }}
            />
          </Container>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "70%",
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{
                textAlign: "start",
                width: "100%",
                fontWeight: "BOLD",
              }}
            >
              {emptyRecipe.name}
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "start", width: "100%" }}
            >
              {emptyRecipe.description}
            </Typography>
          </Container>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "10px",
          }}
        >
          {[
            { key: "protein", label: "Protein", unit: "" },
            { key: "carbs", label: "Carbs", unit: "" },
            { key: "fats", label: "Fat", unit: "" },
            { key: "calories", label: "Calories", unit: "" },
            { key: "fiber", label: "Fiber", unit: "" },
            { key: "sugar", label: "Sugar", unit: "" },
          ].map((nutrient) => (
            <Card
              key={nutrient.key}
              sx={{
                padding: "10px",
                margin: "5px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                backgroundColor: "#e3f2fd",
                width: "22%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  "@media (max-width: 1290px)": {
                    fontSize: 10,
                  },
                }}
              >
                {`${nutrient.label}: ${
                  typeof emptyRecipe[nutrient.key] === "string" ||
                  typeof emptyRecipe[nutrient.key] === "number"
                    ? emptyRecipe[nutrient.key] + nutrient.unit
                    : "-"
                }`}
              </Typography>
            </Card>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex",
            marginTop: "10px",
          }}
        >
          <Typography variant="h6">
            Direction :{" "}
            <span style={{ fontSize: 15 }}>{emptyRecipe.direction}</span>
          </Typography>
        </Box>
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Ingredients : - -
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EmptyMultiRecipeCard;
