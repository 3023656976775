import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Container,
  Collapse,
  CircularProgress,
  Button,
  Snackbar,
  Grid,
} from "@mui/material";

import {
  SavedInterface,
  SingleRecipe,
} from "../../../interface/RecipeInterface";
import { recipeService } from "../../../services/recipe.service";
import EmptyMultiRecipeCard from "../Multi/EmptyMultiRecipeCard";

import RecipeCard from "./RecipeCard";

const Saved = ({
  setRenderMiddleSection,
  setSelectedEditingRecipe,
  setUpdateScreenName,
  updateScreenName,
}: SavedInterface) => {
  const [savedRecipes, setSavedRecipes] = useState<SingleRecipe[]>([]);
  const [savedRecipeLoading, setSavedRecipeLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [selectedRecipes, setSelectedRecipes] = useState<SingleRecipe[]>([]);

  /// Funciton for selecting the recipes -----------------------------------------------------------------------/
  const handleSelectRecipe = useCallback((recipe: SingleRecipe) => {
    setSelectedRecipes((prev) =>
      prev.some((item) => item._id === recipe._id)
        ? prev.filter((item) => item._id !== recipe._id)
        : [...prev, recipe]
    );
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  /// Funciton for fetching the recipes from the db -------------------------------------------------------------/
  const fetchSavedRecipes = useCallback(async () => {
    setSavedRecipeLoading(true);
    try {
      const savedRecipesResponse = await recipeService.getRecipesFromDb(
        "saved"
      );
      const { message, success, recipeData } = savedRecipesResponse;

      setSavedRecipeLoading(false);
      setSnackbarMessage(message);
      if (success) {
        setSavedRecipes(recipeData);
      } else {
        setSavedRecipes([]);
      }
    } catch (error) {
      setSavedRecipeLoading(false);
      setSnackbarMessage(
        "Some error has happened while fetching the saved recipes"
      );
    }
  }, []);

  useEffect(() => {
    fetchSavedRecipes();
  }, [fetchSavedRecipes, updateScreenName]);

  /// Function for deleting the recipes -----------------------------------------------------------------------/
  const deleteCards = async (selectedRecipes: SingleRecipe[]) => {
    if (selectedRecipes.length === 0) {
      setSnackbarMessage("Select the recipes before deleting");
      setSnackbarOpen(true);
      return;
    }

    setSavedRecipeLoading(true);
    const idArray = selectedRecipes.map((recipe) => recipe._id);

    try {
      const deleteResponse = await recipeService.deleteRecipes(
        "saved",
        idArray
      );
      const { message, success } = deleteResponse;

      setSnackbarMessage(message);
      if (success) {
        setSavedRecipes((prev) =>
          prev.filter((recipe) => !idArray.includes(recipe._id))
        );
        setSelectedRecipes([]);
      }
    } catch (error) {
      setSnackbarMessage("Some error has happened");
    } finally {
      setSavedRecipeLoading(false);
      setSnackbarOpen(true);
    }
  };

  //// Funciton for tranfering the recipes to the prod----------------------------------------------------------/
  const transferSavedToProd = useCallback(
    async (selectedRecipes: SingleRecipe[]) => {
      if (selectedRecipes.length === 0) {
        setSnackbarMessage("Select the recipes before transferring");
        setSnackbarOpen(true);
        return;
      }

      setSavedRecipeLoading(true);
      try {
        const transferRecipesResponse =
          await recipeService.tranferRecipesToProd(selectedRecipes);

        const { message, success } = transferRecipesResponse;

        setSnackbarMessage(message);
        setSelectedRecipes([]);
        setSnackbarOpen(true);
        if (!success) {
          setSavedRecipeLoading(false);
        }
      } catch (error) {
        setSavedRecipeLoading(false);
        setSnackbarMessage("Some error has happened");
        setSnackbarOpen(true);
      } finally {
        setSavedRecipeLoading(false);
      }
    },
    []
  );

  if (savedRecipeLoading) {
    return (
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      {savedRecipes.length > 0 ? (
        <>
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              marginBottom: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => deleteCards(selectedRecipes)}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginLeft: 2 }}
                onClick={() => transferSavedToProd(selectedRecipes)}
              >
                Save to Prod
              </Button>
            </Box>
          </Container>
          {savedRecipes.map((recipe, index) => (
            <RecipeCard
              selectedRecipes={selectedRecipes}
              fetchSavedRecipes={fetchSavedRecipes}
              setUpdateScreenName={setUpdateScreenName}
              setRenderMiddleSection={setRenderMiddleSection}
              setSelectedEditingRecipe={setSelectedEditingRecipe}
              recipe={recipe}
              handleSelectRecipe={handleSelectRecipe}
              index={index}
            />
          ))}
        </>
      ) : (
        <EmptyMultiRecipeCard />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </>
  );
};

export default React.memo(Saved);
