import {
  Modal,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import exerciseList from "../helpers/exercises";

const PlanDetailsModal = ({ open, onClose, plan }: any) => {
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          maxHeight: "90vh",
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
          Plan: {plan ? plan.name : ""}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Assigned To: {plan ? plan.userName : ""}
        </Typography>
        <Typography variant="subtitle2">
          Duration:{" "}
          {plan
            ? `${formatDate(plan.startDate)} - ${formatDate(plan.endDate)}`
            : ""}
        </Typography>

        <Box
          sx={{
            maxHeight: 300, // Set a fixed height
            overflowY: "auto", // Make it scrollable
            mt: 2,
          }}
        >
          {plan &&
            plan.workouts.map((workout: any, index: any) => (
              <Paper elevation={3} sx={{ mt: 2, p: 2 }} key={index}>
                <Typography variant="h6" gutterBottom>
                  Workout: {workout.name}
                </Typography>
                <Typography variant="subtitle1">Day: {workout.day}</Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Exercise Name</TableCell>
                      <TableCell>Sets</TableCell>
                      <TableCell>Rep Range</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workout.exercises.map(
                      (exercise: any, exerciseIndex: any) => (
                        <TableRow key={exerciseIndex}>
                          <TableCell style={{ width: "60%" }}>
                            {exerciseList.find(
                              (ex: any) => ex.id == exercise.id
                            )?.exercise || `Unknown (ID: ${exercise.id})`}
                          </TableCell>
                          <TableCell>{exercise.sets}</TableCell>
                          <TableCell>{exercise.repRange}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Paper>
            ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default PlanDetailsModal;
