import React from "react";
import {
  Modal,
  Card,
  CardContent,
  Box,
  Typography,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Paper,
  Container,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MultiRecipeIngredientCard from "./MultiRecipeIngredientCard"; // Assuming this is a custom component for ingredient cards
import { RecipeIngredientInterface } from "../../../interface/RecipeInterface";
import { SingleRecipe } from "../../../interface/RecipeInterface";

interface MultiModalProps {
  isCardOpen: boolean;
  handleCloseCard: () => void;
  recipe: SingleRecipe;
  storageName: string | null;
}

function MultiModal({ isCardOpen, handleCloseCard, recipe }: MultiModalProps) {
  const [openId, setOpenId] = React.useState<number | null>(null);

  const handleClick = (index: number) => {
    setOpenId(openId === index ? null : index);
  };

  return (
    <Modal
      open={isCardOpen}
      onClose={handleCloseCard}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Container
        onClick={handleCloseCard}
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          style={{
            borderRadius: "10px",
            boxShadow: "0 12px 24px rgba(0,0,0,0.3)",
            position: "relative",
            width: "80%",
            zIndex: 2,
            margin: "20px",
            height: "60vh",
            backgroundColor: "#f5f5f5",
            overflowY: "scroll",
          }}
        >
          <CardContent style={{ padding: "20px", borderRadius: "10px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  src={
                    recipe.image
                      ? recipe.image
                      : `https://storageName.blob.core.windows.net/reshape-public/reshape-public/recipes/images/saved/${recipe.id}.png`
                  }
                  alt="Recipe"
                  style={{
                    width: "100%",
                    borderRadius: 4,
                    height: "100%",
                    cursor: "pointer",
                  }}
                  loading="lazy"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "70%",
                  marginLeft: 3,
                }}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  sx={{ fontWeight: "bold" }}
                >
                  {recipe.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "start", width: "100%" }}
                >
                  {recipe.description}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "10px",
              }}
            >
              {[
                { key: "protein", label: "Protein", unit: "g" },
                { key: "carbs", label: "Carbs", unit: "g" },
                { key: "fats", label: "Fat", unit: "g" },
                { key: "calories", label: "Calories", unit: "kcal" },
                { key: "fiber", label: "Fiber", unit: "g" },
                { key: "added_sugar", label: "Added Sugar", unit: "" },
              ].map((nutrient) => (
                <Card
                  key={nutrient.key}
                  sx={{
                    padding: "10px",
                    margin: "5px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                    backgroundColor: "#e3f2fd",
                    width: "22%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      "@media (max-width: 1290px)": {
                        fontSize: 10,
                      },
                    }}
                  >
                    {nutrient.label}: {recipe[nutrient.key]}
                    {nutrient.unit}
                  </Typography>
                </Card>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginTop: "10px",
              }}
            >
              <Typography variant="h6">
                Direction:{" "}
                <span style={{ fontSize: 15 }}>{recipe.direction}</span>
              </Typography>
            </Box>
            <Typography
              variant="h6"
              style={{ marginTop: "20px" }}
              onClick={() => handleClick(0)}
            >
              Ingredients {openId === 0 ? <ExpandLess /> : <ExpandMore />}
            </Typography>
            <Collapse in={openId === 0} timeout="auto" unmountOnExit>
              <List>
                {recipe.ingredients.map(
                  (ingredient: RecipeIngredientInterface, i: number) => (
                    <ListItem
                      key={i}
                      button
                      onClick={() => handleClick(i)}
                      style={{
                        width: "100%",
                        backgroundColor: "#f5f5f5",
                        marginBottom: "5px",
                      }}
                    >
                      <ListItemText
                        primary={
                          <Box
                            sx={{
                              marginBottom: "10px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography sx={{ fontWeight: "bold" }}>
                                {ingredient.name}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                flexWrap: "wrap",
                              }}
                            >
                              {Object.entries(ingredient).map(
                                ([key, value]) => {
                                  if (
                                    [
                                      "calories",
                                      "protein",
                                      "carbs",
                                      "fats",
                                      "added_sugar",
                                      "qty",
                                      "unit",
                                    ].includes(key)
                                  ) {
                                    return (
                                      <Card
                                        key={key}
                                        style={{
                                          padding: "3px",
                                          boxShadow:
                                            "0 4px 8px rgba(0,0,0,0.2)",
                                          backgroundColor: "#e3f2fd",
                                          textAlign: "center",
                                          width: 60,
                                          marginTop: 5,
                                          minWidth: 45,
                                        }}
                                      >
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            fontWeight: "500",
                                            fontSize: 10,
                                          }}
                                        >
                                          {key.charAt(0).toUpperCase() +
                                            key.slice(1)}
                                          :
                                        </Typography>
                                        <Typography variant="body2">
                                          {value}
                                        </Typography>
                                      </Card>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </Box>
                          </Box>
                        }
                      />
                    </ListItem>
                  )
                )}
              </List>
            </Collapse>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
}

export default MultiModal;
