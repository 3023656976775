/** @format */

import React, { useEffect, useRef, useState } from "react";

import { Grid, Box, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import UserDetails from "./UserDetails";
import SideNavbar from "./SideNavbar";
import UserActivity from "./UserMeal";
import BrowseData from "./BrowseData";
import UserActivityByDates from "./UserActivityByDates";
import ChatMain from "./ChatMain";
import { useLocation } from "react-router-dom";
import NotesIcon from "@mui/icons-material/Description";
import { accountService } from "../services";
import { chatService } from "../services/chat.service";
import TrainerChatMain from "./TrainerChatMain";
import NotesModal from "../modules/NotesModal";
import DownloadIcon from "@mui/icons-material/Download";
import { routeService } from "../services/route.service";
import TopAppBar from "../modules/TopAppBar";
import { AppState, SelectedUserInterface } from "../interface/User";
import inititalValueForSqliteDb, {
  userDetailsUtils,
} from "../utils/UserDetails";
import { UserSqliteDetails } from "../interface/UserSqliteCallInterface";
import { userService } from "../services/userdetails.service";
const Main = () => {
  const location = useLocation();
  const { userId, date } = location.state || {};
  // Use URLSearchParams to parse query parameters
  const query = new URLSearchParams(location.search);
  const user_id = query.get("userId");

  const [selectedUser, setSelectedUser] =
    React.useState<SelectedUserInterface | null>(null);

  const [allUsers, setAllUsers] = React.useState([]);
  const [changedUser, setChangedUser] = React.useState<any>();
  const [activity, setActivity] = React.useState(user_id ? "chat" : "byDate");
  const [showChat, setShowChat] = React.useState(false);
  const [mainUserId, setMainUserId] = React.useState();
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState<number>();
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [permission, setPermissions] = useState<Array<string>>([]);

  const [userDataLoading, setUserDataLoading] = useState(false);
  const [appState, setAppState] = useState<AppState>(inititalValueForSqliteDb);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const handleNotesModalClose = () => {
    setNotesModalOpen(false);
  };

  /// Function for opening the chat view----------------------------/
  async function openChat(selectedUser: any) {
    const mainUser = await accountService.userValue;

    if (selectedUser.messageSeen && selectedUser.messageSeen === true) {
      /// user have new message so first it will be turned as false then chat will be open----/
      const response = await chatService.messageSeen(
        selectedUser._id,
        mainUser.role
      );

      if (response.success === true) {
        setChangedUser({ ...selectedUser, messageSeen: false });
        setShowChat(true);
      }
    } else {
      /// Only the chat will be opened in this case because user do not have any new message

      setShowChat(true);
    }
  }

  /// Function For making the api call of all meals and physcial activity---------------------------/
  async function dataApiCall(selectedUser: any) {
    // Cancel the previous request if it is still active
    if (abortController) {
      abortController.abort();
    }

    // Create a new AbortController for the current request
    const controller = new AbortController();
    setAbortController(controller);

    setUserDataLoading(true);
    try {
      const loginUser = accountService.userValue;
      // Execute two service calls in parallel using Promise.all
      const [totalDataResponse, userDetailsData] = await Promise.all([
        userService.getTotalData(selectedUser?._id, loginUser.role),
        accountService.getUserDetailsData(selectedUser?._id),
      ]);

      // Check for the success response of getTotalData
      if (!totalDataResponse.success) {
        alert("Unable to fetch total data");
        return;
      }
      setSelectedUser(totalDataResponse.data);
      const fetchedData = JSON.parse(userDetailsData.latestActivity);
      const activityData = JSON.parse(fetchedData.data);
      let activityName = userDetailsData.latestActivity.tableName;

      const latestBaseTarget = userDetailsData.targetData
        .filter((target) => target.is_base === 1) // Filter to only entries with is_base = 1
        .sort((a, b) => {
          // Parse date strings to Date objects if necessary
          const dateA = new Date(a.dateTime);
          const dateB = new Date(b.dateTime);

          // Ensure both dates are valid before comparison
          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            console.warn("Invalid date encountered in targetData");
            return 0; // Keep the order unchanged if invalid date is found
          }

          return dateB.getTime() - dateA.getTime(); // Sort by dateTime in descending order
        })
        .shift(); // Get the last (latest) matching entry

      setAppState((prevState) => ({
        ...prevState,
        physicalData: {
          steps:
            userDetailsData.physicalData.steps || prevState.physicalData.steps,
          water:
            userDetailsData.physicalData.water || prevState.physicalData.water,
          weights:
            userDetailsData.physicalData.weights ||
            prevState.physicalData.weights,
          workouts:
            userDetailsData.physicalData.workouts ||
            prevState.physicalData.workouts,
          sleep:
            userDetailsData.physicalData.sleep || prevState.physicalData.sleep,
        },
        workoutByDate: userDetailsData.workoutByDate,
        userTargets: {
          ...prevState.userTargets,
          ...(latestBaseTarget || {}), // Use latest target data
        },
        currentWeight:
          userDetailsData.currentWeight.currentWeight !== "Not Available"
            ? userDetailsData.currentWeight.currentWeight
            : prevState.currentWeight,
        lastAppActivityData: {
          activityData: activityData[0],
          tableName: activityName,
        },
        totalTargets: userDetailsData.targetData,
        meals: userDetailsData.mealData || prevState.meals,
        selectedUser: totalDataResponse.data, // Set data from getTotalData
      }));
    } catch (error: any) {
      setAppState(inititalValueForSqliteDb);
    } finally {
      setUserDataLoading(false);
    }
  }

  // React.useEffect(() => {
  //   //Clears the existing timeout if the effect is called again within 1 second
  //   if (requestTimeoutRef.current) {
  //     clearTimeout(requestTimeoutRef.current);
  //   }

  //   requestTimeoutRef.current = setTimeout(() => {
  //     if (selectedUser) {
  //       dataApiCall(selectedUser._id, selectedUser);
  //     }
  //   }, 1000); // Sets a 1-second delay for the operation

  //   // Cleanup function to clear the timeout when the component unmounts or before the effect runs again
  //   return () => {
  //     if (requestTimeoutRef.current) {
  //       clearTimeout(requestTimeoutRef.current);
  //     }
  //   };
  // }, [selectedUser]);

  React.useEffect(() => {
    // Fetch data once when the component is mounted
    const fetchInitialData = async () => {
      // Check if permissions are already stored in localStorage
      const storedPermissions = localStorage.getItem("permissions");

      if (storedPermissions) {
        // If permissions are found in localStorage, use them
        setPermissions(JSON.parse(storedPermissions));
      } else {
        // If not found, fetch permissions and store in localStorage
        const loggedUser = await accountService.userValue;
        const response = await routeService.getDashboardPermission(
          loggedUser.role
        );

        // Store the permissions in local storage
        localStorage.setItem("permissions", JSON.stringify(response));

        setPermissions(response);

        setMainUserId(loggedUser.id);
      }
    };

    // Run only on the first render (initial mount)
    if (user_id === null) {
      fetchInitialData();
    }
  }, []); // Empty dependency array ensures this only runs once on mount

  // Chat opening logic when selectedUser or showChat changes
  React.useEffect(() => {
    if (showChat || user_id !== null) {
      openChat(selectedUser);
    }
  }, [selectedUser, showChat, user_id]);

  const handleDownloadClick = async (e: any) => {
    e.preventDefault();
    try {
      // If not found, fetch permissions and store in localStorage
      const loggedUser = await accountService.userValue;

      const downloadUrl = `https://${loggedUser?.storageAccount}.blob.core.windows.net/user-data/${selectedUser?._id}/${selectedUser?._id}.db?${loggedUser?.sasToken}`;

      const response = await fetch(downloadUrl, { method: "HEAD" });
      if (response.ok) {
        // If the file exists, simulate a click on the link to download the file
        window.location.href = downloadUrl;
      } else {
        alert("The file does not exist.");
      }
    } catch (error) {
      alert("There was an error checking the file.");
    }
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <TopAppBar
          allUsers={allUsers}
          totalNumberOfUsers={totalNumberOfUsers}
        />
      </Grid>
      <Grid item container>
        <Grid item sx={{ p: 1 }} xs={12} md={3}>
          <SideNavbar
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            changedUser={changedUser}
            setAllUsers={setAllUsers}
            totalNumberOfUsers={totalNumberOfUsers}
            setTotalNumberOfUsers={setTotalNumberOfUsers}
            setAppState={setAppState}
            setUserDataLoading={setUserDataLoading}
            dataApiCall={dataApiCall}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={5}
          style={{
            marginBottom: 10,
            filter: selectedUser === null ? "blur(4px)" : "none", // Apply blur effect conditionally
            transition: "filter 0.3s ease-in-out", // Smooth transition for the blur effect
          }}
        >
          {(permission.includes("user_read_basic") ||
            permission.includes("full_access")) && (
            <UserDetails
              selectedUser={selectedUser}
              appState={appState}
              setAppState={setAppState}
              userDataLoading={userDataLoading}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12} // Full width on extra small screens
          md={4} // Half width on medium screens
          style={{
            filter: selectedUser === null ? "blur(4px)" : "none", // Apply blur effect conditionally
            transition: "filter 0.3s ease-in-out", // Smooth transition for the blur effect
          }}
        >
          {/* <Chat /> */}

          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ToggleButtonGroup
                value={activity}
                exclusive
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    if (showChat === true) {
                      setShowChat(false);
                    }
                    // to prevent unselecting the currently selected button
                    setActivity(newValue);
                  }
                }}
                aria-label="activity"
              >
                {permission.includes("full_access") && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ToggleButton
                      value="byDate"
                      sx={{
                        "@media screen and (max-width: 1272px)": {
                          padding: "1px", // Padding for screens smaller than 1272px
                          fontSize: "16px",
                        },
                      }}
                      aria-label="by date"
                    >
                      By date
                    </ToggleButton>

                    <ToggleButton
                      value="physical"
                      sx={{
                        "@media screen and (max-width: 1272px)": {
                          padding: "1px", // Padding for screens smaller than 1272px
                          fontSize: "16px",
                        },
                      }}
                      aria-label="user details"
                    >
                      Browse Data
                    </ToggleButton>
                  </div>
                )}
                {(permission.includes("full_access") ||
                  permission.includes("user_chat")) && (
                  <ToggleButton
                    value="chat"
                    aria-label="user details"
                    sx={{
                      "@media screen and (max-width: 1272px)": {
                        padding: "1px", // Padding for screens smaller than 1202px
                        fontSize: "16px",
                      },
                    }}
                    onClick={() => openChat(selectedUser)}
                  >
                    Chat
                  </ToggleButton>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 2,
                  }}
                >
                  {(permission.includes("user_notes") ||
                    permission.includes("full_access")) && (
                    <NotesIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => setNotesModalOpen(true)}
                    />
                  )}

                  {permission.includes("full_access") && (
                    <a download onClick={handleDownloadClick}>
                      <DownloadIcon
                        sx={{
                          cursor: "pointer",
                          marginLeft: 1,
                          color: "black",
                        }}
                      />
                    </a>
                  )}
                </Box>
                {/* <ToggleButton value="sets" aria-label="user details">
                  Sets
                </ToggleButton> */}
                {/* <ToggleButton
                  value="workout"
                  aria-label="user details"
                  onClick={handleOpen}
                >
                  Workout Plan
                </ToggleButton> */}
              </ToggleButtonGroup>
            </div>

            {showChat ? (
              permission.includes("full_access") ||
              permission.includes("user_chat") ? (
                <ChatMain
                  setShowChat={setShowChat}
                  selectedUserId={selectedUser?._id}
                  loggedUser={mainUserId ? mainUserId : null}
                />
              ) : (
                <TrainerChatMain
                  setShowChat={setShowChat}
                  selectedUserId={selectedUser?._id}
                  loggedUser={mainUserId ? mainUserId : null}
                />
              )
            ) : (
              <>
                {permission.includes("full_access") && (
                  <>
                    {activity === "byDate" && (
                      <UserActivityByDates
                        selectedUser={selectedUser}
                        redirectingDate={date}
                        appState={appState}
                        userDataLoading={userDataLoading}
                      />
                    )}
                    {activity === "physical" && (
                      <Box sx={{ marginTop: 5 }}>
                        <BrowseData selectedUser={selectedUser} />
                      </Box>
                    )}
                    {activity === "onboardingChatSummary" && (
                      <Box sx={{ marginTop: 5 }}>
                        <Typography>
                          {selectedUser?.onboardingChatSummary}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </Grid>
      </Grid>
      {notesModalOpen && (
        <NotesModal
          handleNotesModalClose={handleNotesModalClose}
          notesModalOpen={notesModalOpen}
          selectedUser={selectedUser}
        />
      )}
    </Grid>
  );
};

export default Main;
