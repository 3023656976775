/** @format */

import React, { useEffect, useMemo, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";
import { CSSProperties } from "react";
import { userService } from "../services/userdetails.service";
import { Container, CircularProgress } from "@mui/material";

interface Weight {
  selectedUser: any;
  targetWeight?: any;
}

const Weight = ({ selectedUser, targetWeight }: Weight) => {
  const [graphData, setGraphData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [minYAxisValue, setMinYAxisValue] = useState<number>(0);
  const [maxYAxisValue, setMaxYAxisValue] = useState<number>(0);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await userService.getWeight(selectedUser._id);

        if (response && response.weightData) {
          setGraphData(response.weightData);

          // Find the minimum weight between targetWeight and the first weight data point
          const minWeight = Math.min(
            targetWeight,
            response.weightData.length > 0
              ? response.weightData[0].weight
              : Infinity
          );

          let value = minWeight - 10;
          // Set the minimum Y-axis value to the minimum weight - 10
          const newMinYAxisValue = Math.round(value);

          // Find the maximum weight among the data points
          const maxWeight =
            response.weightData?.length > 0
              ? Math.max(
                  ...response.weightData.map((entry: any) => entry.weight)
                )
              : 0;

          // Compare the maximum weight with targetWeight and add padding
          const maxValue = Math.max(maxWeight, targetWeight) + 10;

          // Round the value to remove decimals
          const newMaxYAxisValue = Math.round(maxValue);

          // Update the Y-axis domain dynamically
          setMaxYAxisValue(newMaxYAxisValue);
          // Update the Y-axis domain dynamically
          setMinYAxisValue(newMinYAxisValue);
        } else {
          setGraphData([]);
        }
      } catch (error) {
        setGraphData([]);
      }
      setLoading(false);
    }

    fetchData();
  }, [selectedUser, targetWeight]);

  return (
    <>
      {loading ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress style={{ marginTop: 50 }} />
        </Container>
      ) : (
        <LineChart
          width={500}
          height={330}
          data={graphData}
          margin={{
            top: 5,
            left: -10,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" padding={{ left: 30, right: 20 }} />
          <YAxis
            domain={[minYAxisValue, maxYAxisValue]}
            allowDataOverflow={true}
            tickCount={5}
          />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="weight"
            stroke="#1067bf"
            activeDot={{ r: 8 }}
          />
          <ReferenceLine
            y={targetWeight}
            stroke="#1067bf"
            strokeDasharray="3 3"
          />
        </LineChart>
      )}
    </>
  );
};

export default Weight;
