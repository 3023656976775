import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  TextField,
  Button,
  Snackbar,
  Box,
  Typography,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { settingsService } from "../../services/settings.service";
import { accountService } from "../../services/account.service";

interface WeeklyReportPrompts {
  [key: string]: string;
}

// Helper function to generate validation schema dynamically based on keys
const generateValidationSchema = (keys: string[]) => {
  const schemaShape = keys.reduce((shape, key) => {
    shape[key] = yup.string().required(`${key} is required`);
    return shape;
  }, {} as Record<string, yup.StringSchema<string>>);
  return yup.object().shape(schemaShape);
};

const WeeklyReportSettings: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<WeeklyReportPrompts>({});
  const [validationSchema, setValidationSchema] = useState(yup.object());
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const storageNameResponse = await accountService.getStorageName();
        const response = await fetch(
          `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/weekly-report-prompts.json`
        );
        const data: WeeklyReportPrompts = await response.json();

        setInitialValues(data);
        setValidationSchema(generateValidationSchema(Object.keys(data)));
      } catch (error) {
        console.error("Failed to fetch prompts:", error);
        setSnackbarMessage("Failed to fetch prompts. Please try again later.");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchPrompts();
  }, []);

  const handleSubmit = async (values: WeeklyReportPrompts) => {
    setSubmitLoading(true);
    try {
      console.log("this are values");

      console.log(values);

      const updateResponse = await settingsService.updateWeeklyReportSettings(
        values
      );
      const { message, updatedData, status } = updateResponse;
      if (status === true) {
        setInitialValues(updatedData);
        setSnackbarMessage(message);
      } else {
        setSnackbarMessage(message);
      }
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Failed to update prompts:", error);
      setSnackbarMessage("Failed to save prompts. Please try again later.");
      setSnackbarOpen(true);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box p={4} style={{ backgroundColor: "#f0f0f0" }}>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: "700" }}>
          Weekly Report Settings
        </Typography>
      </Container>
      {loading ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={3} justifyContent="center">
                {Object.keys(initialValues).map((key) => (
                  <Grid item xs={12} md={6} key={key}>
                    <Field
                      as={TextField}
                      label={key}
                      name={key}
                      placeholder={key}
                      multiline
                      rows={6}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  {submitLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      style={{ marginTop: 20 }}
                    >
                      Submit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Box>
  );
};

export default WeeklyReportSettings;
