import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { config } from "../shared/constants/config";

import { Container, CircularProgress } from "@mui/material";

import MealDetails from "../modules/MealDetails";

import PopupComponent from "../modules/ImageModal";
interface UserMeal{
 loading:boolean;
mealData:any
}
function UserMeal({ mealData,loading }: UserMeal) {
 
  const [imageModal, setImageModal] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState();
  const [videoAttachment, setVideoAttachment] = React.useState<boolean>();


 

 

  return (
    <Container
      style={{ display: "flex", flexDirection: "column", height: "70vh" }}
    >
      <Typography
        sx={{
          marginBottom: 4,
          fontWeight: "bold",
          marginTop: 2,
          textAlign: "center",
        }}
      >
        Browse Meals
      </Typography>
      {loading?(<Container
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress style={{ marginTop: 10 }} />
      </Container>):(<>{mealData && mealData.length > 0 ? (
         <TableContainer
         component={Paper}
         sx={{ width: "100%", overflowY: "scroll" }}
       >
         <Table aria-label="collapsible table">
           <TableHead>
             <TableRow>
               <TableCell />
               <TableCell
                 align="center"
                 style={{
                   position: "sticky",
                   top: 0,
                   backgroundColor: "white",
                 }}
               >
                 Image
               </TableCell>
               <TableCell
                 align="center"
                 style={{
                   position: "sticky",
                   top: 0,
                   backgroundColor: "white",
                 }}
               >
                 Meal Name
               </TableCell>
               <TableCell
                 align="right"
                 style={{
                   position: "sticky",
                   top: 0,
                   backgroundColor: "white",
                 }}
               >
                 Calorie(g)
               </TableCell>
               <TableCell
                 align="right"
                 style={{
                   position: "sticky",
                   top: 0,
                   backgroundColor: "white",
                 }}
               >
                 Protein(g)
               </TableCell>
               <TableCell
                 align="right"
                 style={{
                   position: "sticky",
                   top: 0,
                   backgroundColor: "white",
                 }}
               >
                 Quantity
               </TableCell>

               <TableCell
                 align="right"
                 style={{
                   position: "sticky",
                   top: 0,
                   backgroundColor: "white",
                 }}
               >
                 DateTime
               </TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {mealData.map((row:any, index:number) => (
               <MealDetails
                 key={index}
                 row={row}
                 setImageUrl={setImageUrl}
                 setImageModal={setImageModal}
               />
             ))}
           </TableBody>
         </Table>
       </TableContainer>
      ) : (
        <Container>
        <Typography style={{ textAlign: "center" }}>
          No meal available
        </Typography>
      </Container>
      )}</>)}
      

      <PopupComponent
        isPopupOpen={imageModal}
        imageUrl={imageUrl}
        setIsPopupOpen={setImageModal}
        isVideo={videoAttachment}
      />
    </Container>
  );
}

export default UserMeal;
