/** @format */

import SingleMeal from "./SingleMeal";
import IngredientMeal from "./IngredientMeal";

export default function MealTable(props: any) {
  const { row, setImageUrl, setImageModal } = props;

  const totalCalories = row.ingredients.reduce(
    (acc: number, ingredient: any) => acc + ingredient.calories,
    0
  );
  const totalProtein = row.ingredients.reduce(
    (acc: number, ingredient: any) => acc + ingredient.protein,
    0
  );
  const totalQuantity = row.ingredients.reduce(
    (acc: number, ingredient: any) => acc + ingredient.quantity,
    0
  );
  return (
    <>
      {row.singleMealData.length === 0 ? (
        <>
          <IngredientMeal
            row={row}
            totalCalories={totalCalories}
            totalQuantity={totalQuantity}
            totalProtein={totalProtein}
            setImageModal={setImageModal}
            setImageUrl={setImageUrl}
          />
        </>
      ) : (
        <>
          <SingleMeal
            row={row}
            setImageModal={setImageModal}
            setImageUrl={setImageUrl}
          />
        </>
      )}
    </>
  );
}
