/** @format */

import { Box, Grid } from "@mui/material";
import TopAppBar from "../modules/TopAppBar";
import RecipeLeftNavbar from "../Components/Recipes/RecipeLeftNavbar";
import { useState } from "react";

import DetailsSection from "../Components/Recipes/DetailsSection";
import { SingleRecipe } from "../interface/RecipeInterface";
const Recipes = () => {
  const [renderMiddleSection, setRenderMiddleSection] = useState("single");
  const [selecteEditingdRecipe, setSelectedEditingRecipe] =
    useState<SingleRecipe | null>(null);
  const [updateScreenName, setUpdateScreenName] = useState("");
  return (
    <div style={{ height: "100vh" }}>
      <TopAppBar />
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <RecipeLeftNavbar
          renderMiddleSection={renderMiddleSection}
          setRenderMiddleSection={setRenderMiddleSection}
        />

        <Grid container>
          <DetailsSection
            renderMiddleSection={renderMiddleSection}
            setRenderMiddleSection={setRenderMiddleSection}
            setSelectedEditingRecipe={setSelectedEditingRecipe}
            selecteEditingdRecipe={selecteEditingdRecipe}
            setUpdateScreenName={setUpdateScreenName}
            updateScreenName={updateScreenName}
          />
        </Grid>
      </Box>
    </div>
  );
};

export default Recipes;
