import { memo, useState } from "react";
import { Formik, Form, Field } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Container,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { accountService } from "../services";

interface PasswordModal {
  passwordModalOpen: boolean;
  setpasswordModalOpen: (value: boolean) => void;
  formData: any;
  handelApiCall: any;
  selectedUserIds: any;
  loading: boolean;
  msg: string;
  setMsg: (value: string) => void;
}

function PasswordModal({
  passwordModalOpen,
  setpasswordModalOpen,
  formData,
  handelApiCall,
  selectedUserIds,
  loading,
  msg,
  setMsg,
}: PasswordModal) {
  /// Function for closing the modal-------------------------/

  const handleCloseModal = () => {
    setMsg("");
    setpasswordModalOpen(false);
  };

  const initialValues = {
    password: "",
  };

  /// Function For handeling the submit button event -------------------------------/
  const handleSubmit = async (values: any) => {
    if (values.password === "") {
      alert("Enter the password");
    } else {
      let user = await accountService.userValue;
      const { id } = user;
     
      
      handelApiCall(
        
        formData.title,
        formData.body,
        formData.type,
    
        values.password
      );
    }
    // Handle the form submission here
  };
  return (
    <Modal
      open={passwordModalOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 350,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Container
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleCloseModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </Container>
                <Typography id="modal-title" style={{ fontWeight: "800" }}>
                  Enter Your Password to Confirm
                </Typography>
                <Field
                  type="password"
                  name="password"
                  as={TextField}
                  label="Password"
                  variant="outlined"
                  style={{ width: "100%", marginTop: 10 }}
                />
                {msg !== "" ? (
                  <Container
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{ marginTop: 5, fontSize: 10, color: "red" }}
                    >
                      {msg}
                    </Typography>
                  </Container>
                ) : null}
                {loading ? (
                  <Container
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress style={{ marginTop: 8 }} />
                  </Container>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    style={{ width: "100%", marginTop: 10 }}
                  >
                    Confirm
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default memo(PasswordModal);
