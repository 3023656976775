import React from "react";
import { Modal, Box, IconButton, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { RecipeImageModalInterface } from "../../interface/RecipeInterface";

function RecipeImageModal({
  recipeImageModalOpen,
  closeRecipeImageModal,
  recipeImage,
  recipeId,
  storageName,
}: RecipeImageModalInterface) {
  return (
    <div>
      <Modal
        open={recipeImageModalOpen}
        onClose={closeRecipeImageModal}
        aria-labelledby="recipe-image-modal"
        aria-describedby="recipe-image-modal-description"
      >
        <Box
          onClick={closeRecipeImageModal}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: 1000,
          }}
        >
          <Paper
            onClick={(e) => e.stopPropagation()}
            sx={{
              position: "relative",
              backgroundColor: "#fff",
              borderRadius: 2,
              padding: 2,
              maxWidth: "80%",
              maxHeight: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: 3,
              animation: "float 0.3s ease-out",
              overflow: "hidden",
            }}
          >
            <img
              src={
                recipeImage
                  ? recipeImage
                  : `https://${storageName}.blob.core.windows.net/reshape-public/reshape-public/recipes/images/saved/${recipeId}.png`
              }
              alt="Recipe"
              style={{
                width: "100%",
                maxWidth: "500px",
                height: "auto",
                borderRadius: "10px",
              }}
            />
          </Paper>
        </Box>
      </Modal>
    </div>
  );
}

export default RecipeImageModal;
