/** @format */

import { useCallback, useEffect, useState } from "react";
import { Container, Typography, CircularProgress, Box } from "@mui/material";
import { userService } from "../../services/userdetails.service";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { workoutUtilsFunctions } from "../../utils/Workout";
import ExerciseTable from "../../modules/ExerciseTable";
import { WorkoutByDateInterface } from "../../interface/OwnCreatedWorkoutInterface";
import { OwnWorkoutInterface } from "../../interface/OwnCreatedWorkoutInterface";
export default function WorkoutByDate({
  workout,
  currentDate,
  setCurrentDate,
}: WorkoutByDateInterface) {
  const [filteredExercises, setFilteredExercises] = useState<any>();

  /// Function to call the service function to get the filterted workouts----/
  async function getFiltertedWorkouts(
    workout: OwnWorkoutInterface[],
    currentDate: any
  ) {
    const filterWorkoutResponse =
      await workoutUtilsFunctions.getCurrentDayWorkout(workout, currentDate);

    setFilteredExercises(filterWorkoutResponse);
  }

  useEffect(() => {
    getFiltertedWorkouts(workout, currentDate);
  }, [workout, currentDate]);

  /// Function for increasing the date with arrow--------------------------/
  const increaseDate = async () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);
    setCurrentDate(newDate);
    getFiltertedWorkouts(workout, newDate);
  };

  /// Function for decreasing the date with arrow----------------------------/
  const decreaseDate = async () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 1);
    setCurrentDate(newDate);
    getFiltertedWorkouts(workout, newDate);
  };
  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 2,
        }}
      >
        <ArrowBackIosIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            decreaseDate();
          }}
        />
        <Typography style={{ textAlign: "center", fontWeight: "800" }}>
          {currentDate.toDateString()}
        </Typography>
        <ArrowForwardIosIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (currentDate.getDate() === new Date().getDate()) {
              return;
            } else {
              increaseDate();
            }
          }}
        />
      </Container>
      <Container>
        <Box>
          <Container
            component={Paper}
            sx={{
              width: "100%",

              marginTop: 2,
              height: "60vh",
              overflowX: "hidden",
              "@media (max-width: 1500px)": {
                overflowX: "scroll",
              },
              "&::-webkit-scrollbar": {
                display: "auto", // Always show scrollbar
              },
            }}
          >
            <div style={{ width: "100%" }}>
              {filteredExercises && filteredExercises.length > 0 ? (
                <div>
                  {filteredExercises.map(
                    (workoutDetails: OwnWorkoutInterface, index: any) => {
                      return (
                        <ExerciseTable
                          workoutDetails={workoutDetails}
                          currentDate={currentDate}
                          index={index}
                        />
                      );
                    }
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ marginTop: 10 }}>
                    No Workout History available
                  </Typography>
                </div>
              )}
            </div>
          </Container>
        </Box>
      </Container>
    </>
  );
}
