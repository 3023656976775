const exerciseList = [
  {
    id: 1,
    exercise: "Seated Dumbbell Overhead Press",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 2,
    exercise: "Seated Dumbbell Overhead Press, Single Arm",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 3,
    exercise: "Standing Dumbbell Overhead Press",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 4,
    exercise: "Standing Dumbbell Overhead Press, Single Arm",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 5,
    exercise: "Seated Barbell Overhead Press",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 6,
    exercise: "Seated Barbell Behind the Neck Press",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 7,
    exercise: "Standing Barbell Overhead Press",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 8,
    exercise: "Standing Barbell Behind The Neck Press",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 9,
    exercise: "Smith Machine Overhead Press, Seated",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 10,
    exercise: "Smith Macine Overhead Press, Seated, Behind The Neck",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 11,
    exercise: "Plate Loaded Machine Overhead Press",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 12,
    exercise: "Plate Loaded Machine Overhead Press, Single Arm",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 13,
    exercise: "Selector Overhead Press Machine, Wide",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 14,
    exercise: "Selector Overhead Press Machine, Narrow",
    category: "Shoulders",
    sub_category: "Triceps, Traps",
  },
  {
    id: 15,
    exercise: "Barbell Upright Row",
    category: "Shoulders",
    sub_category: "Biceps, Traps",
  },
  {
    id: 16,
    exercise: "EZ Bar Upight Row",
    category: "Shoulders",
    sub_category: "Biceps, Traps",
  },
  {
    id: 17,
    exercise: "Cable Upright Row, Rope",
    category: "Shoulders",
    sub_category: "Biceps, Traps",
  },
  {
    id: 18,
    exercise: "Dumbbell Upright Row",
    category: "Shoulders",
    sub_category: "Biceps, Traps",
  },
  {
    id: 19,
    exercise: "Standing Dumbbell Lateral Raise",
    category: "Shoulders",
    sub_category: "Traps",
  },
  {
    id: 20,
    exercise: "Seated Dumbbell Lateral Raise",
    category: "Shoulders",
    sub_category: "Traps",
  },
  {
    id: 21,
    exercise: "Single Arm cable Lateral Raise",
    category: "Shoulders",
    sub_category: "Traps",
  },
  {
    id: 22,
    exercise: "Standing Barbell Front Raise",
    category: "Shoulders",
    sub_category: "Chest",
  },
  {
    id: 23,
    exercise: "Standing Dumbbell Front Raise",
    category: "Shoulders",
    sub_category: "Chest",
  },
  {
    id: 24,
    exercise: "Seated Dumbbell Front Raise",
    category: "Shoulders",
    sub_category: "Chest",
  },
  {
    id: 25,
    exercise: "Standing Cable Front Raise",
    category: "Shoulders",
    sub_category: "Chest",
  },
  {
    id: 26,
    exercise: "Rear Delt Machine ",
    category: "Shoulders",
    sub_category: "Traps",
  },
  {
    id: 27,
    exercise: "Dumbbell Bent Over Reverse Fly",
    category: "Shoulders",
    sub_category: "Traps",
  },
  {
    id: 28,
    exercise: "Dumbbell Incline Prone Reverse Fly",
    category: "Shoulders",
    sub_category: "Traps",
  },
  {
    id: 29,
    exercise: "Single Arm Cable Reverse Fly",
    category: "Shoulders",
    sub_category: "Traps",
  },
  {
    id: 30,
    exercise: "Cable Face Pull",
    category: "Shoulders",
    sub_category: "Traps, Biceps",
  },
  {
    id: 31,
    exercise: "Barbell Bench Press",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 32,
    exercise: "Barbell Bench Press, Close Grip",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 33,
    exercise: "Incline Barbell Bench Press",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 34,
    exercise: "Incline Barbell Bench Press, Close Grip",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 35,
    exercise: "Decline Barbell Bench Press",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 36,
    exercise: "Decline Barbell Bench Press, Close Grip",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 37,
    exercise: "Dumbbell Bench Press",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 38,
    exercise: "Dumbbell Fly",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 39,
    exercise: "Incline Dumbbell Bench Press",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 40,
    exercise: "Incline Dumbbell Fly",
    category: "Chest",
    sub_category: "Shoulders",
  },
  {
    id: 41,
    exercise: "Decline Dumbbell Fly",
    category: "Chest",
    sub_category: "Shoulders",
  },
  {
    id: 42,
    exercise: "Smith Machine Bench Press",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 43,
    exercise: "Smith Machine Bench Press, Close Grip",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 44,
    exercise: "Smiith Machine Incline Bench Press",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 45,
    exercise: "Smith Machine Incline Bench Press, Close Grip",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 46,
    exercise: "Medium Cable Fly",
    category: "Chest",
    sub_category: "Shoulders",
  },
  {
    id: 47,
    exercise: "High Cable Fly",
    category: "Chest",
    sub_category: "Shoulders",
  },
  {
    id: 48,
    exercise: "Low Cable Fly",
    category: "Chest",
    sub_category: "Shoulders",
  },
  {
    id: 49,
    exercise: "Plate Loaded Chest Press Machine",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 50,
    exercise: "Plate Loaded Incline Chest Press Machine",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 51,
    exercise: "Selector Chest Press Machine",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 52,
    exercise: "Chest Fly Machine",
    category: "Chest",
    sub_category: "Shoulders",
  },
  {
    id: 53,
    exercise: "Push up",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 54,
    exercise: "Push up, Close Grip",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 55,
    exercise: "Incline Push up",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 56,
    exercise: "Incline Push up, Close Grip",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 57,
    exercise: "Dips",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 58,
    exercise: "Dips, Weighted",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 59,
    exercise: "Machine supported Dips",
    category: "Chest",
    sub_category: "Shoulders, Triceps",
  },
  {
    id: 60,
    exercise: "EZ Bar Standing Barbell Curl, Narrow Grip",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 61,
    exercise: "EZ Bar Standing Barbell Curl, Wide Grip",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 62,
    exercise: "Standing Straight Bar Barbell Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 63,
    exercise: "Standing Barbell Curl, Overhand Grip",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 64,
    exercise: "Standing H Bar Barbell Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 65,
    exercise: "EZ Bar Preacher Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 66,
    exercise: "H Bar Preacher Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 67,
    exercise: "Overhand Grip Straight Bar Preacher Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 68,
    exercise: "Standing Dumbbell Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 69,
    exercise: "Standing Dumbbell Hammer Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 70,
    exercise: "Seated Dumbbell Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 71,
    exercise: "Seated Dumbbell Hammer Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 72,
    exercise: "Incline Dumbbell Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 73,
    exercise: "Incline Dumbbell Hammer Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 74,
    exercise: "Dumbbell Preacher Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 75,
    exercise: "Dumbbell Hammer Preacher Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 76,
    exercise: "Dumbbell Preacher Curl, Single Arm",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 77,
    exercise: "Dumbbell Hammer Preacher Curl, Single Arm",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 78,
    exercise: "Concentration Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 79,
    exercise: "Standing Cable Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 80,
    exercise: "Standing Cable Hammer Curl",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 81,
    exercise: "Standing Cable Curl, Overhand Grip",
    category: "biceps",
    sub_category: "Forearms",
  },
  {
    id: 82,
    exercise: "Standing Cable Curl, Single Arm",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 83,
    exercise: "Standing Cable Hammer Curl, Single Arm",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 84,
    exercise: "Preacher Curl Machine",
    category: "Biceps",
    sub_category: "Forearms",
  },
  {
    id: 85,
    exercise: "Preacher Curl Machine, Single Arm",
    category: "biceps",
    sub_category: "Forearms",
  },
  {
    id: 86,
    exercise: "Standing Behind  Back Barbell Wrist Curl",
    category: "Forearms",
    sub_category: null,
  },
  {
    id: 87,
    exercise: "Standing Dumbbell Wrist Curl",
    category: "Forearms",
    sub_category: null,
  },
  {
    id: 88,
    exercise: "Preacher Dumbbell Wrist Curl",
    category: "Forearms",
    sub_category: null,
  },
  {
    id: 89,
    exercise: "Preacher Dumbbell Reverse Wrist Curl",
    category: "Forearms",
    sub_category: null,
  },
  {
    id: 90,
    exercise: "Crunches",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 91,
    exercise: "Situps",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 92,
    exercise: "Situps Weighted",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 93,
    exercise: "Decline Crunches",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 94,
    exercise: "Decline Situps",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 95,
    exercise: "Decline Situps, Weighted",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 96,
    exercise: "V ups",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 97,
    exercise: "V sit ups",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 98,
    exercise: "Lying Leg Raise",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 99,
    exercise: "Lying Leg Raise, Weighted",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 100,
    exercise: "Lying Reverse Crunch up",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 101,
    exercise: "Hanging Knee Raise",
    category: "Abs",
    sub_category: "Forearms",
  },
  {
    id: 102,
    exercise: "Hanging Knee Raise, Weighted",
    category: "Abs",
    sub_category: "Forearms",
  },
  {
    id: 103,
    exercise: "Hanging Leg Raise",
    category: "Abs",
    sub_category: "Forearms",
  },
  {
    id: 104,
    exercise: "Hanging Leg Raise, Weighted",
    category: "Abs",
    sub_category: "Forearms",
  },
  {
    id: 105,
    exercise: "Toes to Bar",
    category: "Abs",
    sub_category: "Forearms",
  },
  {
    id: 106,
    exercise: "Hanging Knee Raise, Ab Station",
    category: "Abs",
    sub_category: "Forearms",
  },
  {
    id: 107,
    exercise: "Hanging Leg Raise, Ab Station",
    category: "Abs",
    sub_category: "Forearms",
  },
  {
    id: 108,
    exercise: "Seated Cable Crunch",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 109,
    exercise: "Ab Machine",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 110,
    exercise: "Plank",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 111,
    exercise: "Hollow",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 112,
    exercise: "Tuck Hollow",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 113,
    exercise: "Fitball Crunches",
    category: "Abs",
    sub_category: null,
  },
  {
    id: 114,
    exercise: "Kneeling Ab Wheel",
    category: "Abs",
    sub_category: "Lats",
  },
  {
    id: 115,
    exercise: "Russian Twist",
    category: "Obliques",
    sub_category: null,
  },
  {
    id: 116,
    exercise: "Decline Russian Twist",
    category: "Obliques",
    sub_category: null,
  },
  {
    id: 117,
    exercise: "Side Plank",
    category: "Obliques",
    sub_category: null,
  },
  {
    id: 118,
    exercise: "Hanging Leg Raise, Windsheild Wipers",
    category: "Obliques",
    sub_category: null,
  },
  {
    id: 119,
    exercise: "Standing Cable Side Bend",
    category: "Obliques",
    sub_category: null,
  },
  {
    id: 120,
    exercise: "Standing Cable Torso Rotations",
    category: "Obliques",
    sub_category: null,
  },
  {
    id: 121,
    exercise: "Seated Cable Russian Twist",
    category: "Obliques",
    sub_category: null,
  },
  {
    id: 122,
    exercise: "Standing Dumbbell Side Bend",
    category: "Obliques",
    sub_category: null,
  },
  {
    id: 123,
    exercise: "Roman Chair Side Bend",
    category: "Obliques",
    sub_category: null,
  },
  {
    id: 124,
    exercise: "Barbell Back Squat",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 125,
    exercise: "Barbell back Squat, Wide Stance",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 126,
    exercise: "Barbell Front Squat",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 127,
    exercise: "Barbell Front Squat, Wide Stance",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 128,
    exercise: "Barbell Lunge",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 129,
    exercise: "Front Rack Barbell Lunge",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 130,
    exercise: "Goblet Squat",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 131,
    exercise: "Goblet Squat Wide Stance",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 132,
    exercise: "Goblet Lunge",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 133,
    exercise: "Goblet Bulgarian Lunge",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 134,
    exercise: "Dumbbell Lunges",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 135,
    exercise: "Dumbbell Walking Lunges",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 136,
    exercise: "Dumbbell Step up",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 137,
    exercise: "Dumbbell Bulgarian Lunge",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 138,
    exercise: "Smith Machine Squat",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 139,
    exercise: "Smith Machine Squat, Wide Stance",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 140,
    exercise: "Smith Machine Lunge",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 141,
    exercise: "Smith Machine Bulagarian Split Squat",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 142,
    exercise: "Leg Press",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 143,
    exercise: "Leg Press Wide Stance",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 144,
    exercise: "Single Leg Press",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 145,
    exercise: "Hack Squat",
    category: "Quads",
    sub_category: null,
  },
  {
    id: 146,
    exercise: "Hack Squat Wide Stance",
    category: "Quads",
    sub_category: null,
  },
  {
    id: 147,
    exercise: "Leg Press Selector Machine",
    category: "Quads",
    sub_category: null,
  },
  {
    id: 148,
    exercise: "Single Leg, Leg Press Selector Machine",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 149,
    exercise: "Leg Extension",
    category: "Quads",
    sub_category: null,
  },
  {
    id: 150,
    exercise: "Single Leg Extension",
    category: "Quads",
    sub_category: null,
  },
  {
    id: 151,
    exercise: "Bodyweight Lunge",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 152,
    exercise: "Bodyweight Bulgarian Lunge",
    category: "Quads",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 153,
    exercise: "Cossack Squat",
    category: "Quads",
    sub_category: "Adductors, Glutes",
  },
  {
    id: 154,
    exercise: "Adduction Machine",
    category: "Adductors",
    sub_category: null,
  },
  {
    id: 155,
    exercise: "Single Leg Cable Adduction ",
    category: "Adductors",
    sub_category: null,
  },
  {
    id: 156,
    exercise: "Satnding Barbell Shrugs ",
    category: "Traps",
    sub_category: null,
  },
  {
    id: 157,
    exercise: "Standing Dumbbell Shrugs",
    category: "Traps",
    sub_category: null,
  },
  {
    id: 158,
    exercise: "Incline Prone Dumbbell Shrugs",
    category: "Traps",
    sub_category: null,
  },
  {
    id: 159,
    exercise: "Standing Cable Triceps Pushdown, Rope",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 160,
    exercise: "Standing Cable Triceps Pushdown, Straight Bar",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 161,
    exercise: "Standing Cable Tricep Pushdown, Single Arm",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 162,
    exercise: "Standing Cable Overhead Triceps Extension, Rope",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 163,
    exercise: "Standing Cable Overhead Triceps Extensions, Straight Bar",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 164,
    exercise: "Standing Cable Overhead Tricep Extensions, Single Arm",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 165,
    exercise: "Cable Triceps Kick Back, Single Arm",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 166,
    exercise: "Seated Dumbbell Overhead Triceps Extensions",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 167,
    exercise: "Seated Dumbbell Overhead Triceps Extensions, Single Arm",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 168,
    exercise: "Standing Dumbbell Overhead Triceps Extensions",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 169,
    exercise: "Standing Dumbbell Overhead Triceps Extensions, Single Arm",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 170,
    exercise: "Dumbbell Skull Crusher",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 171,
    exercise: "Dumbbell Skull Crusher, Behind head",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 172,
    exercise: "Dumbbell Skull Crusher, Single Arm",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 173,
    exercise: "Dumbbell Tricep Kick Back, Incline Prone",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 174,
    exercise: "Prone Triceps Kick Back, Single Arm",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 175,
    exercise: "EZ Bar Skull Crusher",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 176,
    exercise: "EZ Bar Skull Crusher, Behind Head",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 177,
    exercise: "H Bar Skull Crusher ",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 178,
    exercise: "H Bar Skull Crusher, Behind Head",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 179,
    exercise: "Seated EZ Bar Overhead Triceps Extensions",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 180,
    exercise: "Standing EZ Bar Overhead Triceps Extensions",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 181,
    exercise: "Triceps Press Machine",
    category: "Triceps",
    sub_category: null,
  },
  {
    id: 182,
    exercise: "Bench Dips",
    category: "Triceps",
    sub_category: "Shoulders",
  },
  {
    id: 183,
    exercise: "Overhand Grip Pull up",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 184,
    exercise: "Overhand Grip Pull up, Weighted",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 185,
    exercise: "Wide Grip Pull up",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 186,
    exercise: "Wide Grip Pull up, Weighted",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 187,
    exercise: "Neutral Grip Pull Up",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 188,
    exercise: "Neutral Grip Pull Up, Weighted",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 189,
    exercise: "Underhand Grip Pull Up",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 190,
    exercise: "Underhand Grip Pull Up, Weighted",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 191,
    exercise: "Machine Supported Pull Ups, Overhand Grip",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 192,
    exercise: "Machine Supported Pull Ups, Wide Grip",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 193,
    exercise: "Machine Supported Pull Ups, Neutral Grip",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 194,
    exercise: "Overhand Grip Pull up, With Band",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 195,
    exercise: "Neutral Grip Pull Up, With Band",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 196,
    exercise: "Underhand Grip Pull Up, With Band",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 197,
    exercise: "Overhand Grip Lat Pulldown",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 198,
    exercise: "Wide Grip Lat Pulldown",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 199,
    exercise: "Neutral Grip Lat Pulldown",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 200,
    exercise: "Underhand Grip Lat Pulldown",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 201,
    exercise: "Behind The Neck Lat Pulldown",
    category: "Lats",
    sub_category: "Traps, Biceps",
  },
  {
    id: 202,
    exercise: "Lat Pulldown, Single Arm",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 203,
    exercise: "Overhand Grip Plate Loaded Lat Pulldown",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 204,
    exercise: "Neutral Grip Plate Loaded Lat Pulldown",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 205,
    exercise: "Underhand Grip Plate Loaded Lat Pulldown",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 206,
    exercise: "Neutral Grip Plate Loaded Lat Pulldown, Single Arm",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 207,
    exercise: "Underhand Grip Plate Loaded Lat Pulldown, Single Arm",
    category: "Lats",
    sub_category: "Biceps",
  },
  {
    id: 208,
    exercise: "Seated Cable Row, Overhand Grip",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 209,
    exercise: "Seated Cable Row, Underhand Grip",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 210,
    exercise: "Seated Cable Row, Neutral Grip",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 211,
    exercise: "Seated Cable Row, Wide Grip",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 212,
    exercise: "Seated Cable Row, Single Arm",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 213,
    exercise: "Bent Over Barbell Row, Overhand Grip",
    category: "Lats",
    sub_category: "Lower Back, Biceps",
  },
  {
    id: 214,
    exercise: "Bent Over Barbell Row, Underhand Grip",
    category: "Lats",
    sub_category: "Lower Back, Biceps",
  },
  {
    id: 215,
    exercise: "TBar Row",
    category: "Lats",
    sub_category: "Lower Back, Biceps",
  },
  {
    id: 216,
    exercise: "Single Arm Bumbbell row",
    category: "Lats",
    sub_category: "Biceps, Obliques",
  },
  {
    id: 217,
    exercise: "Chest Supported Dumbbell Row",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 218,
    exercise: "Standing Cable Pullover Bar",
    category: "Lats",
    sub_category: "Traps, Chest",
  },
  {
    id: 219,
    exercise: "Standing Cable Pullover Rope",
    category: "Lats",
    sub_category: "Traps, Chest",
  },
  {
    id: 220,
    exercise: "Standing Cable Pullover, Single Arm",
    category: "Lats",
    sub_category: "Traps, Chest",
  },
  {
    id: 221,
    exercise: "Flat Dumbell Pullover",
    category: "Lats",
    sub_category: "Triceps, Chest",
  },
  {
    id: 222,
    exercise: "Decline Dumbbell Pullover",
    category: "Lats",
    sub_category: "Triceps, Chest",
  },
  {
    id: 223,
    exercise: "Plate Loaded Chest Supported Row Machine, Overhand Grip",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 224,
    exercise: "Plate Loaded Chest Supported Row Machine, Neutral Grip, Narrow",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 225,
    exercise: "Plate Loaded Chest Supported Row Machine, Overhand, Wide",
    category: "Lats",
    sub_category: "Biceps, Shoulders",
  },
  {
    id: 226,
    exercise:
      "Plate Loaded Chest Supported Row Machine, Overhand, Wide, Single Arm",
    category: "Lats",
    sub_category: "Biceps, Shoulders",
  },
  {
    id: 227,
    exercise:
      "Plate Loaded Single Arm Chest Supported Row Machine, Overhand Grip, Narrow",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 228,
    exercise:
      "Plate Loaded Single Arm Chest Supported Low Row Machine, Neutral Grip, Narrow",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 229,
    exercise: "Selector Chest Supported Row Machine, Overhand Grip",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 230,
    exercise: "Selector Chest Supported Row Machine, Neutral Grip, Narrow",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 231,
    exercise: "Selector Chest Supported Row Machine, Underhand Grip",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 232,
    exercise: "Selector Chest Supported Row Machine, Wide Grip",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 233,
    exercise: "Selector Chest Supported Row Machine, Single Arm",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 234,
    exercise: "Inverted Row, Overhand Grip",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 235,
    exercise: "Inverted Row, Underhand Grip",
    category: "Lats",
    sub_category: "Biceps, Traps",
  },
  {
    id: 236,
    exercise: "Abduction Machine",
    category: "Abductors",
    sub_category: null,
  },
  {
    id: 237,
    exercise: "Side Lying Hip Abductions",
    category: "Abductors",
    sub_category: null,
  },
  {
    id: 238,
    exercise: "Single Leg Cable Abduction",
    category: "Abductors",
    sub_category: null,
  },
  {
    id: 239,
    exercise: "Floor Back Extension",
    category: "Lower Back",
    sub_category: null,
  },
  {
    id: 240,
    exercise: "Superman",
    category: "Lower Back",
    sub_category: "Glutes",
  },
  {
    id: 241,
    exercise: "Barbell Hip Thrust",
    category: "Glutes",
    sub_category: "Hamstrings",
  },
  {
    id: 242,
    exercise: "Barbell Hip Thrust, With Bands",
    category: "Glutes",
    sub_category: "Hamstrings, Abductors",
  },
  {
    id: 243,
    exercise: "Barbell Glute Bridge",
    category: "Glutes",
    sub_category: "Hamstrings",
  },
  {
    id: 244,
    exercise: "Barbell Glute Bridge, With Bands",
    category: "Glutes",
    sub_category: "Hamstrings, Abductors",
  },
  {
    id: 245,
    exercise: "Dumbbell Hip thrust",
    category: "Glutes",
    sub_category: "Hamstrings",
  },
  {
    id: 246,
    exercise: "Dumbbell Hip Thrust, With Bands",
    category: "Glutes",
    sub_category: "Hamstrings, Abductors",
  },
  {
    id: 247,
    exercise: "Dumbbell Glute Bridge",
    category: "Glutes",
    sub_category: "Hamstrings",
  },
  {
    id: 248,
    exercise: "Dumbbell Glute Bridge, With Bands",
    category: "Glutes",
    sub_category: "Hamstrings, Abductors",
  },
  {
    id: 249,
    exercise: "Hip Thrust Machine",
    category: "Glutes",
    sub_category: "Hamstrings",
  },
  {
    id: 250,
    exercise: "Standing Glute Kickback Machine",
    category: "Glutes",
    sub_category: "Hamstrings",
  },
  {
    id: 251,
    exercise: "Standing Cable Hip Extension",
    category: "Glutes",
    sub_category: "Hamstrings",
  },
  {
    id: 252,
    exercise: "Hip Thrust With Bands",
    category: "Glutes",
    sub_category: "Hamstrings, Abductors",
  },
  {
    id: 253,
    exercise: "Single Leg Hip Thrust",
    category: "Glutes",
    sub_category: "Hamstrings",
  },
  {
    id: 254,
    exercise: "Single Leg Glute Bridge",
    category: "Glutes",
    sub_category: "Hamstrings",
  },
  {
    id: 255,
    exercise: "Bodyweight Quadruped Glute Kick Back",
    category: "Glutes",
    sub_category: "Hamstrings",
  },
  {
    id: 256,
    exercise: "Lying Kick Back",
    category: "Glutes",
    sub_category: "Hamstrings",
  },
  {
    id: 257,
    exercise: "Barbell Deadlift",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 258,
    exercise: "Trap Bar Deadlift",
    category: "Hamstrings",
    sub_category: "Glutes, Quads",
  },
  {
    id: 259,
    exercise: "Barbell Sumo Deadlift",
    category: "Hamstrings",
    sub_category: "Glutes, Adductors",
  },
  {
    id: 260,
    exercise: "Barbell Romanian Deadlift",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 261,
    exercise: "Barbell Straggered Stance Romanian Deadlift",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 262,
    exercise: "Barbell Good Morning",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 263,
    exercise: "Barbell Staggered Stance Good Morning",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 264,
    exercise: "Dumbbell Deadlift",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 265,
    exercise: "Dumbbell Romanian Deadlift",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 266,
    exercise: "Dumbbell Staggered Stance Romanian Deadlift",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 267,
    exercise: "Kettelbell Deadlift",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 268,
    exercise: "Smith Machine Good Morning",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 269,
    exercise: "Smith Machine Staggered Stance Good Morning",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 270,
    exercise: "Lying Leg Curl Machine",
    category: "Hamstrings",
    sub_category: null,
  },
  {
    id: 271,
    exercise: "Single Leg Lying Leg Curl Machine",
    category: "Hamstrings",
    sub_category: null,
  },
  {
    id: 272,
    exercise: "Seated Leg Curl Machine",
    category: "Hamstrings",
    sub_category: null,
  },
  {
    id: 273,
    exercise: "Seated Single Leg Curl Machine",
    category: "Hamstrings",
    sub_category: null,
  },
  {
    id: 274,
    exercise: "Roman Chair Back Extension",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 275,
    exercise: "Roman Chair Single Leg Back Extension",
    category: "Hamstrings",
    sub_category: "Glutes, Lower Back",
  },
  {
    id: 276,
    exercise: "Standing Calf Raise",
    category: "Calves",
    sub_category: null,
  },
  {
    id: 277,
    exercise: "Standing Calf Raise, Weighted",
    category: "Calves",
    sub_category: null,
  },
  {
    id: 278,
    exercise: "Standing Calf Raise, Single Leg",
    category: "Calves",
    sub_category: null,
  },
  {
    id: 279,
    exercise: "Smith Machine Standing Calf Raise",
    category: "Calves",
    sub_category: null,
  },
  {
    id: 280,
    exercise: "Smith Machine Standing Calf Raise, Single Leg",
    category: "Calves",
    sub_category: null,
  },
  {
    id: 281,
    exercise: "Calf Raise Machine",
    category: "Calves",
    sub_category: null,
  },
  {
    id: 282,
    exercise: "Seated Calf Raise Machine",
    category: "Calves",
    sub_category: null,
  },
  {
    id: 283,
    exercise: "Calf Raise On Leg Press",
    category: "Calves",
    sub_category: null,
  },
  {
    id: 284,
    exercise: "Calf Raise On Leg Press, Single Leg",
    category: "Calves",
    sub_category: null,
  },
  {
    id: 285,
    exercise: "Running ",
    category: "Cardio",
    sub_category: null,
  },
  {
    id: 286,
    exercise: "Bicycle Machine",
    category: "Cardio",
    sub_category: null,
  },
  {
    id: 287,
    exercise: "Elliptical Machine",
    category: "Cardio",
    sub_category: null,
  },
  {
    id: 288,
    exercise: "Step Machine",
    category: "Cardio",
    sub_category: null,
  },
  {
    id: 289,
    exercise: "Jumping Rope",
    category: "Cardio",
    sub_category: null,
  },
  {
    id: 290,
    exercise: "Burpees",
    category: "Cardio",
    sub_category: null,
  },
  {
    id: 291,
    exercise: "Mountain Climbers",
    category: "Cardio",
    sub_category: null,
  },
  {
    id: 292,
    exercise: "Battling Rope",
    category: "Cardio",
    sub_category: null,
  },
];

export default exerciseList;
