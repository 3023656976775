import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";
import { accountService } from "./account.service";
const baseUrl = `${config.apiUrl}/api`;

export const workoutPlanService = {
  workoutPlanPost,
  getWorkoutPlan,
  updateWorkoutPlan,
};

//// Workout plan posting api handel commit-------------------------------------/
async function workoutPlanPost(workoutPlan: any, userId: string) {
  const trainer = await accountService.userValue;

  return fetchWrapper
    .post(`${baseUrl}/create-workout-plan`, {
      plan: { trainer: trainer.id, ...workoutPlan },
      user: userId,
    })
    .then((response) => {
      return response;
    });
}

//// Function For handeling the api for getting the workoutplans-----------------/
async function getWorkoutPlan(trainerId: string) {
  return fetchWrapper.get(
    `${baseUrl}/users/workoutplan?trainerId=${trainerId}`
  );
}

//// Function For handeling the api for getting the workoutplans-----------------/
async function updateWorkoutPlan(body: any) {
  return fetchWrapper.put(`${baseUrl}/update-workout-plan`, body);
}


