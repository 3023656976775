import { Modal, TextField, Box, Button, Typography } from "@mui/material";
import { Formik, Form, Field, FieldArray } from "formik";
import React from "react";
import { SingleRecipe } from "../../interface/RecipeInterface";
import { recipeService } from "../../services/recipe.service";

const EditRecipeModal = ({
  editManualRecipeOpen,
  onEditManualClose,
  recipe,
  setSnackbarOpen,
  setSnackbarMessage,
  fetchSavedRecipes,
  dbType,
}: {
  editManualRecipeOpen: boolean;
  onEditManualClose: () => void;
  recipe: SingleRecipe | null;
  fetchSavedRecipes: any;
  setSnackbarOpen: (type: any) => void;
  setSnackbarMessage: (type: any) => void;
  dbType: string;
}) => {
  async function updateRecipe(values: SingleRecipe, dbType: String) {
    try {
      let db = dbType;
      const { _id, ...updatedData } = values;
      const updatedRecipe = {
        id: _id,
        updatedData: updatedData,
        dbType: db,
      };

      const response = await recipeService.updateRecipeManually(updatedRecipe);

      if (response.success) {
        onEditManualClose();
        fetchSavedRecipes();
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      }
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage("Some error has happened");
    }
  }
  if (!recipe) return null;

  return (
    <Modal open={editManualRecipeOpen} onClose={onEditManualClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Formik
          initialValues={{
            ...recipe,
            categories: Array.isArray(recipe.categories)
              ? recipe.categories
              : recipe.categories
              ? recipe.categories.split(", ")
              : [],
          }}
          onSubmit={(values) => {
            updateRecipe(values, dbType);
          }}
        >
          {({ handleChange, values, setFieldValue }) => (
            <Form>
              <Typography variant="h6" gutterBottom>
                Edit Recipe
              </Typography>
              <Field
                as={TextField}
                name="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Field
                as={TextField}
                name="description"
                label="Description"
                value={values.description}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Field
                as={TextField}
                name="duration"
                label="Duration"
                value={values.duration}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Field
                as={TextField}
                name="calories"
                label="Calories"
                value={values.calories}
                onChange={handleChange}
                fullWidth
                margin="normal"
                type="number"
              />
              <Field
                as={TextField}
                name="protein"
                label="Protein (g)"
                value={values.protein}
                onChange={handleChange}
                fullWidth
                margin="normal"
                type="number"
              />
              <Field
                as={TextField}
                name="carbs"
                label="Carbs (g)"
                value={values.carbs}
                onChange={handleChange}
                fullWidth
                margin="normal"
                type="number"
              />
              <Field
                as={TextField}
                name="fats"
                label="Fats (g)"
                value={values.fats}
                onChange={handleChange}
                fullWidth
                margin="normal"
                type="number"
              />
              <Field
                as={TextField}
                name="fiber"
                label="Fiber (g)"
                value={values.fiber}
                onChange={handleChange}
                fullWidth
                margin="normal"
                type="number"
              />
              <Field
                as={TextField}
                name="added_sugar"
                label="Added Sugar (g)"
                value={values.added_sugar}
                onChange={handleChange}
                fullWidth
                margin="normal"
                type="number"
              />
              <Field
                as={TextField}
                name="direction"
                label="Direction"
                value={values.direction}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Field
                as={TextField}
                name="categories"
                label="Categories"
                value={values.categories.join(", ")}
                onChange={(e: any) => {
                  setFieldValue(
                    "categories",
                    e.target.value.split(",").map((item: any) => item.trim())
                  );
                }}
                fullWidth
                margin="normal"
              />

              <FieldArray name="ingredients">
                {({ remove, push }) => (
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Ingredients
                    </Typography>
                    {values.ingredients.map((ingredient: any, index: any) => (
                      <Box key={index} mb={2}>
                        <Field
                          as={TextField}
                          name={`ingredients.${index}.name`}
                          label="Name"
                          value={ingredient.name}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                        <Field
                          as={TextField}
                          name={`ingredients.${index}.calories`}
                          label="Calories"
                          value={ingredient.calories}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                          type="number"
                        />
                        <Field
                          as={TextField}
                          name={`ingredients.${index}.protein`}
                          label="Protein (g)"
                          value={ingredient.protein}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                          type="number"
                        />
                        <Field
                          as={TextField}
                          name={`ingredients.${index}.carbs`}
                          label="Carbs (g)"
                          value={ingredient.carbs}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                          type="number"
                        />
                        <Field
                          as={TextField}
                          name={`ingredients.${index}.fats`}
                          label="Fats (g)"
                          value={ingredient.fats}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                          type="number"
                        />
                        <Field
                          as={TextField}
                          name={`ingredients.${index}.qty`}
                          label="Quantity"
                          value={ingredient.qty}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                          type="number"
                        />
                        <Field
                          as={TextField}
                          name={`ingredients.${index}.unit`}
                          label="Unit"
                          value={ingredient.unit}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                        />
                        <Field
                          as={TextField}
                          name={`ingredients.${index}.added_sugar`}
                          label="Added Sugar (g)"
                          value={ingredient.added_sugar}
                          onChange={handleChange}
                          fullWidth
                          margin="normal"
                          type="number"
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => remove(index)}
                        >
                          Remove Ingredient
                        </Button>
                      </Box>
                    ))}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        push({
                          name: "",
                          calories: 0,
                          protein: 0,
                          carbs: 0,
                          fats: 0,
                          qty: 0,
                          unit: "",
                          added_sugar: 0,
                        })
                      }
                    >
                      Add Ingredient
                    </Button>
                  </Box>
                )}
              </FieldArray>
              <Box mt={2}>
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
                <Button
                  onClick={onEditManualClose}
                  variant="outlined"
                  color="secondary"
                  style={{ marginLeft: "10px" }}
                >
                  Cancel
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default EditRecipeModal;
