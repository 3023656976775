/** @format */

import {
  Grid,
  Card,
  Box,
  CircularProgress,
  Container,
  Typography,
  CardContent,
} from "@mui/material";
import { memo, useRef } from "react";
import {
  getAllMeals,
  getTargetsForWeek,
} from "../services/userdetails.service";

import React, { useEffect, useState } from "react";

import {
  calculateTotalCalorieAndProteinForWeekIndividually,
  getWeekFromDate,
  filterLastSevenDaysForSteps,
  filterLastSevenDaysWithMaxWaterValue,
  weekArrayDataOfWeights,
  getWeeklySleepData,
} from "../services/userdetails.service";

import BasicInfoModal from "../modules/BasicInfoModal";

import CurrentTargets from "../Components/UserDetails/CurrentTarget";
import UserDeviceInfo from "../Components/UserDetails/UserDeviceInfo";
import UserAppActivity from "../Components/UserDetails/UserAppActivity";
import UserBasicInfo from "../Components/UserDetails/UserBasicInfo";
import { userDetailsUtils } from "../utils/UserDetails";
import UserGraph from "../Components/UserDetails/UserGraph";

import { DayTargets } from "../interface/Targets";

import { AppState } from "../interface/User";

interface UserDetails {
  selectedUser: any;
  appState: AppState;
  setAppState: (data: AppState) => void;
  userDataLoading: boolean;
}

const UserDetails = ({
  selectedUser,
  appState,
  setAppState,
  userDataLoading,
}: UserDetails) => {
  const [stepsWeekArray, setStepsWeekArray] = useState<any[]>([]);
  const [weightsWeekArray, setWeightsWeekArray] = useState<any[]>([]);
  const [waterWeekArray, setWaterWeekArray] = useState<any[]>([]);
  const [proteinTotals, setProteinTotals] = useState<any[]>([]);
  const [calorieTotals, setCalorieTotals] = useState<any[]>([]);
  const [sleepTotals, setSleepTotals] = useState<any[]>([]);

  const [open, setOpen] = useState(false);
  const [weekTargets, setWeekTargets] = useState<DayTargets[]>([]);

  const [dataType, setDataType] = React.useState("steps");
  const [mainDataType, setMainDataType] = React.useState("");

  const [dateRange, setDateRange] = useState({
    starting: "",
    ending: "",
  });

  const [currentDate, setCurrentDate] = React.useState(new Date());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function resetState() {
    setDataType(mainDataType !== "" ? mainDataType : "steps");
    setCurrentDate(new Date());
    setDateRange({
      starting: "",
      ending: "",
    });

    setCalorieTotals([]);
    setWeightsWeekArray([]);
    setSleepTotals([]);
    setProteinTotals([]);
    setStepsWeekArray([]);
    setWaterWeekArray([]);
  }

  useEffect(() => {
    resetState();
  }, [selectedUser]);

  // Function to reset state on error
  function resetDataOnFetchError() {
    setCalorieTotals([]);
    setWeightsWeekArray([]);

    setSleepTotals([]);
    setProteinTotals([]);
    setStepsWeekArray([]);
    setWaterWeekArray([]);
  }

  ///Function For getting all the user Data including protein,calorie,steps and water-----------------/
  async function getAllUserData(
    mealsData: any,
    physicalData: any,
    currentDate: any,
    selectedUser: any,
    targetLevels: any[]
  ) {
    try {
      const weekArray = await getWeekFromDate(currentDate);

      const dateRange = await userDetailsUtils.getStartAndEndDate(weekArray);

      setDateRange(dateRange);
      const stepsWeekArray = await filterLastSevenDaysForSteps(
        physicalData?.steps,

        weekArray
      );

      const targetValuesForTheWeek = getTargetsForWeek(
        dateRange.starting,
        dateRange.ending,
        targetLevels,
        selectedUser
      );

      setWeekTargets(targetValuesForTheWeek);

      const waterWeekArray = await filterLastSevenDaysWithMaxWaterValue(
        physicalData?.water,

        weekArray
      );

      const weightsWeekArray = await weekArrayDataOfWeights(
        physicalData?.weights,
        weekArray
      );

      const sleepWeekArray = await getWeeklySleepData(
        physicalData?.sleep,
        weekArray,
        selectedUser
      );

      const proteinTotals =
        await calculateTotalCalorieAndProteinForWeekIndividually(
          mealsData,
          "protein",
          weekArray
        );

      const calorieTotals =
        await calculateTotalCalorieAndProteinForWeekIndividually(
          mealsData,
          "calorie",
          weekArray
        );

      setCalorieTotals(calorieTotals);
      setProteinTotals(proteinTotals);
      setSleepTotals(sleepWeekArray);
      setWeightsWeekArray(weightsWeekArray);
      setStepsWeekArray(stepsWeekArray);
      setWaterWeekArray(waterWeekArray);

      // Calculate maxYValue based on the current dataType
      const currentMaxValue = Math.max(
        ...stepsWeekArray.map((item) => item.steps)
      );
      const calculatedMaxYValue = Math.max(
        currentMaxValue
        //  targetLevels[dataType] * 1.2
      );
    } catch (error) {
      resetDataOnFetchError();
    }
  }

  // /// Function For making the api call of all meals and physcial activity---------------------------/
  // async function dataApiCall(userId: string, selectedUser: any) {
  //   try {
  //     // Execute all service calls in parallel using Promise.all
  //     // const [
  //     //   meals,
  //     //   weightResponse,
  //     //   physicalData,
  //     //   targetsResult,
  //     //   latestActivityResult,
  //     // ] = await Promise.all([
  //     //   getAllMeals(selectedUser?._id),
  //     //   userService.getCurrentWeight(selectedUser?._id),
  //     //   accountService.getUserPhysicalActivity(selectedUser?._id),
  //     //   accountService.getTargets(selectedUser?._id),
  //     //   accountService.latestActivity(selectedUser?._id),
  //     // ]);
  //     const userDetailsData: UserSqliteDetails =
  //       await accountService.getUserDetailsData(selectedUser?._id);

  //     // Process BMR calculation and set relevant data
  //     const generateBmr = await userDetailsUtils.calculateBMR(
  //       userDetailsData.currentWeight.currentWeight,
  //       selectedUser.height,
  //       selectedUser.yob,
  //       selectedUser.gender
  //     );

  //     // Update state with results from the parallel requests
  //     setBmr(generateBmr);
  //     setCurrentWeight(userDetailsData.currentWeight.currentWeight);

  //     if (userDetailsData.currentWeight.currentWeight !== "Not Available") {
  //       setWeightUnit(selectedUser.weightUnit);
  //     } else {
  //       setWeightUnit("");
  //     }

  //     setMeals(userDetailsData.mealData);
  //     setPhysicalData(userDetailsData.physicalData);

  //     let date = new Date();
  //     getAllUserData(
  //       userDetailsData.mealData,
  //       userDetailsData.physicalData,
  //       date,
  //       selectedUser,
  //       userDetailsData.targetData
  //     );

  //     // Parse latest activity result and set state
  //     const fetchedData = JSON.parse(userDetailsData.latestActivity);
  //     const activityData = JSON.parse(fetchedData.data);

  //     setCurrentDate(new Date());
  //     setMealData(activityData[0]);
  //     setLatestActivityName(userDetailsData.latestActivity.tableName);

  //     // Set target-related data
  //     setUserTargets(
  //       userDetailsData.targetData[userDetailsData.targetData.length - 1]
  //     );

  //     setTargetLevels([...userDetailsData.targetData]);

  //     setLoading(false);
  //   } catch (error: any) {
  //     console.log(error.message);

  //     setCurrentDate(new Date());
  //     resetDataOnFetchError();
  //   }
  // }

  // React.useEffect(() => {
  //   // Clears the existing timeout if the effect is called again within 1 second
  //   if (requestTimeoutRef.current) {
  //     clearTimeout(requestTimeoutRef.current);
  //   }

  //   requestTimeoutRef.current = setTimeout(() => {
  //     if (selectedUser._id !== "") {
  //       setLoading(true);
  //      // dataApiCall(selectedUser._id, selectedUser);
  //     }
  //   }, 1000); // Sets a 1-second delay for the operation

  //   // Cleanup function to clear the timeout when the component unmounts or before the effect runs again
  //   return () => {
  //     if (requestTimeoutRef.current) {
  //       clearTimeout(requestTimeoutRef.current);
  //     }
  //   };
  // }, [selectedUser]);

  useEffect(() => {
    let date = new Date();
    getAllUserData(
      appState.meals,
      appState.physicalData,
      date,
      selectedUser,
      appState.totalTargets
    );
  }, [appState]);
  /// Function For handeling the weeks when pressed the arrow----------------/
  const handlePrevWeek = () => {
    const prevWeek = new Date(currentDate);

    prevWeek.setDate(currentDate.getDate() - 7);

    setCurrentDate(prevWeek);

    getAllUserData(
      appState.meals,
      appState.physicalData,
      prevWeek,
      selectedUser,
      appState.totalTargets
    );
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(currentDate);
    nextWeek.setDate(currentDate.getDate() + 7);
    setCurrentDate(nextWeek);
    getAllUserData(
      appState.meals,
      appState.physicalData,
      nextWeek,
      selectedUser,
      appState.totalTargets
    );
  };

  const handleTypeChange = (
    event: any,
    newType: React.SetStateAction<string> | null
  ) => {
    if (newType !== null) {
      setMainDataType(newType);

      // Avoid unselecting all buttons
      setDataType(newType);
    }
  };

  return (
    <Box sx={{ height: "87vh", overflowY: "auto" }}>
      <Grid container>
        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card
            sx={{
              height: "290px",
              overflowY: "auto",
            }}
          >
            {userDataLoading ? (
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress sx={{ marginTop: "30px" }} />
              </Container>
            ) : (
              <CardContent sx={{ padding: "16px 16px 32px 16px" }}>
                <Typography
                  sx={{ fontSize: 16, fontWeight: "bold" }}
                  color="#000"
                  gutterBottom
                >
                  Basic Info
                </Typography>

                <UserBasicInfo
                  selectedUser={selectedUser}
                  handleOpen={handleOpen}
                />
              </CardContent>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card
            sx={{
              height: "290px",
              "@media (max-width: 1280px)": {
                height: "410px",
              },
              "@media (max-width: 500px)": {
                height: "290px",
              },
            }}
          >
            {userDataLoading ? (
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress sx={{ marginTop: "30px" }} />
              </Container>
            ) : (
              <CurrentTargets userTargets={appState.userTargets} />
            )}
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{ padding: 1.5 }}>
          <Card sx={{ height: 460 }}>
            {userDataLoading ? (
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress sx={{ marginTop: "30px" }} />
              </Container>
            ) : (
              <UserGraph
                dataType={dataType}
                dateRange={dateRange}
                currentDate={currentDate}
                handlePrevWeek={handlePrevWeek}
                handleNextWeek={handleNextWeek}
                handleTypeChange={handleTypeChange}
                userTargets={appState.userTargets}
                selectedUser={selectedUser}
                targetLevels={appState.totalTargets}
                stepsWeekArray={stepsWeekArray}
                weightsWeekArray={weightsWeekArray}
                proteinTotals={proteinTotals}
                calorieTotals={calorieTotals}
                sleepTotals={sleepTotals}
                waterWeekArray={waterWeekArray}
                weekTargets={weekTargets}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card sx={{ overflowY: "auto", height: 230 }}>
            {userDataLoading ? (
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress sx={{ marginTop: "30px" }} />
              </Container>
            ) : (
              <UserAppActivity appState={appState} />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ padding: 1.5 }}>
          <Card sx={{ height: 230 }}>
            {userDataLoading ? (
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress sx={{ marginTop: "30px" }} />
              </Container>
            ) : (
              <UserDeviceInfo selectedUser={selectedUser} />
            )}
          </Card>
        </Grid>
      </Grid>
      {open ? (
        <BasicInfoModal
          open={open}
          onClose={handleClose}
          selectedUser={selectedUser}
        />
      ) : null}
    </Box>
  );
};

export default memo(UserDetails);
