import { Grid } from "@mui/material";
import MultiDescription from "./MultiDescription";
import MultiPromptSection from "./MultiPromptSection";
import { useState } from "react";
import {
  MultipleRecipeInterface,
  PromptsInterface,
} from "../../../interface/RecipeInterface";

//// This is the parent componet for the when navbar button is pressed as single --------------------------------------------------/
function MultiSection() {
  const [multiRecipe, setMultiRecipe] = useState<
    MultipleRecipeInterface[] | null
  >(null);
  const [multiRecipeLoading, setMultiRecipeLoading] = useState(false);
  const [prompts, setPrompts] = useState<PromptsInterface>({
    gpt: "",
    dalle: "",
    recipeDetails: "",
  });

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MultiPromptSection
          multiRecipe={multiRecipe}
          setMultiRecipe={setMultiRecipe}
          setMultiRecipeLoading={setMultiRecipeLoading}
          multiRecipeLoading={multiRecipeLoading}
          prompts={prompts}
          setPrompts={setPrompts}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          maxHeight: "88vh",

          overflowY: multiRecipeLoading ? "auto" : "scroll",
        }}
      >
        <MultiDescription
          multiRecipe={multiRecipe}
          setMultiRecipe={setMultiRecipe}
          setMultiRecipeLoading={setMultiRecipeLoading}
          multiRecipeLoading={multiRecipeLoading}
          prompts={prompts}
          setPrompts={setPrompts}
        />
      </Grid>
    </>
  );
}

export default MultiSection;
