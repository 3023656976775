/** @format */

import { createBrowserRouter } from "react-router-dom";
import Main from "../pages/Main";

import Login from "../pages/Login";
import PrivateRoute from "./PrivateRoute";
import Summary from "../pages/Summary";
import Notification from "../pages/Notification";
import Plan from "../pages/Plan";
import ProfilePage from "../pages/Profile";
import AppSettings from "../pages/AppSettings";
import NutritionLogPage from "../pages/NutritionLogPage";
import EmailSend from "../pages/EmailSend";
import Recipes from "../pages/Recipes";
import ApiMonitor from "../pages/ApiMonitor";
import PrivateHomeScreenRoute from "./PrivateHomeScreenRoute";
import DiscountCode from "../pages/DiscountCode";
import Campaign from "../pages/Campaign";
import CampaignDetails from "../pages/CampaignDetails";

export const routes = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/*",
    element: (
      <PrivateRoute>
        <Main />
      </PrivateRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <PrivateHomeScreenRoute>
        <ProfilePage />
      </PrivateHomeScreenRoute>
    ),
  },
  {
    path: "/summary",
    element: (
      <PrivateHomeScreenRoute>
        <Summary />
      </PrivateHomeScreenRoute>
    ),
  },
  {
    path: "/notification",
    element: (
      <PrivateHomeScreenRoute>
        <Notification />
      </PrivateHomeScreenRoute>
    ),
  },
  {
    path: "/plans",
    element: (
      <PrivateHomeScreenRoute>
        <Plan />
      </PrivateHomeScreenRoute>
    ),
  },
  {
    path: "/appsettings",
    element: (
      <PrivateHomeScreenRoute>
        <AppSettings />
      </PrivateHomeScreenRoute>
    ),
  },
  {
    path: "/nutritionlogs",
    element: (
      <PrivateHomeScreenRoute>
        <NutritionLogPage />
      </PrivateHomeScreenRoute>
    ),
  },
  {
    path: "/emailsend",
    element: (
      <PrivateHomeScreenRoute>
        <EmailSend />
      </PrivateHomeScreenRoute>
    ),
  },
  {
    path: "/recipes",
    element: (
      <PrivateHomeScreenRoute>
        <Recipes />
      </PrivateHomeScreenRoute>
    ),
  },
  {
    path: "/campaign",
    element: (
      <PrivateHomeScreenRoute>
        <Campaign />
      </PrivateHomeScreenRoute>
    ),
  },
  {
    path: "/apimonitor",
    element: (
      <PrivateHomeScreenRoute>
        <ApiMonitor />
      </PrivateHomeScreenRoute>
    ),
  },
  {
    path: "/discountcode",
    element: (
      <PrivateHomeScreenRoute>
        <DiscountCode />
      </PrivateHomeScreenRoute>
    ),
  },
  {
    path: "/campaigndetails",
    element: (
      <PrivateHomeScreenRoute>
        <CampaignDetails />
      </PrivateHomeScreenRoute>
    ),
  },
]);
