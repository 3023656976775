import React, { useEffect, useState } from "react";
import { Autocomplete, Container, Modal, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  FormHelperText,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { workoutPlanService } from "../services/workoutplan.service";
import exerciseList from "../helpers/exercises";
import { validateWorkoutPlan } from "../utils/Validation";
import { accountService } from "../services";
import { formatDateToYYYYMMDD } from "../utils/TimeConverter";
import {
  WorkoutPlan,
  ValidationErrors,
  WorkoutPlanDetails,
  Workout,
} from "../interface/Workout";

// Define the initial state for workoutPlan
const initialWorkoutPlanState = {
  name: "",
  workouts: [],
  startDate: "", // Assuming dates are stored as strings
  endDate: "",
};
const WorkoutPlanModal = ({
  isModalOpen,
  handleClose,
  userId,
}: WorkoutPlanDetails) => {
  const [workoutPlan, setWorkoutPlan] = useState<WorkoutPlan>(
    initialWorkoutPlanState
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [showPlans, setShowPlans] = useState(false);
  const [plans, setPlans] = useState<any>([]);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [workoutError, setWorkoutError] = useState("");
  const [exerciseError, setExerciseError] = useState("");
  const [firstSelectedDate, setFirstSelectedDate] = useState<any>({});
  // const [isPlanLoading, setIsPlanLoading] = useState(false);
  const [planNameInput, setPlanNameInput] = useState("");

  //// Function For fetching the workoout plans--------------------------------------------/
  async function fetchWorkoutPlan(userId: string) {
    // setIsPlanLoading(true);

    try {
      const loggedUserDetails = await accountService.userValue;

      if (loggedUserDetails) {
        const planDetailsResponse = await workoutPlanService.getWorkoutPlan(
          loggedUserDetails.id
        );
        if (planDetailsResponse.data === true) {
          setPlans(planDetailsResponse.workoutData);
        } else {
          setPlans([]);
        }
      }
    } catch (error) {
      setPlans([]);
    }
    //setIsPlanLoading(false);
  }
  useEffect(() => {
    fetchWorkoutPlan(userId);
  }, [isModalOpen]);
  // const togglePlansList = () => {
  //   if (!showPlans) {
  //     fetchWorkoutPlan(userId);
  //   }
  //   setShowPlans(!showPlans);
  // };
  // Similar updates for other handlers...

  //// Function for adding new workout---------------------------------/
  const addWorkout = () => {
    setWorkoutError("");
    const newWorkout: Workout = {
      name: "",
      day: "",
      exercises: [],
    };
    setWorkoutPlan((prevState) => ({
      ...prevState,
      workouts: [...prevState.workouts, newWorkout],
    }));
  };

  /// Funciton for adding each exercise-----------------------------------------/
  const addExercise = (workoutIndex: any) => {
    setExerciseError("");
    const newExercise = {
      id: "",
      sets: "",
      repRange: "",
    };
    const updatedWorkouts = [...workoutPlan.workouts];
    updatedWorkouts[workoutIndex].exercises.push(newExercise);
    setWorkoutPlan({ ...workoutPlan, workouts: updatedWorkouts });
  };

  /// Function for removing the workout -------------------------------------/
  const removeWorkout = (workoutIndex: any) => {
    const updatedWorkouts = workoutPlan.workouts.filter(
      (_, index) => index !== workoutIndex
    );
    setWorkoutPlan({ ...workoutPlan, workouts: updatedWorkouts });
  };

  //// Function For removing exercise -----------------------------------------/
  const removeExercise = (workoutIndex: any, exerciseIndex: any) => {
    const updatedWorkouts = [...workoutPlan.workouts];
    updatedWorkouts[workoutIndex].exercises = updatedWorkouts[
      workoutIndex
    ].exercises.filter((_, index) => index !== exerciseIndex);
    setWorkoutPlan({ ...workoutPlan, workouts: updatedWorkouts });
  };

  //// Function for submitting the form -------------------------------------------------/
  async function submitFunction(firstSelectedDate: any) {
    const errors = validateWorkoutPlan(
      workoutPlan,
      planNameInput,
      firstSelectedDate
    );
    /// For workout , exercise we need to show two different error that will appear on screen
    // not over any text/input fld-----------/
    if (errors.workouts) {
      setWorkoutError(errors.workouts);
    } else if (errors.exercise) {
      setExerciseError(errors.exercise);
    } else {
      setWorkoutError("");
      setExerciseError("");
    }
    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      // No validation errors, proceed with submission
      let response = await workoutPlanService.workoutPlanPost(
        workoutPlan,
        userId
      );

      if (response.success) {
        // Handle successful response
        setSnackbarOpen(true);
        setSnackbarMessage("Workout plan updated successfully");
        setWorkoutPlan(initialWorkoutPlanState);
        handleClose();
      } else {
        // Handle unsuccessful response
        setSnackbarOpen(true);
        setSnackbarMessage(response.message || "Failed to update workout plan");
        handleClose();
      }
      setFirstSelectedDate({
        startDateTime: "",
        endDateTime: "",
      });
      setPlanNameInput("");
    }
  }

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        aria-labelledby="workout-plan-modal-title"
        aria-describedby="workout-plan-modal-description"
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflowY: "auto",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: 16, top: 16 }}
            onClick={() => {
              setWorkoutPlan(initialWorkoutPlanState);
              setPlanNameInput("");
              setValidationErrors({}); // Clear all validation errors
              setWorkoutError("");
              setExerciseError("");
              setFirstSelectedDate({
                endDateTime: "",
                startDateTime: "",
              });
              handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Autocomplete
                freeSolo
                options={plans}
                getOptionLabel={(option: any) => option.name || ""}
                fullWidth
                inputValue={planNameInput}
                onInputChange={(event, newInputValue) => {
                  setPlanNameInput(newInputValue);
                  setWorkoutPlan((prevPlan) => ({
                    ...prevPlan,
                    name: newInputValue,
                  }));

                  // Clear plan name error when user types a new value
                  const newErrors = { ...validationErrors };
                  delete newErrors.planName;
                  setValidationErrors(newErrors);
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setWorkoutPlan((prevPlan) => ({
                      ...prevPlan,
                      name: newValue,
                    }));
                  } else if (newValue && newValue.name) {
                    const updatedWorkouts = newValue.workouts.map(
                      (workout: any) => ({
                        ...workout,
                        exercises: workout.exercises.map((exercise: any) => {
                          const matchedExercise = exerciseList.find(
                            (exList) => exList.id === exercise.id
                          );
                          return {
                            ...exercise,
                            name: matchedExercise
                              ? matchedExercise.exercise
                              : "",
                          };
                        }),
                      })
                    );

                    setWorkoutPlan({
                      name: newValue.name,
                      workouts: updatedWorkouts,
                      startDate: formatDateToYYYYMMDD(newValue.startDate),
                      endDate: formatDateToYYYYMMDD(newValue.endDate),
                    });
                  }

                  // Clear plan name error when user selects a value from the dropdown
                  const newErrors = { ...validationErrors };
                  delete newErrors.planName;
                  setValidationErrors(newErrors);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Plan Name"
                    margin="normal"
                    error={!!validationErrors.planName}
                    helperText={validationErrors.planName}
                  />
                )}
              />

              {/* <IconButton onClick={togglePlansList}>
                {showPlans ? <CloseIcon /> : <ArrowDropDownIcon />}
              </IconButton>
              <PlanSuggestion
                showPlans={showPlans}
                setShowPlans={setShowPlans}
                plans={plans}
                isPlanLoading={isPlanLoading}
                setWorkoutPlan={setWorkoutPlan}
              /> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography>Start-Date</Typography>
                <TextField
                  style={{ flex: 1, marginRight: 5, width: "100%" }}
                  type="date"
                  value={workoutPlan.startDate}
                  onChange={(e) => {
                    let currentDate = Date.now();
                    setFirstSelectedDate((prevValue: any) => ({
                      ...prevValue,
                      startDateTime: currentDate,
                    }));
                    // Remove any existing validation errors for start date
                    setValidationErrors((prevErrors: any) => ({
                      ...prevErrors,
                      startDate: undefined,
                    }));
                    setWorkoutPlan((prevPlan) => ({
                      ...prevPlan,
                      startDate: e.target.value,
                    }));
                  }}
                  margin="normal"
                  error={!!validationErrors.startDate}
                  helperText={validationErrors.startDate}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography>End-Date</Typography>
                <TextField
                  style={{ flex: 1, width: "100%" }}
                  type="date"
                  value={workoutPlan.endDate}
                  onChange={(e) => {
                    let currentDate = Date.now();
                    setFirstSelectedDate((prevValue: any) => ({
                      ...prevValue,
                      endDateTime: currentDate,
                    }));
                    setValidationErrors((prevErrors: any) => ({
                      ...prevErrors,
                      endDate: undefined,
                    }));
                    setWorkoutPlan((prevPlan) => ({
                      ...prevPlan,
                      endDate: e.target.value,
                    }));
                  }}
                  margin="normal"
                  error={!!validationErrors.endDate}
                  helperText={validationErrors.endDate}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {workoutError === "" ? null : (
              <Typography style={{ color: "red", fontSize: 15 }}>
                {workoutError}
              </Typography>
            )}
          </div>
          {workoutPlan.workouts.map((workout, workoutIndex) => (
            <Card key={workoutIndex} sx={{ margin: 2 }}>
              <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  style={{ display: "flex", width: "100%", marginRight: 5 }}
                  label="Workout Name"
                  value={workout.name}
                  error={!!validationErrors[`workoutName_${workoutIndex}`]}
                  helperText={validationErrors[`workoutName_${workoutIndex}`]}
                  onChange={(e) => {
                    setWorkoutPlan((prevPlan) => ({
                      ...prevPlan,
                      workouts: prevPlan.workouts.map((w, idx) =>
                        idx === workoutIndex
                          ? { ...w, name: e.target.value }
                          : w
                      ),
                    }));
                    // Optionally clear the error for this field
                    setValidationErrors({
                      ...validationErrors,
                      [`workoutName_${workoutIndex}`]: undefined,
                    });
                  }}
                  margin="normal"
                />

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!validationErrors[`workoutDay_${workoutIndex}`]}
                >
                  <InputLabel>Day</InputLabel>
                  <Select
                    value={workout.day}
                    label="Day"
                    onChange={(e) => {
                      setWorkoutPlan((prevPlan) => ({
                        ...prevPlan,
                        workouts: prevPlan.workouts.map((w, idx) =>
                          idx === workoutIndex
                            ? { ...w, day: e.target.value }
                            : w
                        ),
                      }));
                      // Optionally clear the error for this field
                      setValidationErrors({
                        ...validationErrors,
                        [`workoutDay_${workoutIndex}`]: undefined,
                      });
                    }}
                  >
                    <MenuItem value="Monday">Monday</MenuItem>
                    <MenuItem value="Tuesday">Tuesday</MenuItem>
                    <MenuItem value="Wednesday">Wednesday</MenuItem>
                    <MenuItem value="Thursday">Thursday</MenuItem>
                    <MenuItem value="Friday">Friday</MenuItem>
                    <MenuItem value="Saturday">Saturday</MenuItem>
                    <MenuItem value="Sunday">Sunday</MenuItem>
                  </Select>
                  <FormHelperText>
                    {validationErrors[`workoutDay_${workoutIndex}`]}
                  </FormHelperText>
                </FormControl>
              </CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {exerciseError === "" ? null : (
                  <Typography style={{ color: "red", fontSize: 15 }}>
                    {exerciseError}
                  </Typography>
                )}
              </div>
              <CardContent sx={{ display: "flex", overflowX: "auto" }}>
                {workout.exercises.map((exercise, exerciseIndex) => (
                  <Card key={exerciseIndex} sx={{ minWidth: 250, margin: 1 }}>
                    <CardContent>
                      <Autocomplete
                        options={exerciseList}
                        getOptionLabel={(option) => option.exercise}
                        fullWidth
                        value={
                          exercise.name
                            ? exerciseList.find(
                                (ex) => ex.exercise === exercise.name
                              ) || null
                            : exerciseList.find(
                                (ex) => ex.id.toString() === exercise.id
                              ) || null
                        }
                        onChange={(event, newValue) => {
                          setWorkoutPlan((prevPlan) => ({
                            ...prevPlan,
                            workouts: prevPlan.workouts.map((w, workoutIdx) =>
                              workoutIdx === workoutIndex
                                ? {
                                    ...w,
                                    exercises: w.exercises.map(
                                      (ex, exerciseIdx) =>
                                        exerciseIdx === exerciseIndex
                                          ? {
                                              ...ex,
                                              id: newValue?.id.toString() || "",
                                            }
                                          : ex
                                    ),
                                  }
                                : w
                            ),
                          }));

                          // Clear the exercise ID error when a new exercise is selected
                          const newErrors = { ...validationErrors };
                          delete newErrors[
                            `exerciseId_${workoutIndex}_${exerciseIndex}`
                          ];
                          setValidationErrors(newErrors);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Exercise Name"
                            margin="normal"
                            error={
                              !!validationErrors[
                                `exerciseId_${workoutIndex}_${exerciseIndex}`
                              ]
                            }
                            helperText={
                              validationErrors[
                                `exerciseId_${workoutIndex}_${exerciseIndex}`
                              ]
                            }
                          />
                        )}
                      />

                      <TextField
                        fullWidth
                        label="Sets"
                        value={exercise.sets}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );

                          setWorkoutPlan((prevPlan) => ({
                            ...prevPlan,
                            workouts: prevPlan.workouts.map((w, workoutIdx) =>
                              workoutIdx === workoutIndex
                                ? {
                                    ...w,
                                    exercises: w.exercises.map(
                                      (ex, exerciseIdx) =>
                                        exerciseIdx === exerciseIndex
                                          ? { ...ex, sets: numericValue }
                                          : ex
                                    ),
                                  }
                                : w
                            ),
                          }));
                        }}
                        // Add value and onChange handler
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Rep Range"
                        value={exercise.repRange || ""}
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/[^0-9-]/g, "") // Allow only numbers and dashes
                            .replace(/-{2,}/g, "-"); // Replace multiple dashes with a single das

                          setWorkoutPlan((prevPlan) => ({
                            ...prevPlan,
                            workouts: prevPlan.workouts.map((w, workoutIdx) =>
                              workoutIdx === workoutIndex
                                ? {
                                    ...w,
                                    exercises: w.exercises.map(
                                      (ex, exerciseIdx) =>
                                        exerciseIdx === exerciseIndex
                                          ? { ...ex, repRange: newValue }
                                          : ex
                                    ),
                                  }
                                : w
                            ),
                          }));
                        }}
                      />
                    </CardContent>
                    <CardActions>
                      <IconButton
                        onClick={() =>
                          removeExercise(workoutIndex, exerciseIndex)
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                ))}

                <Card
                  sx={{
                    minWidth: 250,
                    margin: 1,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardContent>
                    <Button onClick={() => addExercise(workoutIndex)}>
                      + Add Exercise
                    </Button>
                  </CardContent>
                </Card>
              </CardContent>
              <CardActions>
                <Button onClick={() => removeWorkout(workoutIndex)}>
                  Remove Workout
                </Button>
              </CardActions>
            </Card>
          ))}

          <Button onClick={addWorkout}>+ Add Workout</Button>
          <Button onClick={() => submitFunction(firstSelectedDate)}>
            Save
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2", // Use primary color from the theme
            color: "white", // Set text color to white
          },
        }}
      />
    </>
  );
};

export default WorkoutPlanModal;
