/** @format */

import { workoutUtilsFunctions } from "../utils/Workout";
import {
  ExerciseTableInterface,
  SetInterface,
} from "../interface/OwnCreatedWorkoutInterface";
import { Divider, Typography } from "@mui/material";
import DumbbellIcon from "@mui/icons-material/FitnessCenter";
import TimeIcon from "@mui/icons-material/AccessTime";
import WeightIcon from "@mui/icons-material/BarChart";
import SetIcon from "@mui/icons-material/List";

export default function ExerciseTable({
  workoutDetails,
  currentDate,
  index,
}: ExerciseTableInterface) {
  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <div style={{ width: "100%", maxWidth: "600px" }}>
        {workoutDetails?.exercises.map((exercise: any, exerciseIndex: any) => (
          <div
            key={exerciseIndex}
            style={{
              marginBottom: "20px",
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {/* Workout name and exercise */}
            <div
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
                fontSize: 14,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Typography>Plan Name:</Typography>{" "}
                <Typography style={{ marginLeft: 2 }}>
                  {workoutDetails.workout_name}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: 5,
                }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  Exercise:
                </Typography>{" "}
                <Typography style={{ marginLeft: 2, fontWeight: "bold" }}>
                  {exercise.exercise}
                </Typography>
              </div>
            </div>
            <Divider style={{ width: "98%" }} />
            {/* Exercise sets */}
            {exercise.sets
              .filter((set: SetInterface) =>
                workoutUtilsFunctions.isSameDay(
                  new Date(set.dateTime),
                  currentDate
                )
              ) // Filter sets for the current date
              .map((set: SetInterface, setIndex: any) => (
                <div
                  key={`${exerciseIndex}-${setIndex}`}
                  style={{
                    marginBottom: "10px",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                  }}
                >
                  {/* Set number */}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <SetIcon sx={{ fontSize: 18 }} />
                      <Typography sx={{ fontWeight: "bold", marginLeft: 1 }}>
                        Set:
                      </Typography>
                    </div>
                    <Typography>{setIndex + 1}</Typography>
                  </div>

                  {/* Reps or Duration */}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <DumbbellIcon sx={{ fontSize: 18 }} />
                      <Typography sx={{ fontWeight: "bold", marginLeft: 1 }}>
                        {set.set_duration ? "Duration:" : "Reps:"}
                      </Typography>
                    </div>
                    <Typography>
                      {set.set_duration
                        ? set.set_duration < 60
                          ? `${set.set_duration} sec`
                          : `${Math.floor(set.set_duration / 60)} min`
                        : set.reps}
                    </Typography>
                  </div>

                  {/* Weight */}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <WeightIcon sx={{ fontSize: 18 }} />
                      <Typography sx={{ fontWeight: "bold", marginLeft: 1 }}>
                        Weight:
                      </Typography>
                    </div>
                    <Typography>
                      {set.isBodyWeight === 1 || set.weight === null
                        ? "Bodyweight"
                        : `${set.weight ?? ""} ${set.weightUnit}`}
                    </Typography>
                  </div>

                  {/* Date */}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <TimeIcon sx={{ fontSize: 18 }} />
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          marginLeft: 1,
                        }}
                      >
                        Date:
                      </Typography>
                    </div>
                    <Typography>
                      {set.dateTime
                        ? new Date(set.dateTime).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })
                        : ""}
                    </Typography>
                  </div>
                </div>
              ))}
            {/* Display "No sets available" if there are no sets */}
            {exercise.sets.length === 0 && (
              <div style={{ marginBottom: "10px" }}>
                <strong>No sets available</strong>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
