/** @format */
import { memo } from "react";
import { CircularProgress, Container, Typography } from "@mui/material";

import { useEffect, useState } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ActivitiesCard from "../../modules/ActivitiesCard";

import {
  ActivitiesByDateInterface,
  ActivitiesInterface,
} from "../../interface/ActivitiesInterface";
import { activitiesUtilityFunctions } from "../../utils/Activities";
function ActivitiesByDate({
  selectedUser,
  activityData,

  currentDate,
  setCurrentDate,
}: ActivitiesByDateInterface) {
  const [currentDayActivityData, setCurrentDayActivityData] = useState<
    ActivitiesInterface[]
  >([]);

  //// Function For getting The activity data----------------/

  async function getUserActivities(
    activityData: ActivitiesInterface[],
    date: Date
  ) {
    try {
      //// Getting The actitivy of that Particular day----------/

      const currentDayActivityResponse =
        await activitiesUtilityFunctions.getCurrentDayActivities(
          activityData,
          date
        );
      setCurrentDayActivityData(currentDayActivityResponse);
    } catch (error) {
      setCurrentDayActivityData([]);
    }
  }

  useEffect(() => {
    getUserActivities(activityData, currentDate);
  }, [selectedUser, activityData]);
  /// Function for increasing the date with arrow--------------------------/
  const increaseDate = async () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);
    setCurrentDate(newDate);
    const currentDayActivityResponse =
      await activitiesUtilityFunctions.getCurrentDayActivities(
        activityData,
        newDate
      );
    setCurrentDayActivityData(currentDayActivityResponse);
  };

  /// Function for decreasing the date with arrow----------------------------/
  const decreaseDate = async () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 1);
    setCurrentDate(newDate);
    const currentDayActivityResponse =
      await activitiesUtilityFunctions.getCurrentDayActivities(
        activityData,
        newDate
      );

    setCurrentDayActivityData(currentDayActivityResponse);
  };
  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 2,
        }}
      >
        <ArrowBackIosIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            decreaseDate();
          }}
        />
        <Typography style={{ textAlign: "center", fontWeight: "800" }}>
          {currentDate.toDateString()}
        </Typography>
        <ArrowForwardIosIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (currentDate.getDate() === new Date().getDate()) {
              return;
            } else {
              increaseDate();
            }
          }}
        />
      </Container>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Container>
      ) : (
      <Container style={{ height: "65vh", overflowY: "scroll" }}>
        {currentDayActivityData.length > 0 ? (
          <ActivitiesCard
            activities={currentDayActivityData}
            currentDate={currentDate}
          />
        ) : (
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography style={{ marginTop: 50 }}>No data available</Typography>
          </Container>
        )}
      </Container>
    </>
  );
}

export default ActivitiesByDate;
