import { BehaviorSubject } from "rxjs";

import { fetchWrapper } from "../helpers";
import axios from "axios";
import Cookies from "js-cookie";
import { config } from "../shared/constants/config";
import { Mixpanel } from "../helpers/mixpanel-helper";
import { da } from "date-fns/locale";
import { log } from "node:console";
import { UserSqliteDetails } from "../interface/UserSqliteCallInterface";

export const userSubject: any = new BehaviorSubject(
  JSON.parse(localStorage.getItem("user")!)
);

const baseUrl = `${config.apiUrl}/api/users`;

export const accountService = {
  getProfileByUsername,
  sendMail,
  nearbyUsers,
  signin,
  socialSignin,
  logout,
  refreshToken,
  register,
  checkUsername,
  verifyEmail,
  forgotPassword,
  validateResetToken,
  resetPassword,
  changePassword,
  getAll,
  getById,
  create,
  update,
  getCsvUsers,
  deleteUser,
  uploadImage,
  getNewNotifications,
  getNewChats,
  noNewChat,
  noNewNotification,
  changeBlockStatus,
  changeFirstTime,
  checkResetCode,
  delete: _delete,
  getTargets,
  getCountries,
  getQueueString,
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  getStorageName,
  getAllUsers,
  getUserPhysicalActivity,
  latestActivity,
  deleteUserByAdmin,
  updateVersion,
  updateVersionNew,
  getSubscriptionDetails,
  adminSignin,
  userUpdate,
  getUserDetailsData,
};

function changeBlockStatus(userId: string, status: boolean) {
  return fetchWrapper
    .put(`${baseUrl}/block-status`, { userId, status })
    .then((user) => {
      user = { ...userSubject.value, ...user };
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      return user;
    })
    .catch((error) => {});
}

function getProfileByUsername(username: string) {
  return fetchWrapper.get(`${baseUrl}/getProfile/${username}`);
}

function sendMail() {
  return fetchWrapper.get(`${baseUrl}/sendVerificationMail`);
}

async function noNewNotification() {
  return fetchWrapper.get(`${baseUrl}/noNewNotification`);
}

async function getNewNotifications() {
  return fetchWrapper.get(`${baseUrl}/newNotifications`);
}

async function noNewChat() {
  return fetchWrapper.get(`${baseUrl}/noNewChat`);
}

async function getNewChats() {
  return fetchWrapper.get(`${baseUrl}/newChats`);
}

function nearbyUsers(region: {
  name: any;
  countryCode: any;
  stateCode: any;
  latitude: any;
  longitude: any;
}) {
  return fetchWrapper.get(
    `${baseUrl}/nearbyUsers?region=${JSON.stringify(region)}`
  );
}

function uploadImage(data: any, userId: string) {
  return fetchWrapper.postFormData(
    `${baseUrl}/profile-pic?filename=${userId}&username=profile-pics`,
    data
  );
}

function deleteUser(userId: string) {
  return fetchWrapper.delete(`${baseUrl}/delete/${userId}`);
}

function socialSignin(provider: string, token: string) {
  return fetchWrapper
    .post(`${baseUrl}/socialauth`, { provider, token })
    .then((user) => {
      Mixpanel.track("Signed In", {
        "Signin Type": "Social",
      });
      let currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      startRefreshTokenTimer();
      return user;
    });
}

async function signin(email: string, password: string) {
  return fetchWrapper
    .post(`${baseUrl}/admin-login`, { email, password })
    .then((user) => {
      Mixpanel.track("Signed In", {
        "Signin Type": "Email",
      });

      //let currDate = new Date();
      //currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      //localStorage.setItem("tokenexpiry", "" + currDate.getTime());

      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      startRefreshTokenTimer();

      return user;
    });
}

async function adminSignin(
  email: string,
  idToken: string,
  name: any,
  role: any
) {
  return fetchWrapper
    .get(
      `${config.apiUrl}/api/get-logged-userdata?email=${email}&token=${idToken}&name=${name}&role=${role}`
    )
    .then((user) => {
      Mixpanel.track("Signed In", {
        "Signin Type": "Email",
      });

      //let currDate = new Date();
      //currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      //localStorage.setItem("tokenexpiry", "" + currDate.getTime());

      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      startRefreshTokenTimer();

      return user;
    });
}

async function logout() {
  Mixpanel.track("Logged Out");

  stopRefreshTokenTimer();
  localStorage.removeItem("user");
  localStorage.removeItem("tokenexpiry");
  localStorage.removeItem("permissions");
  userSubject.next(null);
  Cookies.remove("refreshToken");
  Cookies.remove("jwtToken");

  return "true";
}

function refreshToken() {
  return fetchWrapper
    .post(`${baseUrl}/refresh-token`, {})
    .then((user) => {
      let currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      startRefreshTokenTimer();
      return user;
    })
    .catch((error) => {
      Cookies.set("refreshtokenavailable", "no", { expires: 7 });
    });
}

function register(body: any) {
  return fetchWrapper.post(`${baseUrl}/signup`, body).then((user) => {
    Mixpanel.track("Signed Up", {
      "Signup Type": "Email",
    });
    let currDate = new Date();
    currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
    localStorage.setItem("tokenexpiry", "" + currDate.getTime());
    localStorage.setItem("user", JSON.stringify(user));
    userSubject.next(user);
    startRefreshTokenTimer();
    return user;
  });
}

async function checkUsername(username: string) {
  try {
    return await axios.get(`${baseUrl}/checkUsername`, {
      params: {
        username: username,
      },
    });
  } catch (error) {
    return error;
  }
}

function verifyEmail(token: any) {
  return fetchWrapper.post(`${baseUrl}/verify-email`, { token });
}

function forgotPassword(email: string) {
  return fetchWrapper.post(`${baseUrl}/forgot-password`, { email });
}

function validateResetToken(token: string | (string | null)[] | null) {
  return fetchWrapper.post(`${baseUrl}/validate-reset-token`, { token });
}

function resetPassword({ token, password, confirmPassword }: any) {
  return fetchWrapper.post(`${baseUrl}/reset-password`, {
    token,
    password,
    confirmPassword,
  });
}

function changePassword(oldPassword: string, newPassword: string) {
  return fetchWrapper.post(`${baseUrl}/change-password`, {
    oldPassword,
    newPassword,
  });
}

function getAll() {
  return fetchWrapper.get(`${baseUrl}/all`);
}

function getById(id: string) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(body: any) {
  return fetchWrapper.post(baseUrl, body);
}

function update(body: any) {
  return fetchWrapper
    .put(`${baseUrl}/updatev2`, body)
    .then((user) => {
      // Merge the updated user data with the existing user data
      user = { ...userSubject.value, ...user };

      // Set token expiry date 3 days from now
      let currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", currDate.getTime().toString());

      // Store the updated user data in localStorage
      localStorage.setItem("user", JSON.stringify(user));

      // Notify all subscribers about the updated user
      userSubject.next(user);

      return user;
    })
    .catch((error) => {
      console.error("Error updating user:", error);
      throw error; // Re-throw the error after logging it
    });
}
function userUpdate(body: any) {
  return fetchWrapper
    .put(`${baseUrl}/update`, body)
    .then((user) => {
      // Merge the updated user data with the existing user data
      user = { ...userSubject.value, ...user };

      // // Set token expiry date 3 days from now
      // let currDate = new Date();
      // currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      // localStorage.setItem("tokenexpiry", currDate.getTime().toString());

      // // Store the updated user data in localStorage
      // localStorage.setItem("user", JSON.stringify(user));

      // // Notify all subscribers about the updated user
      // userSubject.next(user);

      return user;
    })
    .catch((error) => {
      console.error("Error updating user:", error);
      throw error; // Re-throw the error after logging it
    });
}
function _delete(id: string) {
  return fetchWrapper.delete(`${baseUrl}/${id}`).then((x) => {
    // auto logout if the logged in user deleted their own record
    if (userSubject.value && id === userSubject.value.id) {
      logout();
    }
    return x;
  });
}

// helper functions

let refreshTokenTimeout: NodeJS.Timeout;

function startRefreshTokenTimer() {
  if (userSubject.value) {
    const timeout = 10 * 60 * 1000;
    refreshTokenTimeout = setTimeout(refreshToken, timeout);
  }
}

function checkResetCode(body: any) {
  return fetchWrapper.post(`${baseUrl}/check-reset-code`, { body });
}

function changeFirstTime(body: any) {
  return fetchWrapper
    .put(`${baseUrl}/change-firstTime`, { body })
    .then((user) => {
      // update stored user if the logged in user updated their own record
      // if (userSubject.value && user.id === userSubject.value.id) {
      // publish updated user to subscribers
      user = { ...userSubject.value, ...user };

      let currDate = new Date();
      currDate.setMinutes(currDate.getMinutes() + 60 * 24 * 3);
      localStorage.setItem("tokenexpiry", "" + currDate.getTime());
      localStorage.setItem("user", JSON.stringify(user));
      userSubject.next(user);
      // }
      return user;
    })
    .catch((error) => {});
}

function stopRefreshTokenTimer() {
  clearTimeout(refreshTokenTimeout);
}

function getAllUsers({
  numberofCalls,
  userGroupNumber,
  trainerCode,
  deviceType,
  onboarding,
  subscription,
  countries,
  notification,
  maxVersion,
  minVersion,
  genderType,
  goalType,
  activeType,
  userId,
  password,
  activity,
  dateRange,
  contactNumber,
  notes,
  uncheckedCountries,
  countryCode,
  messageSeen,
  allFields,
  csvFile,
  distinctIds,
  openSupportMessage,
  minimumAge,
  maximumAge,
  maximumBmi,
  minimumBmi,
  freePremium,
  freeTrial,
  paidFilter,
  signUpFilter,
  subscriptionCanceledFilter,
  initialQuotaFilter,
  hasGymMembershipFilter,
  freePremiumOverFilter,
}: any) {
  // Initialize the base URL for the API endpoint
  const url = `${config.apiUrl}/api/allUsers`;

  // Handle the conversion of activity date if present
  let processedActivity = undefined;
  if (activity) {
    if (activity.activityType !== "") {
      processedActivity = {
        ...activity,
        date: activity.date ? new Date(activity.date).toISOString() : null, // Ensure activity.date is in ISO format or set to null
      };
    }
  }

  // Handle the conversion of dateRange if present
  let processedDateRange = undefined;
  if (dateRange) {
    if (dateRange.startDate != null || dateRange.endDate != null) {
      processedDateRange = {
        startDate: new Date(dateRange.startDate).toISOString(),
        endDate: new Date(dateRange.endDate).toISOString(),
      };
    }
  }
  let signUpDateFilter = undefined;
  if (signUpFilter !== null) {
    const date = new Date(signUpFilter);
    // Format the date in local time as YYYY-MM-DD (without timezone conversion)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    signUpDateFilter = `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  }
  // Prepare the payload for the POST request
  const body = {
    numberofCalls,
    userGroupNumber,
    trainerCode,
    deviceType,
    onboarding,
    subscription,
    countries,
    notification,
    maxVersion,
    minVersion,
    genderType,
    goalType,
    activeType,
    userId,
    password,
    activity: processedActivity, // Use the processed activity
    dateRange: processedDateRange, // Use the processed dateRange
    contactNumber,
    notes,
    uncheckedCountries,
    countryCode,
    messageSeen,
    allFields,
    csvFile,
    distinctIds,
    openSupportMessage,
    minimumAge,
    maximumAge,
    maximumBmi,
    minimumBmi,
    freePremium,
    freeTrial,
    paidFilter,
    signUpDateFilter,
    subscriptionCanceledFilter,
    initialQuotaFilter,
    hasGymMembershipFilter,
    freePremiumOverFilter,
  };

  // Make the API call using a POST request and pass the body as JSON
  return fetchWrapper.post(url, body);
}

function getCsvUsers({
  numberofCalls,
  userGroupNumber,
  allFields,
  csvFile,
  distinctIds,
}: any) {
  // Create a body object to hold the parameters
  const body = {
    numberofCalls,
    userGroupNumber,
    allFields,
    csvFile,
    distinctIds,
  };

  // Initialize the URL
  const url = `${config.apiUrl}/api/get-csvusers`;

  // Make a POST request with the body
  return fetchWrapper.post(url, body);
}

/// function for getting the queue string----------------------------------/
async function getQueueString(queueName: string) {
  return fetchWrapper.get(
    `${config.apiUrl}/api/get-queuestring?queueName=${queueName}`
  );
}
async function getTargets(id: any) {
  return fetchWrapper.post(`${config.apiUrl}/api/get-user-targets`, {
    id,
  });
}

function getUserPhysicalActivity(id: any) {
  return fetchWrapper.post(`${config.apiUrl}/api/getUserPhysicalActivity`, {
    id,
  });
}

function latestActivity(id: any) {
  return fetchWrapper.post(`${config.apiUrl}/api/get-latest-entry-user`, {
    id,
  });
}
async function getUserDetailsData(id: any): Promise<UserSqliteDetails> {
  return fetchWrapper.post(`${config.apiUrl}/api/get-userDetailsData`, {
    id,
  });
}

function deleteUserByAdmin(id: any) {
  return fetchWrapper.delete(
    config.apiUrl + "/api/delete-user-admin" + "?" + "id=" + id
  );
}
async function getSubscriptionDetails(receiptData: any) {
  return fetchWrapper.post(
    `${config.apiUrl}/api/dashboard-receipt-verify`,
    receiptData
  );
}
/// Function to update version and handeling the api call----------/
function updateVersion(
  type: string,
  currentVersion: string,
  releaseNotes: string
) {
  // Example that trims, removes HTML, replaces line breaks, and handles special characters
  let formattedReleaseNotes = releaseNotes
    .replace(/<[^>]*>?/gm, "") // Remove HTML tags
    .replace(/(\r\n|\n|\r)/gm, "\n") // Convert line breaks to \n
    .trim(); // Trim whitespace

  // Use JSON.stringify for the final step to ensure proper escaping, then remove quotes added by stringify
  formattedReleaseNotes = JSON.stringify(formattedReleaseNotes).slice(1, -1);

  return fetchWrapper.post(`${config.apiUrl}/api/version`, {
    type,
    currentVersion,
    releaseNotes: formattedReleaseNotes,
  });
}
/// Function to update version and handeling the api call----------/
function updateVersionNew(
  type: string,
  currentVersion: string,
  releaseNotes: string,
  mandatory: string
) {
  // Example that trims, removes HTML, replaces line breaks, and handles special characters
  let formattedReleaseNotes = releaseNotes
    .replace(/<[^>]*>?/gm, "") // Remove HTML tags
    .replace(/(\r\n|\n|\r)/gm, "\n") // Convert line breaks to \n
    .trim(); // Trim whitespace

  // Use JSON.stringify for the final step to ensure proper escaping, then remove quotes added by stringify
  formattedReleaseNotes = JSON.stringify(formattedReleaseNotes).slice(1, -1);

  return fetchWrapper.post(`${config.apiUrl}/api/version-new`, {
    type,
    currentVersion,
    releaseNotes: formattedReleaseNotes,
    mandatory: mandatory,
  });
}
//// Function for the api call for getting all the countries from the db------/
function getCountries(id: string) {
  return fetchWrapper.get(`${config.apiUrl}/api/get-countries?id=${id}`);
}

//// Function for the api call for getting all the countries from the db------/
function getStorageName(): Promise<{ storageName: string }> {
  return fetchWrapper.get(
    `${config.apiUrl}/api/get-azure-storage-account-name`
  );
}
