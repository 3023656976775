import { Box, Modal, Typography, IconButton, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { weekCardioDetails } from "../services/exercise.service";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ScatterChart, Scatter, BarChart, Bar, ResponsiveContainer } from "recharts";
import React from "react";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface CardioTrackModal {
    cardioTrackmodalOpen: boolean;
    setCardioTrackModalOpen: (value: boolean) => void;
    currentExercise: any;
    setCurrentExercise: any;
}

export default function ExerciseTrackModal({ cardioTrackmodalOpen, setCardioTrackModalOpen, currentExercise, setCurrentExercise }: CardioTrackModal) {


    const [heighestSet, setHighestSet] = useState<number[]>([]);
    const [setsData, setSetData] = useState<any[]>([]);
    const [currentDate, setCurrentDate] = useState(new Date());
   const [dateRange,setDateRange]=useState({
    startDate:"",
    endDate:""
   })

    async function getExerciseResult(currentExercise: any,currentDay: any) {
        const result = await weekCardioDetails(currentExercise, currentDay);
        
        setHighestSet(result.biggestArray);
        setSetData(result.formattedExercise);
        setDateRange(result.dateRange)
    }

    useEffect(() => {
        if (cardioTrackmodalOpen) {
            let currentDate=new Date();
            setCurrentDate(currentDate)
            getExerciseResult(currentExercise, currentDate);
        }
    }, [cardioTrackmodalOpen, ]);

    const handlePreviousWeek = () => {
        const previousWeek = new Date(currentDate);
        previousWeek.setDate(previousWeek.getDate() - 7);
        setCurrentDate(previousWeek);
        getExerciseResult(currentExercise, previousWeek);
    };

    const handleNextWeek = () => {
        const nextWeek = new Date(currentDate);
        nextWeek.setDate(nextWeek.getDate() + 7);
        setCurrentDate(nextWeek);
        getExerciseResult(currentExercise, nextWeek);
    };

    function closeModal() {
        setHighestSet([]);
        setDateRange({startDate:"",endDate:""})
        setCurrentExercise();
        setCardioTrackModalOpen(false);
    }

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const { payload: dataPayload } = payload[0];
            const { rep, dateTime, duration } = dataPayload;
            const setsData = Object.keys(dataPayload)
                .filter((key) => key.startsWith('set'))
                .map((key) => ({ name: key, value: dataPayload[key] }));
    
            return (
                <div style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
                    <p>{`Duration: ${duration} min`}</p>
                   

                </div>
            );
        }
        return null;
    };
    

    return (
        <Modal
            open={cardioTrackmodalOpen}
            onClose={() => closeModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    width: '70%',
                    height: '80%',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px',alignItems:"center" }}>
                    <IconButton onClick={handlePreviousWeek}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    
                    <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                           {dateRange.startDate} - {dateRange.endDate}
                        </Typography>
                    <IconButton onClick={handleNextWeek}>
                        <NavigateNextIcon />
                    </IconButton>
                </div>
                {currentExercise ? (
                    <div>
                              <Typography variant="h6" component="h2" style={{ textAlign: "center", fontWeight: "bold" }}>
                        {currentExercise.exercise}
                    </Typography>
                        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                            Category: {currentExercise.category}
                        </Typography>
                      
                    </div>
                ) : null}

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {/* Your content here */}
                </Typography>
                {setsData && setsData.length>0 ? (
                    <ResponsiveContainer width="100%" height="80%">
                        <BarChart
                            width={400}
                            height={220}
                            data={setsData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="dateTime" />
                            <YAxis
                                type="number"
                                dataKey={'duration'}
                                tickFormatter={(value) => `${value} min`} 
                            />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            {heighestSet.map((number, index) => (
                                <Bar
                                    key={`bar-${index}`}
                                    dataKey={`set${number}.duration`}
                                    fill={`rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.6)`}
                                    barSize={20}
                                    maxBarSize={30}
                                />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                ):(  <ResponsiveContainer width="100%" height="80%">
                
                <BarChart
                    width={400}
                    height={220}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="rep" />
                    <YAxis domain={[0, 20]}  tickFormatter={(value) => `${value} min`} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="dummy" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>)}
            </Box>
        </Modal>
    );
}
