import { Box, Collapse, Container, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import ExerciseTrackModal from "./ExerciseTrackModal";
import CardioTrackModal from "./CardioTrackModal";
interface Exercise{
    open:boolean;
    exerciseData:any;
}


export default function Exercise({open,exerciseData}:Exercise){
 
    const [cardioTrackmodalOpen,setCardioTrackModalOpen]=useState(false);
    const [exerciseTrackmodalOpen, setExerciseTrackModalOpen] = useState(false); // State to manage modal open/close
     const [currentExercise,setCurrentExercise]=useState<any>();
    const handleTrackButtonClick = (exercise:any) => {
      if(exercise.category==="Cardio"){
        setCardioTrackModalOpen(true); // Open modal when track button is clicked
 
        setCurrentExercise(exercise)
      }else{
        setExerciseTrackModalOpen(true); // Open modal when track button is clicked
 
        setCurrentExercise(exercise)
      }
      
    };
    return(
        <>
        <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Exercise Name
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {exerciseData.map((exercise: any, index: any) => (
                    <Box
                      key={index}
                      sx={{
                        marginBottom: 2,
                        border: "1px solid #e0e0e0",
                        borderRadius: "4px",
                      

                        display:"flex",flexDirection:"column"
                      }}
                    >
                      <TableRow style={{width:"100%",display:"flex"}}>
                        <TableCell style={{width:"100%",display
                    :"flex"}}>
                           <Container style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                          <Typography style={{ fontWeight: "500" }}>
                            {exercise.exercise}
                          </Typography>
                          <Typography   onClick={()=>handleTrackButtonClick(exercise)}  style={{ fontWeight: "500",color:"#1067bf",cursor:"pointer" }}>
                        Track
                          </Typography>
                          </Container> 
                        </TableCell>
    
                        {/* ... more cells for other properties ... */}
                      </TableRow>
                    </Box>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ExerciseTrackModal
      setExerciseTrackModalOpen={setExerciseTrackModalOpen}
      exerciseTrackmodalOpen={exerciseTrackmodalOpen}
      currentExercise={currentExercise}
      setCurrentExercise={setCurrentExercise}
      />
      <CardioTrackModal
      setCardioTrackModalOpen={setCardioTrackModalOpen}
      cardioTrackmodalOpen={cardioTrackmodalOpen}
      currentExercise={currentExercise}
      setCurrentExercise={setCurrentExercise}
      />
      </>
    )
}