/** @format */
import {
  OwnWorkoutInterface,
  SetInterface,
} from "../interface/OwnCreatedWorkoutInterface";
// Function to check if two dates are the same day
function isSameDay(date1: any, date2: any) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

//// Function for getting the exercise of a particular day-------------------/
async function getCurrentDayWorkout(
  workouts: OwnWorkoutInterface[],
  currentDate: Date
): Promise<OwnWorkoutInterface[]> {
  const filteredWorkouts = [];
  try {
    for (const workout of workouts) {
      let workoutHasMatchingExercise = false;

      for (const exercise of workout.exercises) {
        if (
          exercise.sets.some((set: SetInterface) =>
            isSameDay(new Date(set.dateTime), currentDate)
          )
        ) {
          workoutHasMatchingExercise = true;
          break;
        }
      }

      if (workoutHasMatchingExercise) {
        filteredWorkouts.push(workout);
      }
    }

    return filteredWorkouts;
  } catch (error) {
    return filteredWorkouts;
  }
}

export const workoutUtilsFunctions = {
  getCurrentDayWorkout,
  isSameDay,
};
