import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Container,
  Collapse,
  CircularProgress,
  Grid,
  Snackbar,
  Button,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { SingleRecipe } from "../../../interface/RecipeInterface";
import { recipeService } from "../../../services/recipe.service";
import EmptyMultiRecipeCard from "../Multi/EmptyMultiRecipeCard";
import { multiRecipeUtils } from "../../../utils/RecipePage/MultiRecipe";
import CardImageModal from "../CardImageModal";
import EditIcon from "@mui/icons-material/Edit";
import { accountService } from "../../../services/account.service";
import EditRecipeModal from "../EditRecipeModal";
interface Saved {
  setRenderMiddleSection: (type: string) => void;
  setSelectedEditingRecipe: (type: SingleRecipe | null) => void;
  setUpdateScreenName: (type: string) => void;
  updateScreenName: any;
}
//// Main function for showing the saved meals-----------------------------------------------------------------------------/
const Prod = ({
  setRenderMiddleSection,
  setSelectedEditingRecipe,
  setUpdateScreenName,
  updateScreenName,
}: Saved) => {
  const [savedRecipes, setSavedRecipes] = useState<SingleRecipe[]>([]);
  const [savedRecipeLoading, setSavedRecipeLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openId, setOpenId] = useState(null);
  const [selectedRecipes, setSelectedRecipes] = useState<any[]>([]);
  const [recipeImageModalOpen, setRecipeImageModalOpen] = useState(false);
  const [recipeId, setRecipeId] = useState("");
  const [storageName, setStorageName] = useState<string | null>(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentEditingRecipe, setCurrentEditingRecipe] =
    useState<SingleRecipe | null>(null);
  const openEditModal = (recipe: SingleRecipe) => {
    setCurrentEditingRecipe(recipe);
    setEditModalOpen(true);
  };
  function onEditManualClose() {
    setEditModalOpen(false);
  }
  /// Function for closing the image modal ---------------------------------------------------------/
  const closeRecipeImageModal = () => {
    setRecipeImageModalOpen(!closeRecipeImageModal);
  };
  //// Funciton for opening up the image modal ----------------------------------------------------/
  function openImage(recipeId: string) {
    setRecipeId(recipeId);
    setRecipeImageModalOpen(!recipeImageModalOpen);
  }
  const handleClick = (id: any) => {
    setOpenId(openId === id ? null : id);
  };
  const handleSelectRecipe = (id: any) => {
    if (selectedRecipes.includes(id)) {
      setSelectedRecipes(selectedRecipes.filter((recipeId) => recipeId !== id));
    } else {
      setSelectedRecipes([...selectedRecipes, id]);
    }
  };
  /// Function for closing the snackbar------------------------------------------/
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  async function fetchSavedRecipes() {
    setSavedRecipeLoading(true);
    try {
      const storageNameResponse = await accountService.getStorageName();
      setStorageName(storageNameResponse.storageName);
      const savedRecipesResponse = await recipeService.getRecipesFromDb("prod");
      const { message, success, recipeData } = savedRecipesResponse;

      if (success) {
        setSavedRecipeLoading(false);
        setSnackbarMessage(message);
        setSavedRecipes(recipeData);
      } else {
        setSavedRecipeLoading(false);
        setSnackbarMessage(message);
        setSavedRecipes([]);
      }
    } catch (error) {
      setSavedRecipeLoading(true);
      setSnackbarMessage(
        "Some error has happened while fetching the saved recipes"
      );
    }
  }

  /// useffect function for fetching the saved recipes from the backend------------------------/
  useEffect(() => {
    fetchSavedRecipes();
  }, [updateScreenName]);
  /// Function for Deleting the saved card -----------------------------------------------------/
  async function deleteCards(selectedRecipes: SingleRecipe[]) {
    if (selectedRecipes.length > 0) {
      setSavedRecipeLoading(true);
      const idArray = selectedRecipes.map((recipe) => recipe._id);
      try {
        const deleteResponse = await recipeService.deleteRecipes(
          "prod",
          idArray
        );

        let { message, success } = deleteResponse;

        if (success) {
          // Filter out the deleted recipes from the savedRecipes state
          const updatedRecipes = savedRecipes.filter(
            (recipe) => !idArray.includes(recipe._id)
          );
          setSelectedRecipes([]);
          setSavedRecipes(updatedRecipes);
          setSnackbarOpen(true);
          setSnackbarMessage(message);
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage(message);
        }
      } catch (error) {
        setSavedRecipeLoading(false);
        setSnackbarMessage("Some errror has happened");
        setSnackbarOpen(true);
      } finally {
        setSavedRecipeLoading(false);
      }
    } else {
      setSnackbarOpen(true);
      setSnackbarMessage("Select the recipes before deleting");
    }
  }
  //// Function for editing any recipe and openeing the single meal edit section ---------------------------/
  function editRecipe(recipe: SingleRecipe) {
    setUpdateScreenName("prod");
    setRenderMiddleSection("single");
    setSelectedEditingRecipe({
      ...recipe,
      image: `https://${storageName}.blob.core.windows.net/reshape-public/recipes/images/prod/${recipe._id}.png`,
    });
  }

  return (
    <>
      {savedRecipeLoading ? (
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <>
          {savedRecipes && savedRecipes.length > 0 ? (
            <>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => deleteCards(selectedRecipes)}
                  >
                    Delete
                  </Button>
                </Box>
              </Container>
              {savedRecipes.map((recipe: SingleRecipe, index: number) => (
                <div
                  key={index}
                  style={{
                    width: "70%",
                    marginLeft: 70,
                  }}
                >
                  <Card
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0 12px 24px rgba(0,0,0,0.3)",
                      marginBottom: 10,
                      zIndex: 2,
                      height: 520,
                      overflowY: "scroll",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <CardContent
                      style={{ padding: "20px", borderRadius: "10px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ width: "20%" }}>
                          <img
                            src={`https://${storageName}.blob.core.windows.net/reshape-public/recipes/images/prod/${
                              recipe._id
                            }.png??buster=${Date.now()}`}
                            alt="Recipe"
                            style={{
                              width: "100%",
                              borderRadius: 4,
                              cursor: "pointer",
                              height: "100%",
                            }}
                            onClick={() => {
                              openImage(recipe._id);
                            }}
                          />
                        </Box>
                        <Container
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "70%",
                          }}
                        >
                          <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                              textAlign: "start",
                              width: "100%",
                              fontWeight: "BOLD",
                            }}
                          >
                            {recipe.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ textAlign: "start", width: "100%" }}
                          >
                            {recipe.description}
                          </Typography>
                        </Container>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <EditNoteIcon
                            color="primary"
                            sx={{
                              marginBottom: 3,
                              cursor: "pointer",
                              fontSize: 30,
                            }}
                            onClick={() => editRecipe(recipe)}
                          />
                          <input
                            type="checkbox"
                            checked={selectedRecipes.some(
                              (item) => item._id === recipe._id
                            )}
                            onChange={() => handleSelectRecipe(recipe)}
                            style={{ transform: "scale(1.5)" }}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: 2,
                              cursor: "pointer",
                            }}
                            onClick={() => openEditModal(recipe)}
                          >
                            <EditIcon
                              fontSize="small"
                              color="primary"
                              sx={{ cursor: "pointer" }}
                            />
                            <Typography>Manual</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "10px",
                        }}
                      >
                        {[
                          { key: "protein", label: "Protein", unit: "g" },
                          { key: "carbs", label: "Carbs", unit: "g" },
                          { key: "fats", label: "Fat", unit: "g" },
                          {
                            key: "calories",
                            label: "Calories",
                            unit: "kcal",
                          }, // Calories typically do not need 'g' unit
                          { key: "fiber", label: "Fiber", unit: "g" }, // Calories typically do not need 'g' unit
                          { key: "added_sugar", label: "Sugar", unit: "" }, // Calories typically do not need 'g' unit
                        ].map((nutrient) => (
                          <Card
                            key={nutrient.key}
                            sx={{
                              padding: "10px",
                              margin: "5px",
                              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                              backgroundColor: "#e3f2fd",
                              width: "22%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                textAlign: "center",
                                "@media (max-width: 1290px)": {
                                  fontSize: 10, // Apply bold weight when the screen width is below 1290px
                                },
                              }}
                            >
                              {nutrient.label}: {recipe[nutrient.key]}
                              {nutrient.unit}
                            </Typography>
                          </Card>
                        ))}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <Typography variant="h6">
                          Direction :{" "}
                          <span style={{ fontSize: 15 }}>
                            {recipe.direction}
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <Typography variant="h6">
                          Duration :{" "}
                          <span style={{ fontSize: 15 }}>
                            {recipe.duration}
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ textAlign: "start", width: "100%" }}
                        >
                          Categories:{" "}
                          {recipe.categories && recipe.categories.length > 0
                            ? recipe.categories.map(
                                (categories: any, index: number) => (
                                  <React.Fragment key={index}>
                                    {categories}
                                    {index !== categories.length - 1 && ", "}
                                  </React.Fragment>
                                )
                              )
                            : "No categories available"}
                        </Typography>
                      </Box>
                      <Typography
                        variant="h6"
                        style={{ marginTop: "20px" }}
                        onClick={() => handleClick(index)}
                      >
                        Ingredients{" "}
                        {openId === index ? <ExpandLess /> : <ExpandMore />}
                      </Typography>
                      <Collapse
                        in={openId === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          {recipe.ingredients.map((ingredient, index) => (
                            <ListItem
                              key={index}
                              button
                              onClick={() => handleClick(index)}
                              style={{
                                width: "100%",
                                backgroundColor: "#f5f5f5",
                                marginBottom: "5px",
                              }}
                            >
                              <ListItemText
                                primary={
                                  <Box
                                    sx={{
                                      marginBottom: "10px",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <Typography sx={{ fontWeight: "bold" }}>
                                        {ingredient.name}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {Object.entries(ingredient).map(
                                        ([key, value]) => {
                                          if (
                                            [
                                              "calories",
                                              "protein",
                                              "carbs",
                                              "fats",
                                              "added_sugar",
                                              "qty",
                                              "unit",
                                            ].includes(key)
                                          ) {
                                            // Render only nutrient properties in cards
                                            return (
                                              <Card
                                                style={{
                                                  padding: "3px",
                                                  boxShadow:
                                                    "0 4px 8px rgba(0,0,0,0.2)",
                                                  backgroundColor: "#e3f2fd",
                                                  textAlign: "center",
                                                  width: 60,
                                                  marginTop: 5,
                                                  minWidth: 45,
                                                }}
                                              >
                                                <Typography
                                                  variant="body2"
                                                  sx={{
                                                    fontWeight: "500",
                                                    fontSize: 10,
                                                  }}
                                                >
                                                  {key.charAt(0).toUpperCase() +
                                                    key.slice(1)}
                                                  :
                                                </Typography>
                                                <Typography variant="body2">
                                                  {multiRecipeUtils.formatIngredientValue(
                                                    value
                                                  )}{" "}
                                                </Typography>
                                              </Card>
                                            );
                                          }
                                          return null;
                                        }
                                      )}
                                    </Box>
                                  </Box>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </>
          ) : (
            <EmptyMultiRecipeCard />
          )}
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
      <CardImageModal
        recipeImageModalOpen={recipeImageModalOpen}
        closeRecipeImageModal={closeRecipeImageModal}
        recipeId={recipeId}
        setRecipeImageModalOpen={setRecipeImageModalOpen}
        recipeType={"prod"}
        storageName={storageName}
      />
      <EditRecipeModal
        editManualRecipeOpen={editModalOpen}
        onEditManualClose={onEditManualClose}
        recipe={currentEditingRecipe}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
        fetchSavedRecipes={fetchSavedRecipes}
        dbType={"prod"}
      />
    </>
  );
};

export default Prod;
