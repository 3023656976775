/** @format */

import { SleepInterface } from "../interface/SleepInterface";

////// Utils function for Sleep-------------------------------------------------------/

//// Function for getting the sleepdata of a particular daay------------------/
async function getCurrentDaySleep(
  sleepData: SleepInterface[],
  currentDate: Date
): Promise<SleepInterface[]> {
  let sleepArray: SleepInterface[] = [];
  try {
    const currentYear = new Date(currentDate).getFullYear();
    const currentMonth = new Date(currentDate).getMonth();
    const currentDay = new Date(currentDate).getDate();

    for (let index = 0; index < sleepData.length; index++) {
      const stepsYear = new Date(sleepData[index].dateTime).getFullYear();
      const stepsMonth = new Date(sleepData[index].dateTime).getMonth();
      const stepsDay = new Date(sleepData[index].dateTime).getDate();

      if (
        currentYear === stepsYear &&
        currentMonth === stepsMonth &&
        currentDay === stepsDay
      ) {
        sleepArray.push(sleepData[index]);
      }
    }
    return sleepArray;
  } catch (error) {
    return sleepArray;
  }
}

//// Function For formatting the SleepSession into hour an minute format
function formatSleepSession(session: any) {
  const hours = Math.floor(session);
  const minutes = Math.round((session - hours) * 60);
  return `${hours} hour ${minutes} minutes`;
}

//// Function For formatting the Time into hour :minute :a.m/p.m format---/
function formatTime(timeString: any) {
  const time = new Date(timeString);
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

//// Exporting all the functions  ---------------------------------------/

export const sleepUtilsFunction = {
  getCurrentDaySleep,
  formatSleepSession,
  formatTime,
};
