/** @format */

import React, { useState, useEffect } from "react";
import {
  Container,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Divider,
  Paper,
  Typography,
  Box,
  TableContainer,
  Button,
  IconButton,
  Snackbar,
} from "@mui/material";
// Import Pagination from MUI
import Pagination from "@mui/material/Pagination";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

import {
  NutritionLogDataInterface,
  SelectedNutritionLogInterface,
} from "../interface/NutritionLogInterface";
import { nutritionlogsService } from "../services/nutritionlog.service";
import { accountService } from "../services";
import TopAppBar from "../modules/TopAppBar";

import NutritionFilterModal from "../modules/NutritionFilterModal";
import NutritionImageModal from "../modules/NutritionImageModal";
import { nutritionLogUtilsFunction } from "../utils/NutritionLog";

const NutritionLogPage = () => {
  let eachPageData = 100;
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [nutritionLogDetails, setNutritionLogDetails] = useState<
    NutritionLogDataInterface[]
  >([]);
  const [totalnutritionLogDetails, setTotalNutritionLogDetails] = useState<
    NutritionLogDataInterface[]
  >([]);
  const [sasToken, setSasToken] = useState<string | null>(null);
  const [storageAccount, setStorageAccount] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [imageModal, setImageModal] = React.useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [filteredType, setFilteredType] = useState("");
  const [videoAttachment, setVideoAttachment] = React.useState<boolean>(false);
  // Define state to keep track of the sorting order for createdAt column
  const [createdAtSortOrder, setCreatedAtSortOrder] = useState<"asc" | "desc">(
    "desc" // By default, set to descending order
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [selectedNutritionData, setSelectedNutritionData] =
    useState<SelectedNutritionLogInterface>();
  const [filteredStatus, setFilteredStatus] = useState<string | null>("");
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const [selectedNutritionDataIndex, setSelectedNutritionDataIndex] =
    useState<number>(0);
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalDocuments / eachPageData);

  const fetchNutritionLogDetails = async (
    pageNum: number,
    createdAtSortOrder: string
  ) => {
    setLoading(true);
    try {
      const loggedUser = await accountService.userValue;
      const startNumber = (pageNum - 1) * eachPageData;
      const endNumber = startNumber + eachPageData;

      const response = await nutritionlogsService.getNutritionLogs(
        loggedUser.id,
        startNumber,
        endNumber
      );

      if (response) {
        /// filtering the nutrition data based on type/status/date
        const filteredNutritionData =
          await nutritionLogUtilsFunction.filterNutritionLogs(
            response.nutritionLogDetails,
            filteredType,
            filteredStatus,
            createdAtSortOrder
          );
        setSasToken(response.sasToken);
        setStorageAccount(loggedUser.storageAccount);
        setNutritionLogDetails(filteredNutritionData);
        setTotalDocuments(response.totalDocuments);
        setTotalNutritionLogDetails(response.nutritionLogDetails);
      }
    } catch (error) {
      console.error("Error fetching nutrition log details:", error);
      setNutritionLogDetails([]);
      setTotalNutritionLogDetails([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNutritionLogDetails(currentPage, createdAtSortOrder); // Fetch data for the current page
  }, [currentPage]); // Dependency array: Refetch when currentPage changes

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };

  // Function to handle toggling the sorting order for createdAt column
  const handleToggleSortOrder = () => {
    // Toggle the sorting order between "asc" and "desc"
    const newSortOrder = createdAtSortOrder === "asc" ? "desc" : "asc";

    // Sort the nutritionLogDetails array based on the new sorting order
    const sortedDetails = [...nutritionLogDetails].sort((a: any, b: any) => {
      // Check if either of the objects has no 'createdAt' field
      if (!a.createdAt || !b.createdAt) {
        // Put the object without 'createdAt' field on top when sorting in ascending order
        if (newSortOrder === "asc") {
          return !a.createdAt ? -1 : 1;
        }
        // Put the object without 'createdAt' field at the bottom when sorting in descending order
        else {
          return !a.createdAt ? 1 : -1;
        }
      }

      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();

      // For descending order: latest createdAt first, earliest createdAt last
      return newSortOrder === "desc" ? dateB - dateA : dateA - dateB;
    });

    // Update the sorted nutritionLogDetails array and the sorting order state
    setNutritionLogDetails(sortedDetails);
    setCreatedAtSortOrder(newSortOrder);
  };
  function imageSelected(detail: NutritionLogDataInterface, index: number) {
    setSelectedNutritionData({
      ...detail, // Spread all properties of the nutrition log detail
      imageUrl: `https://${storageAccount}.blob.core.windows.net/user-data/${detail.user}/${detail.input}?${sasToken}`, // Assuming 'input' holds your image URL; adjust if necessary
    });
    setSelectedNutritionDataIndex(index);
    setImageModal(true);
  }

  //// Function for finding the next or previous data with image-------/
  function changeModalData(changeType: string, currentIndex: number) {
    let newIndex = -1;
    const totalItems = nutritionLogDetails.length;

    if (changeType === "next") {
      for (let i = currentIndex + 1; i < totalItems; i++) {
        if (nutritionLogDetails[i].type === "image") {
          newIndex = i;
          break;
        }
      }
    } else if (changeType === "prev") {
      for (let i = currentIndex - 1; i >= 0; i--) {
        if (nutritionLogDetails[i].type === "image") {
          newIndex = i;
          break;
        }
      }
    }

    if (newIndex !== -1) {
      // Found an image, update the selected data and index
      const queryString = `?${sasToken}`;
      setSelectedNutritionData({
        ...nutritionLogDetails[newIndex],
        imageUrl: `${nutritionLogDetails[newIndex].input}${queryString}`,
      });
      setSelectedNutritionDataIndex(newIndex);
      setOpenSnackbar(false); // Close the snackbar in case it was open
    } else {
      // No more images available, show the snackbar
      setOpenSnackbar(true);
    }
  }

  return (
    <>
      <NutritionFilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        totalnutritionLogDetails={totalnutritionLogDetails}
        nutritionLogDetails={nutritionLogDetails}
        setNutritionLogDetails={setNutritionLogDetails}
        filteredType={filteredType}
        setFilteredType={setFilteredType}
        filteredStatus={filteredStatus}
        setFilteredStatus={setFilteredStatus}
      />
      <TopAppBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              // Apply different variants for different breakpoints
              fontSize: {
                xs: "0.7rem", // Use a smaller font size on extra-small screens
                sm: "1rem", // Use a slightly larger font size on small screens
                md: "2.125rem", // Use the default h4 size on medium screens and above
              },
            }}
          >
            Nutrition Log Details
          </Typography>

          <Button
            color="primary"
            style={{ marginTop: 10 }}
            onClick={toggleModal}
          >
            Filter
          </Button>
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handleChangePage}
              color="primary"
              style={{ marginTop: "20px" }}
            />
          )}
        </Container>
        <Paper
          style={{
            border: "1px solid #ddd",
            padding: "1rem",
            marginTop: 20,
            width: "90%",
          }}
        >
          <Box>
            {loading ? (
              <Container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </Container>
            ) : (
              <>
                {nutritionLogDetails && nutritionLogDetails.length === 0 ? (
                  <Container style={{ width: "100%" }}>
                    <Typography align="center">
                      No nutrition log found
                    </Typography>
                  </Container>
                ) : (
                  <TableContainer style={{ maxHeight: "75vh" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              position: "sticky",
                              top: 0,
                              backgroundColor: "white",
                            }}
                          >
                            No.
                          </TableCell>
                          <Divider orientation="vertical" />
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              position: "sticky",
                              top: 0,
                              backgroundColor: "white",
                            }}
                          >
                            Input
                          </TableCell>
                          <Divider orientation="vertical" />
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              position: "sticky",
                              top: 0,
                              backgroundColor: "white",
                            }}
                          >
                            Type
                          </TableCell>
                          <Divider orientation="vertical" />
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              position: "sticky",
                              top: 0,
                              backgroundColor: "white",
                            }}
                          >
                            Status
                          </TableCell>
                          <Divider orientation="vertical" />
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              position: "sticky",
                              top: 0,
                              backgroundColor: "white",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              // Always use "primary" color for the arrow icon when sorting
                            >
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  marginRight: 3,
                                  fontSize: 14,
                                }}
                              >
                                Created
                              </Typography>
                              {createdAtSortOrder === "desc" ? (
                                <ArrowDownward
                                  onClick={handleToggleSortOrder} // Toggle the sorting order when the arrow icon is clicked
                                  color="primary"
                                  style={{ cursor: "pointer", fontSize: 22 }}
                                />
                              ) : (
                                <ArrowUpward
                                  onClick={handleToggleSortOrder} // Toggle the sorting order when the arrow icon is clicked
                                  color="primary"
                                  style={{ cursor: "pointer", fontSize: 22 }}
                                />
                              )}{" "}
                              {/* Render the arrow icon based on the sorting order */}
                            </div>

                            {/* Label for sorting */}
                          </TableCell>
                          <Divider orientation="vertical" />
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              position: "sticky",
                              top: 0,
                              backgroundColor: "white",
                            }}
                          >
                            Raw Output
                          </TableCell>
                          <Divider orientation="vertical" />
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              position: "sticky",
                              top: 0,
                              backgroundColor: "white",
                            }}
                          >
                            Output
                          </TableCell>
                          <Divider orientation="vertical" />
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              position: "sticky",
                              top: 0,
                              backgroundColor: "white",
                            }}
                          >
                            Error Message
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {nutritionLogDetails.map(
                          (
                            detail: NutritionLogDataInterface,
                            index: number
                          ) => (
                            <TableRow key={detail._id}>
                              <TableCell align="center">{index + 1}</TableCell>
                              <Divider orientation="vertical" />
                              <TableCell align="center">
                                {detail.type === "image" ? (
                                  <img
                                    onClick={() => imageSelected(detail, index)}
                                    src={`https://${storageAccount}.blob.core.windows.net/user-data/${detail.user}/${detail.input}?${sasToken}`}
                                    alt="image"
                                    style={{ height: "50px" }}
                                  />
                                ) : (
                                  <span>{detail.input}</span>
                                )}
                              </TableCell>
                              <Divider orientation="vertical" />
                              <TableCell align="center">
                                {detail.type}
                              </TableCell>
                              <Divider orientation="vertical" />
                              <TableCell align="center">
                                {detail.status}
                              </TableCell>
                              <Divider orientation="vertical" />
                              <TableCell align="center">
                                {detail.createdAt
                                  ? new Date(detail.createdAt).toLocaleString(
                                      [],
                                      {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      }
                                    )
                                  : null}
                              </TableCell>
                              <Divider orientation="vertical" />
                              <TableCell align="center">
                                {detail.rawOutput}
                              </TableCell>
                              <Divider orientation="vertical" />
                              <TableCell align="center">
                                {/* Check if detail.rawOutput should be parsed and displayed as JSON */}
                                {typeof detail.output === "object" ? (
                                  <pre>
                                    {JSON.stringify(detail.output, null, 2)}
                                  </pre>
                                ) : (
                                  detail.rawOutput
                                )}
                              </TableCell>

                              <Divider orientation="vertical" />
                              <TableCell
                                align="center"
                                style={{ maxWidth: 250 }}
                              >
                                <Box
                                  maxWidth={500}
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  sx={{ textAlign: "justify" }}
                                >
                                  {detail.error_msg}
                                </Box>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </>
            )}
          </Box>
        </Paper>
      </Box>

      <NutritionImageModal
        isPopupOpen={imageModal}
        setIsPopupOpen={setImageModal}
        isVideo={videoAttachment}
        nutritionData={selectedNutritionData}
        changeModalData={changeModalData}
        selectedNutritionDataIndex={selectedNutritionDataIndex}
      />
      <>
        {/* Your existing components */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          message="No more images available for this page"
        />
      </>
    </>
  );
};

export default NutritionLogPage;
