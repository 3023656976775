import React, { useState } from "react";
import { Box, Typography, TextField, Button, IconButton } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

interface StarRatingProps {
  input_fields: {
    title: string;
    body?: string;
    imageUrl?: string | null;
    ratingType?: string;
    feedbackType?: string;
    feedbackText?: string;
    feedbackBoxPlaceholder?: string;
  };
}

const StarRatingPreview: React.FC<StarRatingProps> = ({ input_fields }) => {
  const [rating, setRating] = useState<number | null>(null);
  const [feedback, setFeedback] = useState<string>("");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        padding: 1,
        borderRadius: 2,
        boxShadow: 3,
        overflowY: "scroll",
      }}
    >
      {/* Title */}
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
        {input_fields.title}
      </Typography>

      {/* Body */}
      {/* Star/Number Rating */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {Array.from({ length: 10 }, (_, index) => (
          <Box
            key={index}
            onClick={() => setRating(index + 1)}
            sx={{
              padding: "2px 4px",
              backgroundColor: rating === index + 1 ? "#1E88E5" : "#E0E0E0",
              borderRadius: "1px",
              margin: "0 2px",
              cursor: "pointer",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            {index + 1}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "85%",
        }}
      >
        <IconButton>
          <SentimentVeryDissatisfiedIcon
            color={rating !== null && rating <= 5 ? "error" : "disabled"}
          />
        </IconButton>
        <IconButton>
          <SentimentVerySatisfiedIcon
            color={rating !== null && rating > 5 ? "success" : "disabled"}
          />
        </IconButton>
      </Box>
      {/* Image */}
      {input_fields.imageUrl && (
        <img
          src={input_fields.imageUrl}
          alt="Star rating"
          style={{
            width: "50%",
            maxHeight: 120,
            objectFit: "cover",
            borderRadius: 8,
            marginTop: "2px",
          }}
        />
      )}
      {input_fields.body && (
        <Typography variant="body1" sx={{ mt: 1, textAlign: "center" }}>
          {input_fields.body}
        </Typography>
      )}

      {/* Feedback Text */}
      {input_fields.feedbackText && (
        <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
          {input_fields.feedbackText}
        </Typography>
      )}

      {/* Feedback Input Box */}
      <TextField
        fullWidth
        placeholder={
          input_fields.feedbackBoxPlaceholder || "Enter your feedback..."
        }
        multiline
        rows={2}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        variant="outlined"
        sx={{ mt: 1, width: "100%" }}
      />

      {/* Submit Button */}
      <Button
        variant="contained"
        sx={{ mt: 2, backgroundColor: "#1E88E5", width: "100%" }}
        onClick={() => console.log({ rating, feedback })}
      >
        Submit
      </Button>
    </Box>
  );
};

export default StarRatingPreview;
