import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import TopAppBar from "../modules/TopAppBar";
import { accountService } from "../services";
import { userUtils } from "../utils/userUtils";

import { BlobServiceClient } from "@azure/storage-blob";
import imageCompression from "browser-image-compression";

const ProfilePage: React.FC = () => {
  const user = accountService.userValue;

  const [newProfilePicture, setNewProfilePicture] = useState("");
  const [newTimezone, setNewTimezone] = useState(
    user.metadata != null
      ? userUtils.convertTimezoneOffsetToReadableFormat(
          user.metadata.timezoneOffset
        )
      : ""
  );

  const [accountName, setAccountName] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProfilePicture = async () => {
      const accountName = await userUtils.getStorageName();

      const timestamp = new Date().getTime();
      const profilePicUrl = `https://${accountName}.blob.core.windows.net/user-data/${user.id}/profile-pic?${user.sasToken}&t=${timestamp}`;
      setNewProfilePicture(profilePicUrl);
      setAccountName(accountName);
    };

    fetchProfilePicture();
  }, [user]);

  const handleProfilePictureChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setLoading(true);
      const file = event.target.files[0];

      // Compress the image file
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64 = reader.result as string;

          const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net/?${user.sasToken}`
          );

          const containerClient =
            blobServiceClient.getContainerClient("user-data");
          const blobClient = containerClient.getBlockBlobClient(
            `${user.id}/profile-pic`
          );

          const byteCharacters = atob(base64.split(",")[1]);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: compressedFile.type });

          await blobClient.uploadData(blob, {
            blobHTTPHeaders: { blobContentType: compressedFile.type },
          });

          const timestamp = new Date().getTime();
          const newProfilePictureUrl = `https://${accountName}.blob.core.windows.net/user-data/${user.id}/profile-pic?${user.sasToken}&t=${timestamp}`;
          setNewProfilePicture(newProfilePictureUrl);
          setLoading(false);
        };

        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Image compression error:", error);
        setLoading(false);
      }
    }
  };
  const [error, setError] = useState(false);

  const handleTimezoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^([+-])(\d{2}):(\d{2})$/;

    if (!regex.test(value)) {
      setError(true);
    } else {
      setError(false);
    }

    setNewTimezone(value);
  };

  const handleSave = () => {
    const timezoneOffset =
      userUtils.convertReadableFormatToTimezoneOffset(newTimezone);
    accountService
      .update({
        metadata: {
          ...user.metadata,
          timezoneOffset: timezoneOffset.toString(),
        },
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to save changes. Please try again.");
      });
  };

  return (
    <div className="App">
      <TopAppBar />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="#f5f5f5"
        height="100vh"
      >
        <Card sx={{ maxWidth: 600, padding: 4 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            {loading ? (
              <CircularProgress />
            ) : (
              <Avatar
                alt="Profile Picture"
                src={newProfilePicture}
                sx={{ width: 150, height: 150, marginBottom: 2 }}
                onClick={() => document.getElementById("file-input")?.click()}
              />
            )}
            <input
              id="file-input"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleProfilePictureChange}
            />
          </Box>
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <TextField
                label="Timezone"
                value={newTimezone}
                onChange={handleTimezoneChange}
                margin="normal"
                error={!!error}
              />
              <Button
                sx={{ ml: 1 }}
                variant="contained"
                color="primary"
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default ProfilePage;
