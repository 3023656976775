import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";

interface MultipleChoiceProps {
  input_fields: {
    title: string;
    body?: string;
    imageUrl?: string | null;
    selectionType?: string;
    options: {
      label: string;
      imageUrl?: string;
    }[];
  };
}

const MultipleChoicePreview: React.FC<MultipleChoiceProps> = ({
  input_fields,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        overflowY: "scroll",
      }}
    >
      {/* Image */}
      {input_fields.imageUrl && (
        <Box sx={{ width: "100%", mb: 2 }}>
          <img
            src={input_fields.imageUrl}
            alt="Multiple choice"
            style={{
              width: "100%",
              height: "auto",
              objectFit: "contain",
              maxHeight: 250,
              borderRadius: 8,
            }}
          />
        </Box>
      )}
      {/* Title */}
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
        {input_fields.title}
      </Typography>
      {/* Body */}
      {input_fields.body && (
        <Typography variant="body1" sx={{ mt: 1, textAlign: "center" }}>
          {input_fields.body}
        </Typography>
      )}
      <Box sx={{ width: "100%", mt: 1 }}>
        {input_fields.options.map((option, index) => (
          <Card
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              border: "1px solid #E0E0E0",
              borderRadius: 2,
            }}
          >
            {option.imageUrl && (
              <img
                src={option.imageUrl}
                alt={option.label}
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  borderRadius: 4,
                  marginRight: "16px",
                }}
              />
            )}
            <CardContent>
              <Typography variant="body1">{option.label}</Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default MultipleChoicePreview;
