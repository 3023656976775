/** @format */

import { Box, Modal, Typography, IconButton, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { weekExerciseDetails } from "../services/exercise.service";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { log } from "util";

interface ExerciseTrackModal {
  exerciseTrackmodalOpen: boolean;
  setExerciseTrackModalOpen: (value: boolean) => void;
  currentExercise: any;
  setCurrentExercise: any;
}

export default function ExerciseTrackModal({
  exerciseTrackmodalOpen,
  setExerciseTrackModalOpen,
  currentExercise,
  setCurrentExercise,
}: ExerciseTrackModal) {
  const [heighestSet, setHighestSet] = useState<number[]>([]);

  const [currentDate, setCurrentDate] = useState(new Date());

  const [setsData, setSetData] = useState<any[]>([]);

  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  async function getExerciseResult(currentExercise: any, currentDate: any) {
    const result = await weekExerciseDetails(currentExercise, currentDate);

    setHighestSet(result.biggestArray);
    setSetData(result.formattedExercise);
    setDateRange(result.dateRange);
  }

  useEffect(() => {
    if (exerciseTrackmodalOpen) {
      let currentDate = new Date();
      setCurrentDate(currentDate);
      getExerciseResult(currentExercise, currentDate);
    }
  }, [exerciseTrackmodalOpen]);

  const handlePreviousWeek = () => {
    const prevWeek = new Date(currentDate);

    prevWeek.setDate(currentDate.getDate() - 7);

    setCurrentDate(prevWeek);
    getExerciseResult(currentExercise, prevWeek);
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(currentDate);
    nextWeek.setDate(nextWeek.getDate() + 7);
    setCurrentDate(nextWeek);
    getExerciseResult(currentExercise, nextWeek);
  };
  function closeModal() {
    setHighestSet([]);
    setDateRange({ startDate: "", endDate: "" });
    setCurrentExercise();
    setExerciseTrackModalOpen(false);
  }
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const { payload: dataPayload } = payload[0];
      const { rep, dateTime } = dataPayload;
      const setsData = Object.keys(dataPayload)
        .filter((key) => key.startsWith("set"))
        .map((key) => ({ name: key, value: dataPayload[key] }));

      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          {setsData.map((set: any) => (
            <p key={set.name}>{` Weight - ${
              set.value.weight === "Bodyweight"
                ? "Bodywight"
                : `${set.value.weight}lbs`
            }, Reps - ${set.value.rep}`}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      open={exerciseTrackmodalOpen}
      onClose={() => closeModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "70%",
          height: "80%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            alignItems: "center",
          }}
        >
          <IconButton onClick={handlePreviousWeek}>
            <NavigateBeforeIcon />
          </IconButton>

          <Typography variant="subtitle1" style={{ textAlign: "center" }}>
            {dateRange.startDate} - {dateRange.endDate}
          </Typography>
          <IconButton onClick={handleNextWeek}>
            <NavigateNextIcon />
          </IconButton>
        </div>
        {currentExercise ? (
          <div>
            <Typography
              variant="h6"
              component="h2"
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              {currentExercise.exercise}
            </Typography>
            <Typography variant="subtitle1" style={{ textAlign: "center" }}>
              Category: {currentExercise.category}
            </Typography>
          </div>
        ) : null}

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {/* Your content here */}
        </Typography>
        {setsData && setsData.length > 0 ? (
          <ResponsiveContainer width="100%" height="80%">
            <BarChart
              width={400}
              height={220}
              data={setsData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="dateTime" />
              <YAxis
                type="number"
                dataKey={"rep"}
                tickFormatter={(value) => `${value} reps`}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              {heighestSet.map((number, index) => (
                <Bar
                  key={`bar-${index}`}
                  dataKey={`set${number}.rep`}
                  fill={`rgba(${Math.random() * 255}, ${Math.random() * 255}, ${
                    Math.random() * 255
                  }, 0.6)`}
                  barSize={20} // Adjust the width of the bars
                  maxBarSize={30} // Set a maximum width for the bars
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="100%" height="80%">
            <BarChart
              width={400}
              height={220}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="rep" />
              <YAxis
                domain={[0, 20]}
                tickFormatter={(value) => `${value} reps`}
              />
              <Tooltip />
              <Legend />
              <Bar dataKey="dummy" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Modal>
  );
}
