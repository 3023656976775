import { Grid } from "@mui/material";
import SingleDescription from "./SingleDescription";
import SinglePromptSection from "./SinglePromptSection";
import { useEffect, useState } from "react";
import {
  PromptsInterface,
  SingleRecipe,
} from "../../../interface/RecipeInterface";
interface SingleSection {
  selectedEditingRecipe: SingleRecipe | null;
  setSelectedEditingRecipe: (type: SingleRecipe | null) => void;
  setRenderMiddleSection: (type: string) => void;
  setUpdateScreenName: (type: string) => void;
  updateScreenName: string;
}
//// This is the parent componet for the when navbar button is pressed as single --------------------------------------------------/
function SingleSection({
  selectedEditingRecipe,
  setSelectedEditingRecipe,
  setRenderMiddleSection,
  setUpdateScreenName,
  updateScreenName,
}: SingleSection) {
  const [singleRecipe, setSingleRecipe] = useState<SingleRecipe | null>(null);
  const [singleRecipeLoading, setSingleRecipeLoading] = useState(false);
  const [singleRecipeImageLoading, setSingleRecipeImageLoading] =
    useState(false);
  const [prompts, setPrompts] = useState<PromptsInterface>({
    gpt: "",
    dalle: "",
    recipeDetails: "",
  });

  return (
    <>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SinglePromptSection
          singleRecipe={singleRecipe}
          setSingleRecipe={setSingleRecipe}
          setSingleRecipeLoading={setSingleRecipeLoading}
          singleRecipeLoading={singleRecipeLoading}
          setSingleRecipeImageLoading={setSingleRecipeImageLoading}
          singleRecipeImageLoading={singleRecipeImageLoading}
          prompts={prompts}
          setPrompts={setPrompts}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          maxHeight: "92vh",
          overflowY: "scroll",
        }}
      >
        <SingleDescription
          singleRecipe={singleRecipe}
          setSingleRecipe={setSingleRecipe}
          setSingleRecipeLoading={setSingleRecipeLoading}
          singleRecipeLoading={singleRecipeLoading}
          setSingleRecipeImageLoading={setSingleRecipeImageLoading}
          singleRecipeImageLoading={singleRecipeImageLoading}
          prompts={prompts}
          setPrompts={setPrompts}
          selectedEditingRecipe={selectedEditingRecipe}
          setSelectedEditingRecipe={setSelectedEditingRecipe}
          setRenderMiddleSection={setRenderMiddleSection}
          setUpdateScreenName={setUpdateScreenName}
          updateScreenName={updateScreenName}
        />
      </Grid>
    </>
  );
}

export default SingleSection;
