/** @format */

import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { settingsService } from "../../services/settings.service";
import { InAppReviewInterface } from "../../interface/SubscriptionSettingsInterface";
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import * as yup from "yup";
import { da } from "date-fns/locale";

// Define a simple validation schema for the inAppReviewDetails
const inAppReviewValidationSchema = yup.object().shape({
  inAppReviewDetails: yup.array().of(
    yup.object().shape({
      order: yup.number().required("Order is required"),
      type: yup.string().required("Type is required"),
      value: yup.number().required("Value is required"),
    })
  ),
});

function InAppReview() {
  const [loading, setLoading] = useState(false);
  const [inAppReviewDetails, setInAppReviewDetails] = useState<
    InAppReviewInterface[]
  >([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  async function fetchSubscriptionSettings() {
    try {
      const response = await settingsService.getInAppReview();

      setInAppReviewDetails(response);
    } catch (error) {
      // handle error
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    fetchSubscriptionSettings();
  }, []);

  async function subscriptionUpdate(updatedDetails: InAppReviewInterface[]) {
    setSubmitLoading(true);
    try {
      const updateResponse = await settingsService.updateInAppReview(
        updatedDetails
      );
      let { message, updatedData, status } = updateResponse;
      if (status === true) {
        setInAppReviewDetails(updatedData);
        setSnackbarOpen(true);
        setSnackbarMessage(message);
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(message);
      }
    } catch (error) {
      // handle error
    }
    setSubmitLoading(false);
  }

  const handleCloseSnackbar = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      {loading ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "600px", // Adjust as needed
          }}
        >
          <div
            style={{
              margin: "20px 0",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Formik
              initialValues={{ inAppReviewDetails }}
              validationSchema={inAppReviewValidationSchema}
              onSubmit={(values, { setSubmitting }) => {
                subscriptionUpdate(values.inAppReviewDetails);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, values }) => (
                <Form style={{ width: "100%" }}>
                  <FieldArray name="inAppReviewDetails">
                    {({ push, remove }) => (
                      <>
                        <Grid container spacing={3}>
                          {values.inAppReviewDetails.map((review, index) => (
                            <Grid
                              item
                              xs={12}
                              key={index}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`inAppReviewDetails.${index}.order`}
                                  >
                                    {({ field, meta }: FieldProps<number>) => (
                                      <TextField
                                        {...field}
                                        label="Order"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        error={Boolean(
                                          meta.touched && meta.error
                                        )}
                                        helperText={
                                          meta.touched ? meta.error : ""
                                        }
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`inAppReviewDetails.${index}.type`}
                                  >
                                    {({ field, meta }: FieldProps<string>) => (
                                      <TextField
                                        {...field}
                                        label="Type"
                                        variant="outlined"
                                        fullWidth
                                        error={Boolean(
                                          meta.touched && meta.error
                                        )}
                                        helperText={
                                          meta.touched ? meta.error : ""
                                        }
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={4}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Field
                                    name={`inAppReviewDetails.${index}.value`}
                                  >
                                    {({ field, meta }: FieldProps<number>) => (
                                      <TextField
                                        {...field}
                                        label="Value"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        error={Boolean(
                                          meta.touched && meta.error
                                        )}
                                        helperText={
                                          meta.touched ? meta.error : ""
                                        }
                                      />
                                    )}
                                  </Field>
                                  <IconButton
                                    color="secondary"
                                    onClick={() => remove(index)}
                                    style={{ marginTop: 20 }}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          justifyContent="center"
                          style={{ marginTop: 20 }}
                        >
                          <Grid item>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                push({
                                  order: values.inAppReviewDetails.length + 1,
                                  type: "",
                                  value: 0,
                                })
                              }
                            >
                              <Add />
                            </IconButton>
                          </Grid>
                          <Grid item xs={12}>
                            {submitLoading ? (
                              <CircularProgress />
                            ) : (
                              <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                                style={{ marginTop: 20, width: "100%" }}
                              >
                                Submit
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </FieldArray>
                </Form>
              )}
            </Formik>
          </div>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}
            message={snackbarMessage}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            sx={{
              "& .MuiSnackbarContent-root": {
                backgroundColor: "#1976d2", // Use primary color from the theme
                color: "white", // Set text color to white
              },
            }}
          />
        </Container>
      )}
    </>
  );
}

export default InAppReview;
