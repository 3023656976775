import React, { useState } from "react";
import { Container, Typography, Grid } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Button,
  Box,
} from "@mui/material";
import PlanDetailsModal from "../modules/PlanViewModal";
import EditIcon from "@mui/icons-material/Edit";

import TopAppBar from "../modules/TopAppBar";
import { accountService } from "../services";
import { workoutPlanService } from "../services/workoutplan.service";
import { useEffect } from "react";

import EditPlanModal from "../modules/EditPlanModal";
// Define the initial state for workoutPlan
const initialWorkoutPlanState = {
  name: "",
  workouts: [],
  startDate: "", // Assuming dates are stored as strings
  endDate: "",
};
export default function Plan() {
  const [allWorkoutPlan, setAllWorkoutPlan] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingPlan, setEditingPlan] = useState<any>([]);
  const [currentPlanId, setCurrentPlanId] = useState("");
  //// Function For Opening The Modals-------------------------/
  const handleOpenModal = (plan: any) => {
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpen = () => setIsEditModalOpen(true);
  const handleClose = () => setIsEditModalOpen(false);

  function editModalOpen(plandetails: any) {
    setEditingPlan(plandetails);
    setCurrentPlanId(plandetails.id);
    handleOpen();
  }
  //// Function For fetching The all the plans----------------/
  async function fetchWorkoutPlan() {
    const userDetails = await accountService.userValue;
    setLoading(true);
    try {
      const workoutPlanResponse = await workoutPlanService.getWorkoutPlan(
        userDetails.id
      );

      if ((workoutPlanResponse.data = true)) {
        setAllWorkoutPlan(workoutPlanResponse.workoutData);
      } else {
        setAllWorkoutPlan(initialWorkoutPlanState);
      }
    } catch (error) {
      setAllWorkoutPlan(initialWorkoutPlanState);
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchWorkoutPlan();
  }, []);

  const [open, setOpen] = useState<{ [key: string]: boolean }>({});

  const handleToggle = (planId: string) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [planId]: !prevOpen[planId],
    }));
  };

  return (
    <Grid container direction="column" style={{ height: "100vh" }}>
      <Grid item>
        <TopAppBar />
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        style={{ flexGrow: 1, overflow: "hidden" }} // Hide overflow here
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              width: "90%",
              maxWidth: "none",
              margin: "auto",
              marginTop: 2,
              maxHeight: "70vh", // Set a maximum height
              overflowY: "auto", // Enable vertical scrolling
            }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Plan Name</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell style={{ textAlign: "center" }}>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allWorkoutPlan.map((plan: any) => (
                  <TableRow key={plan.id}>
                    <TableCell>{plan.name}</TableCell>
                    <TableCell>{plan.userName}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => editModalOpen(plan)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Button onClick={() => handleOpenModal(plan)}>
                        View Plan
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <PlanDetailsModal
        open={isModalOpen}
        onClose={handleCloseModal}
        plan={selectedPlan}
      />
      <EditPlanModal
        isEditModalOpen={isEditModalOpen}
        handleClose={handleClose}
        editingPlan={editingPlan}
        planId={currentPlanId}
        allWorkoutPlan={allWorkoutPlan}
        setAllWorkoutPlan={setAllWorkoutPlan}
      />
    </Grid>
  );
}
