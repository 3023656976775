/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { accountService } from "../services";
import { userService } from "../services/userdetails.service";
import { SelectedUserInterface } from "../interface/User";

interface BasicInfoModalProps {
  subscriptionModalOpen: boolean;
  onClose: () => void;
  selectedUser: SelectedUserInterface | null;
}

interface ReceiptResponse {
  expireDate?: string;
  productType?: string;
  purchaseTime?: string;
  transactionId?: string;
  status: string;
  productId: string;
  receipt: any;
}
interface FreePremiumOverInterface {
  startDate: string;
  endDate: string;
  duration: number;
  type: string;
}
const SubscriptionShowingModal: React.FC<BasicInfoModalProps> = ({
  subscriptionModalOpen,
  onClose,
  selectedUser,
}) => {
  const [receiptResponse, setReceiptResponse] =
    useState<ReceiptResponse | null>(null);
  const [freePremiumOverDate, setFreePremiumOverData] =
    useState<FreePremiumOverInterface | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchReceiptData = async () => {
    setIsLoading(true); // Start loading
    try {
      if (selectedUser && selectedUser.subscription) {
        const loginUser = accountService.userValue;
        /// Make the call to get the total data of the user ---------------------------------------/
        // const response = await userService.getTotalData(
        //   selectedUser._id,
        //   loginUser.role
        // );

        // let userData = response.data;

        // if (
        //   userData.subscription &&
        //   userData.subscription.productId === "free_premium"
        // ) {
        //   setIsLoading(false); // Start loading

        //   setReceiptResponse(userData.subscription);
        // } else {
        // if (
        //   userData &&
        //   userData.metadata &&
        //   userData.subscription &&
        //   userData.subscription.receipt
        // ) {
        //   let platform = "";
        //   let receiptData = {
        //     platform: "",
        //     receiptData: "",
        //     productId: "",
        //     packageName: "",
        //     subscriptionId: "",
        //   };
        //   const isIOS =
        //     userData.subscription["receipt"]["packageName"] == null;
        //   if (isIOS) {
        //     platform = "apple";
        //     receiptData = {
        //       ...receiptData,
        //       platform,
        //       receiptData: userData.subscription.receipt.latestReceipt,
        //     };
        //   } else {
        //     platform = "android";

        //     receiptData = {
        //       ...receiptData,
        //       platform,
        //       receiptData: userData.subscription.receipt.purchaseToken,
        //       productId: userData.subscription.receipt.productId,
        //       packageName: userData.subscription.receipt.packageName,
        //       subscriptionId: userData.subscription.productId,
        //     };
        //   }

        try {
          const response = await accountService.getSubscriptionDetails(
            selectedUser._id
          );
          console.log("this is response");
          console.log(response);

          if (response.length > 0) {
            setReceiptResponse(response[0]);
          } else {
            setReceiptResponse(null);
          }
          setIsLoading(false); // End loading
        } catch (error) {
          console.error("Failed to fetch receipt data:", error);
          setReceiptResponse(null);
          setIsLoading(false); // End loading
        }
        // } else {
        //   setReceiptResponse(null);
        //   setIsLoading(false); // Start loading
        // }
        //  }
      } else {
        if (selectedUser && selectedUser.freePremiumOver) {
          setIsLoading(false); // Start loading
          setFreePremiumOverData(selectedUser.introOffer);
        } else {
          setIsLoading(false); // Start loading
          setReceiptResponse(null);
        }
      }
    } catch (error) {
      console.log(error);

      setIsLoading(false); // Start loading
    }
  };
  useEffect(() => {
    if (subscriptionModalOpen) {
      fetchReceiptData();
    }
  }, [selectedUser]);

  function handleClose() {
    onClose();
  }
  function parseCustomDate(dateString: any) {
    try {
      // Assuming dateString is "2024-03-26 17:32:28 Etc/GMT"
      // Convert to a format the Date constructor will understand: "2024-03-26T17:32:28Z"
      const isoString = dateString?.replace(" ", "T").replace(" Etc/GMT", "Z");

      // Create a Date object using the modified string
      const date = new Date(isoString);

      // Optionally, adjust the displayed date/time to the user's local timezone
      // or format it as needed
      return date.toLocaleString(); // Converts to local timezone and formats as a string
    } catch (error) {
      return null;
    }
  }

  return (
    <Modal open={subscriptionModalOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflow: "auto",
          maxHeight: "80vh",
        }}
      >
        <IconButton
          onClick={() => handleClose()}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>

        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : receiptResponse ? (
          <>
            {receiptResponse.productId === "free_premium" ? (
              <>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  <span style={{ fontWeight: "bold" }}>No of Days: </span>
                  {receiptResponse.receipt.noOfDays || "Not Available"}
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  <span style={{ fontWeight: "bold" }}>Start Date: </span>
                  {receiptResponse.receipt.startDate || "Not Available"}
                </Typography>
              </>
            ) : (
              <>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  <span style={{ fontWeight: "bold" }}>Transaction Id: </span>
                  {receiptResponse.transactionId || "Not Available"}
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  <span style={{ fontWeight: "bold" }}>Product Type: </span>
                  {receiptResponse.productType || "Not Available"}
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  <span style={{ fontWeight: "bold" }}>Purchase Time: </span>
                  {receiptResponse.purchaseTime
                    ? parseCustomDate(receiptResponse.purchaseTime)
                    : "Not Available"}
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  <span style={{ fontWeight: "bold" }}>Expire Date: </span>
                  {receiptResponse.expireDate
                    ? parseCustomDate(receiptResponse.expireDate)
                    : "Not Available"}
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  <span style={{ fontWeight: "bold" }}>Status: </span>
                  {receiptResponse.status || "Not Available"}
                </Typography>
              </>
            )}
          </>
        ) : freePremiumOverDate && selectedUser?.introOffer ? (
          <>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              <span style={{ fontWeight: "bold" }}>Type: </span>
              {freePremiumOverDate.type || "Not Available"}
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              <span style={{ fontWeight: "bold" }}>Duration: </span>
              {freePremiumOverDate.duration || "Not Available"} days
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              <span style={{ fontWeight: "bold" }}>Start Date: </span>
              {freePremiumOverDate.startDate || "Not Available"}
            </Typography>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              <span style={{ fontWeight: "bold" }}>End Date: </span>
              {freePremiumOverDate.endDate || "Not Available"}
            </Typography>
          </>
        ) : (
          <Typography sx={{ mt: 2 }}>
            Subscription Data: Not Available
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default SubscriptionShowingModal;
