/** @format */

import { CircularProgress, Container, Paper, Typography } from "@mui/material";

import { useEffect, useState } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { stepsUtilsFunctions } from "../../utils/Steps";
import { StepsByDateInterface } from "../../interface/Steps";

export default function StepsByDate({
  selectedUser,
  steps,

  currentDate,
  setCurrentDate,
}: StepsByDateInterface) {
  const [currentStepsData, setCurrentStepsData] = useState({
    steps: 0,
    date: "",
  });

  ///// Function for calling the utils function of steps------------------/
  const getCurrentDaySteps = async (stepsData: any, currentDate: Date) => {
    const stepResponse = await stepsUtilsFunctions.getSteps(
      stepsData,
      currentDate
    );
    setCurrentStepsData(stepResponse);
  };
  const increaseDate = async () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);
    setCurrentDate(newDate);
    getCurrentDaySteps(steps, newDate);
  };
  const decreaseDate = async () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 1);
    setCurrentDate(newDate);
    getCurrentDaySteps(steps, newDate);
  };

  useEffect(() => {
    if (steps.length > 0) {
      getCurrentDaySteps(steps, currentDate);
    } else {
      setCurrentStepsData({ steps: 0, date: "" });
    }
  }, [steps, selectedUser]);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 2,
        }}
      >
        <ArrowBackIosIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            decreaseDate();
          }}
        />
        <Typography style={{ textAlign: "center", fontWeight: "800" }}>
          {currentDate.toDateString()}
        </Typography>
        <ArrowForwardIosIcon
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (currentDate.getDate() === new Date().getDate()) {
              return;
            } else {
              increaseDate();
            }
          }}
        />
      </Container>

      <>
        {currentStepsData && currentStepsData.steps !== 0 ? (
          <Container>
            <Paper
              elevation={3}
              style={{
                padding: "10px",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "20px",

                marginTop: 50,
                width: "90%",
              }}
            >
              <Container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "95%",
                }}
              >
                <DirectionsWalkIcon />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AccessTimeIcon style={{ fontSize: 20 }} />
                  <Typography>{currentStepsData.date}</Typography>
                </div>
              </Container>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "95%",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Typography variant="body1">Steps Count :</Typography>

                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  {currentStepsData.steps} steps
                </Typography>
              </Container>
            </Paper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography style={{ marginTop: 50 }}>
              No Steps available
            </Typography>
          </Container>
        )}
      </>
    </>
  );
}
