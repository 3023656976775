import { CardContent, Typography } from "@mui/material";

//// Main funciton for the User meta details -------------------------------------------/
function UserDeviceInfo({ selectedUser }: any) {
  return (
    <CardContent>
      <Typography sx={{ fontSize: 20 }} color="#000" gutterBottom>
        Device Info
      </Typography>
      {selectedUser?.metadata && (
        <>
          <Typography>{selectedUser.metadata.os}</Typography>
          <Typography>{selectedUser.metadata.device}</Typography>
          <Typography>
            Contact no: {selectedUser.contactNumber || ""}
          </Typography>
          <Typography>
            App Version - {selectedUser.metadata.appVersion}
          </Typography>
          <Typography>
            {selectedUser.metadata.notificationPermission
              ? "Notifications Enabled"
              : "Notifications Disabled"}
          </Typography>
          {selectedUser.metadata.country && (
            <Typography>Country - {selectedUser.metadata.country}</Typography>
          )}
          {selectedUser.metadata.city && (
            <Typography>City - {selectedUser.metadata.city}</Typography>
          )}
        </>
      )}
    </CardContent>
  );
}

export default UserDeviceInfo;
