/** @format */

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { memo } from "react";
import { StepsBrowseDataInterface } from "../../interface/Steps";
import { StepsDataInterface } from "../../interface/Steps";

function StepsBrowseData({ stepArray }: StepsBrowseDataInterface) {
  return (
    <TableContainer
      component={Paper}
      sx={{ marginTop: 2, height: "65vh", overflowX: "hidden" }}
    >
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                width: "50%",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              Date Time
            </TableCell>
            <TableCell
              style={{
                width: "50%",
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              Steps
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stepArray?.map((row: any, index: any) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ width: "50%", textAlign: "center" }}
              >
                {row.dateTime}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ width: "50%", textAlign: "center" }}
              >
                {row.steps}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(StepsBrowseData);
