import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { individualMealCalculation } from "../services/userdetails.service";
interface Ingredient {
  name: string;
  quantity: number;
  calories: number;
  protein: number;
  dateTime?: string; // Add this line
  unit?: string;
}

//// Function For Showing the ingredients details when dropdown arrow is clicked------------/
const IngredientRow: React.FC<{ ingredient: Ingredient }> = ({
  ingredient,
}) => (
  <TableRow>
    <TableCell component="th" scope="row">
      {ingredient.name}
    </TableCell>
    <TableCell>
      {ingredient.quantity > 0
        ? `${ingredient.quantity} ${
            ingredient.unit === "g" ? "grams" : ingredient.unit
          }`
        : "-"}{" "}
    </TableCell>
    <TableCell align="right">{ingredient.calories}</TableCell>
    <TableCell align="right">{ingredient.protein}</TableCell>
  </TableRow>
);

//// Function For The when meal has ingredients----------------------------------------------/
const CollapsibleIngredients: React.FC<{
  open: boolean;
  ingredients: Ingredient[];
}> = ({ open, ingredients }) => {
  const ingredientRef = React.useRef<HTMLTableRowElement>(null);

  React.useEffect(() => {
    if (open && ingredientRef.current) {
      ingredientRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [open]);

  return (
    <TableRow ref={ingredientRef}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography variant="h6" gutterBottom component="div">
              Ingredients
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell align="right">Calories</TableCell>
                  <TableCell align="right">Protein</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ingredients.map((ingredient, index) => (
                  <IngredientRow key={index} ingredient={ingredient} />
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};


//// Main Function For The Meal Details----------------------------------------------------------/
export default function MealDetails({ row, setImageUrl, setImageModal }: any) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({
    totalCalorie: 0,
    totalProtein: 0,
    totalQuantity: 0,
  });
  const [imageError, setImageError] = React.useState(false);

  async function mealCalculation() {
    const res = await individualMealCalculation(row?.ingredients);
    setData(res);
  }
  React.useEffect(() => {
    mealCalculation();
  }, []);

  const isMeal = row.mealName !== undefined;
  const hasIngredients = row.ingredients && row.ingredients.length > 0;

  const handleError = (e: any) => {
    setImageError(true);
    e.target.src =
      "https://p.kindpng.com/picc/s/79-798754_hoteles-y-centros-vacacionales-dish-placeholder-hd-png.png"; // Path to your placeholder image
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {hasIngredients && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          <img
            onClick={() => {
              if (imageError) return;
              setImageUrl(row.image);
              setImageModal(true);
            }}
            src={row.image}
            height={60}
            onError={handleError}
            alt="image"
          />
        </TableCell>
        <TableCell component="th" scope="row">
          {row.mealName || row.name}
        </TableCell>
        <TableCell align="right">{row.calorie || data?.totalCalorie}</TableCell>
        <TableCell align="right">{row.protein || data.totalProtein.toFixed(1)}</TableCell>
        <TableCell align="right">
          {row.quantity > 0
            ? `${row.quantity} ${row.units === "g" ? "grams" : row.units}`
            : "-"}{" "}
        </TableCell>

        <TableCell align="right">
          {row.dateTime ||
            (row.ingredients && row.ingredients.length > 0
              ? row.ingredients.length > 1
                ? row.ingredients[1].dateTime
                  ? row.ingredients[1].dateTime
                  : null
                : row.ingredients[0].dateTime
                ? row.ingredients[0].dateTime
                : null
              : null)}
        </TableCell>
      </TableRow>
      {hasIngredients && (
        <CollapsibleIngredients
          open={open}
          ingredients={row.ingredients || []}
        />
      )}
    </React.Fragment>
  );
}
