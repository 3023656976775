import { accountService } from "../services";

const createFileName = (extension: any) => {
  const now = new Date();
  return `${now.getTime()}-file.${extension}`;
};

// Replace this with your actual file upload logic
const uploadFileInAzure = async (file: any, fileName: any, role: string) => {
  try {
    const user = accountService.userValue;

    let sasToken = user.sasToken;
    let fileType = "";

    if (role === "admin") {
      fileType += "support-chat-files";
    } else {
      fileType += "trainer-chat-files";
    }
    const storageNameResponse = await accountService.getStorageName();
    const containerUrl = `https://${storageNameResponse.storageName}.blob.core.windows.net/user-data/${user.id}/${fileType}/${fileName}?${sasToken}`;

    const response = await fetch(containerUrl, {
      method: "PUT",
      body: file, // Directly sending the file object
      headers: {
        "x-ms-blob-type": "BlockBlob",
      },
    });

    if (response.status === 201) {
      return response.url; // URL of the uploaded file
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

export async function uploadFile(file: any, userId: any, role: string) {
  try {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const fileName = createFileName(fileExtension);

    const uploadedUrl = await uploadFileInAzure(file, fileName, role);

    if (uploadedUrl) {
      return uploadedUrl;
    }
  } catch (error) {
    return { uploadedUrl: "", success: false };
  }
}

/// Function For showing images on the support chat in a proper way
export function chunkArray(array: any, size: any) {
  return array.reduce((acc: any, val: any, i: any) => {
    let idx = Math.floor(i / size);
    let page = acc[idx] || (acc[idx] = []);
    page.push(val);
    return acc;
  }, []);
}

// Function to check if the URL is a video
export function isVideoUrl(url: any) {
  const mp4Index = url.indexOf(".mp4");
  const queryIndex = url.indexOf("?");
  return mp4Index > -1 && (queryIndex === -1 || mp4Index < queryIndex);
}
