import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";

interface FreeTextProps {
  input_fields: {
    title: string;
    body?: string;
    imageUrl?: string | null;
    inputBoxPlaceholder?: string;
  };
}

const FreeTextPreview: React.FC<FreeTextProps> = ({ input_fields }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        padding: 1,
        borderRadius: 2,
        boxShadow: 3,
        overflowY: "scroll",
      }}
    >
      {/* Image */}
      {input_fields.imageUrl && (
        <img
          src={input_fields.imageUrl}
          alt="Free text"
          style={{
            width: "70%",
            maxHeight: 120,
            objectFit: "cover",
            borderRadius: 8,
            marginTop: "16px",
          }}
        />
      )}
      {/* Title */}
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
        {input_fields.title}
      </Typography>

      {/* Body */}
      {input_fields.body && (
        <Typography variant="body1" sx={{ mt: 1, textAlign: "center" }}>
          {input_fields.body}
        </Typography>
      )}
      {/* Free text input */}
      <TextField
        fullWidth
        placeholder={
          input_fields.inputBoxPlaceholder || "Enter your feeling..."
        }
        multiline
        rows={3}
        variant="outlined"
        sx={{ mt: 2, width: "100%" }}
      />

      {/* Submit Button */}
      <Button
        variant="contained"
        sx={{ mt: 2, backgroundColor: "#1E88E5", width: "100%" }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default FreeTextPreview;
