import {
  Box,
  Card,
  CardContent,
  Collapse,
  Container,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  RecipeCardInterface,
  SingleRecipe,
} from "../../../interface/RecipeInterface";
import { multiRecipeUtils } from "../../../utils/RecipePage/MultiRecipe";
import {
  ExpandMore,
  ExpandLess,
  Try,
  Edit as EditIcon,
  EditNote as EditNoteIcon,
} from "@mui/icons-material";
import { userUtils } from "../../../utils/userUtils";
import CardImageModal from "../CardImageModal";
import EditRecipeModal from "../EditRecipeModal";

interface Ingredient {
  name: string;
  calories: number;
  protein: number;
  carbs: number;
  fats: number;
  added_sugar: number;
  qty: number;
  unit: string;
}

const nutrientLabels = [
  { key: "protein", label: "Protein", unit: "g" },
  { key: "carbs", label: "Carbs", unit: "g" },
  { key: "fats", label: "Fat", unit: "g" },
  { key: "calories", label: "Calories", unit: "kcal" },
  { key: "fiber", label: "Fiber", unit: "g" },
  { key: "added_sugar", label: "Sugar", unit: "" },
];

///// Main function for the SavedRecipe -------------------------------------------------------------------------------/
function RecipeCard({
  setUpdateScreenName,
  setRenderMiddleSection,
  setSelectedEditingRecipe,
  recipe,

  selectedRecipes,

  handleSelectRecipe,

  index,
  fetchSavedRecipes,
}: RecipeCardInterface) {
  const [storageName, setStorageName] = useState(null);
  const [recipeId, setRecipeId] = useState("");
  const [recipeImageModalOpen, setRecipeImageModalOpen] = useState(false); /// State for the recipe image modal -------------------/
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentEditingRecipe, setCurrentEditingRecipe] =
    useState<SingleRecipe | null>(null); /// State for storing current edit recipe details ----------------------/
  const [openId, setOpenId] = useState<number | null>(null); /// State for opening up the ingredients dropdown----/
  const [savedRecipeLoading, setSavedRecipeLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //// Funciton for closing the snackbar message pop up -------------------------------------------/
  const handleCloseSnackbar = useCallback(() => {
    setSnackbarOpen(false);
  }, []);

  /// Funciton for opening up the handleClick of ingredients dropdown -----------------------------/
  const handleClick = (id: number) => {
    setOpenId((prev) => (prev === id ? null : id));
  };

  //// Funciton for opening the manual edit modal ------------------------------------------------/
  const openEditModal = (recipe: SingleRecipe) => {
    setCurrentEditingRecipe(recipe);
    setEditModalOpen(true);
  };

  /// Funciton for closing the recipe edit modal -------------------------------------------------/

  const onEditManualClose = useCallback(() => {
    setEditModalOpen(false);
  }, []);

  /// Funciton for closing the recipe image modal ------------------------------------------------/
  const closeRecipeImageModal = () => {
    setRecipeImageModalOpen(false);
  };

  //// Fetching the storage account name ----------------------------------------------------------/
  useEffect(() => {
    const fetchStorageName = async () => {
      //////// Fetching the storageaccountname ------------------------------------------/
      const storageName = await userUtils.getStorageName();
      setStorageName(storageName);
    };

    fetchStorageName();
  }, []);

  /// Function for opening the image of the recipe ------------------------------------------------/
  const openImage = useCallback((recipeId: string) => {
    setRecipeId(recipeId);
    setRecipeImageModalOpen(true);
  }, []);

  const editRecipe = useCallback(
    async (recipe: SingleRecipe) => {
      //////// Fetching the storageaccountname ------------------------------------------/
      const storageName = await userUtils.getStorageName();
      setUpdateScreenName("saved");
      setRenderMiddleSection("single");
      setSelectedEditingRecipe({
        ...recipe,
        image: `https://${storageName}.blob.core.windows.net/reshape-public/recipes/images/saved/${recipe._id}.png`,
      });
    },
    [setRenderMiddleSection, setSelectedEditingRecipe, setUpdateScreenName]
  );

  return (
    <>
      {storageName ? (
        <div key={recipe._id} style={{ width: "70%", marginLeft: 70 }}>
          <Card
            sx={{
              borderRadius: "10px",
              boxShadow: "0 12px 24px rgba(0,0,0,0.3)",
              marginBottom: 2,
              zIndex: 2,
              height: 480,
              overflowY: "scroll",
              backgroundColor: "#f5f5f5",
            }}
          >
            <CardContent sx={{ padding: 2, borderRadius: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "20%" }}>
                  <img
                    src={`https://${storageName}.blob.core.windows.net/reshape-public/recipes/images/saved/${
                      recipe._id
                    }.png?buster=${Date.now()}`}
                    alt="Recipe"
                    style={{
                      width: "100%",
                      borderRadius: 4,
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onError={(e) => {
                      console.log("Image failed to load, using placeholder");
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                    }}
                    loading="lazy"
                    onClick={() => openImage(recipe._id)}
                  />
                </Box>
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "70%",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={{
                      textAlign: "start",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                  >
                    {recipe.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "start", width: "100%" }}
                  >
                    {recipe.description}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "start", width: "100%" }}
                  >
                    Duration: {recipe.duration}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "start", width: "100%" }}
                  >
                    Categories:{" "}
                    {recipe.categories && recipe.categories.length > 0
                      ? recipe.categories.map((category: any, i: number) => (
                          <React.Fragment key={i}>
                            {category}
                            {i !== recipe.categories.length - 1 && ", "}
                          </React.Fragment>
                        ))
                      : "No categories available"}
                  </Typography>
                </Container>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <EditNoteIcon
                    color="primary"
                    sx={{
                      marginBottom: 3,
                      cursor: "pointer",
                      fontSize: 30,
                    }}
                    onClick={() => editRecipe(recipe)}
                  />
                  <input
                    type="checkbox"
                    checked={selectedRecipes.some(
                      (item: any) => item._id === recipe._id
                    )}
                    onChange={() => handleSelectRecipe(recipe)}
                    style={{ transform: "scale(1.5)" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 2,
                      cursor: "pointer",
                    }}
                    onClick={() => openEditModal(recipe)}
                  >
                    <EditIcon
                      fontSize="small"
                      color="primary"
                      sx={{ cursor: "pointer" }}
                    />
                    <Typography>Manual</Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: 2,
                }}
              >
                {nutrientLabels.map((nutrient) => (
                  <Card
                    key={nutrient.key}
                    sx={{
                      padding: 2,
                      margin: 1,
                      boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                      backgroundColor: "#e3f2fd",
                      width: "22%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        "@media (max-width: 1290px)": {
                          fontSize: 10,
                        },
                      }}
                    >
                      {nutrient.label}: {recipe[nutrient.key]}
                      {nutrient.unit}
                    </Typography>
                  </Card>
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex",
                  marginTop: 2,
                }}
              >
                <Typography variant="h6">
                  Direction:{" "}
                  <span style={{ fontSize: 15 }}>{recipe.direction}</span>
                </Typography>
              </Box>
              <Typography
                variant="h6"
                sx={{ marginTop: 2, cursor: "pointer" }}
                onClick={() => handleClick(index)}
              >
                Ingredients {openId === index ? <ExpandLess /> : <ExpandMore />}
              </Typography>
              <Collapse in={openId === index} timeout="auto" unmountOnExit>
                <List>
                  {recipe.ingredients.map((ingredient: any, index: any) => (
                    <ListItem
                      key={index}
                      sx={{
                        width: "100%",
                        backgroundColor: "#f5f5f5",
                        marginBottom: 1,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Box
                            sx={{
                              marginBottom: 2,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography sx={{ fontWeight: "bold" }}>
                                {ingredient.name}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                flexWrap: "wrap",
                              }}
                            >
                              {Object.entries(ingredient)
                                .filter(([key]) =>
                                  [
                                    "calories",
                                    "protein",
                                    "carbs",
                                    "fats",
                                    "added_sugar",
                                    "qty",
                                    "unit",
                                  ].includes(key)
                                )
                                .map(([key, value]) => (
                                  <Card
                                    key={key}
                                    sx={{
                                      padding: 1,
                                      boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                                      backgroundColor: "#e3f2fd",
                                      textAlign: "center",
                                      width: 60,
                                      marginTop: 1,
                                      minWidth: 45,
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: "500",
                                        fontSize: 10,
                                      }}
                                    >
                                      {key.charAt(0).toUpperCase() +
                                        key.slice(1)}
                                      :
                                    </Typography>
                                    <Typography variant="body2">
                                      {multiRecipeUtils.formatIngredientValue(
                                        value
                                      )}
                                    </Typography>
                                  </Card>
                                ))}
                            </Box>
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </CardContent>
          </Card>
        </div>
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
      <CardImageModal
        recipeImageModalOpen={recipeImageModalOpen}
        closeRecipeImageModal={closeRecipeImageModal}
        recipeId={recipeId}
        setRecipeImageModalOpen={setRecipeImageModalOpen}
        recipeType={"saved"}
        storageName={storageName}
      />

      <EditRecipeModal
        editManualRecipeOpen={editModalOpen}
        onEditManualClose={onEditManualClose}
        recipe={currentEditingRecipe}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarOpen={setSnackbarOpen}
        fetchSavedRecipes={fetchSavedRecipes}
        dbType="saved"
      />
    </>
  );
}

export default RecipeCard;
