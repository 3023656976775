/** @format */

import * as React from "react";

import Typography from "@mui/material/Typography";
import { Button, Container, MenuItem, CircularProgress } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import UserActivity from "./UserMeal";
import WorkoutHistory from "./WorkoutHistory";
import "../css/dashboard.css";
import { getAllMeals, userService } from "../services/userdetails.service";
import StepsBrowseData from "../Components/BrowseData/StepsBrowseData";
import WaterBrowseData from "../Components/BrowseData/WaterBrowseData";
import WeightBrowseData from "../Components/BrowseData/WeightBrowseData";
import SleepBrowseData from "../Components/BrowseData/SleepBrowseData";
import { PhysicalDataInterface } from "../interface/PhysicalDataInterface";
import { resetPhysicalData } from "../utils/PhysicalData";
import { OwnWorkoutInterface } from "../interface/OwnCreatedWorkoutInterface";
import { workoutStrucutre } from "../interface/OwnCreatedWorkoutInterface";
import { accountService } from "../services";
function BrowseData({ selectedUser }: any) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [mealData, setMealData] = React.useState<any[]>([]);
  const [workout, setWorkout] = React.useState<OwnWorkoutInterface[]>([]);

  const [open, setOpen] = React.useState("steps");
  const requestTimeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const [data, setData] = React.useState<PhysicalDataInterface | undefined>(); // State to store the fetched data

  //// Function For calling the api service function--------------------------/
  const fetchData = async () => {
    try {
      const physicalActivityResponse =
        await accountService.getUserPhysicalActivity(selectedUser?._id);

      // Handle errors from the physical activity fetch
      if (physicalActivityResponse.error) {
        let initialPhysicalData = resetPhysicalData();
        setData(initialPhysicalData);
      } else {
        setData(physicalActivityResponse);
      }

      // Fetch meals
      const mealsResponse = await getAllMeals(selectedUser?._id);

      // Sorting the result array based on the dateTime property
      const sortedResult = mealsResponse.sort(
        (a, b) =>
          new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
      );
      // Fetch workout history
      const workoutHistoryResponse = await userService.getWorkoutHistory(
        selectedUser._id
      );

      const filteredWorkouts = workoutHistoryResponse.reduce(
        (acc: workoutStrucutre, curr: OwnWorkoutInterface) => {
          if (curr.planName !== null) {
            acc.trainerWorkout.push(curr);
          } else {
            acc.workout.push(curr);
          }
          return acc;
        },
        { workout: [], trainerWorkout: [] }
      );

      // Update state with meals and workout history
      setMealData(sortedResult);
      setWorkout(filteredWorkouts);
    } catch (error) {
      let initialPhysicalData = resetPhysicalData();
      setData(initialPhysicalData);
      setMealData([]);
      setWorkout([]);
    }
  };

  React.useEffect(() => {
    let initialPhysicalData = resetPhysicalData();
    setData(initialPhysicalData);
    // Clears the existing timeout if the effect is called again within 1 second
    if (requestTimeoutRef.current) {
      clearTimeout(requestTimeoutRef.current);
    }
    setLoading(true);
    requestTimeoutRef.current = setTimeout(() => {
      if (selectedUser._id !== "") {
        fetchData().finally(() => {
          // Turn off the loading indicator once the operation is complete
          setLoading(false);
        });
      }
    }, 1000); // Sets a 1-second delay for the operation

    // Cleanup function to clear the timeout when the component unmounts or before the effect runs again
    return () => {
      if (requestTimeoutRef.current) {
        clearTimeout(requestTimeoutRef.current);
      }
    };
  }, [selectedUser]);

  return (
    <>
      <Container className="mainContainer">
        <div className="toggleButtonGroupContainer">
          <ToggleButtonGroup
            value={open}
            exclusive
            onChange={(event, newValue) => setOpen(newValue)}
            aria-label="data type"
          >
            <ToggleButton
              value="steps"
              sx={{
                padding: "5px", // Default padding
                "@media screen and (max-width: 1202px)": {
                  padding: "1px", // Padding for screens smaller than 1202px
                  fontSize: "11px",
                },
                "&.Mui-selected": { backgroundColor: "#f2f2f2" },
              }}
            >
              Steps
            </ToggleButton>
            <ToggleButton
              value="water"
              sx={{
                padding: "5px", // Default padding
                "@media screen and (max-width: 1202px)": {
                  padding: "1px", // Padding for screens smaller than 1202px
                  fontSize: "11px",
                },
                "&.Mui-selected": { backgroundColor: "#f2f2f2" },
              }}
            >
              Water
            </ToggleButton>
            <ToggleButton
              value="workout"
              sx={{
                padding: "5px", // Default padding
                "@media screen and (max-width: 1202px)": {
                  padding: "1px", // Padding for screens smaller than 1202px
                  fontSize: "11px",
                },
                "&.Mui-selected": { backgroundColor: "#f2f2f2" },
              }}
            >
              Workouts
            </ToggleButton>
            <ToggleButton
              value="weight"
              sx={{
                padding: "5px", // Default padding
                "@media screen and (max-width: 1202px)": {
                  padding: "1px", // Padding for screens smaller than 1202px
                  fontSize: "11px",
                },
                "&.Mui-selected": { backgroundColor: "#f2f2f2" },
              }}
            >
              Weight
            </ToggleButton>
            <ToggleButton
              value="sleep"
              sx={{
                padding: "5px", // Default padding
                "@media screen and (max-width: 1202px)": {
                  padding: "1px", // Padding for screens smaller than 1202px
                  fontSize: "11px",
                },
                "&.Mui-selected": { backgroundColor: "#f2f2f2" },
              }}
            >
              Sleep
            </ToggleButton>
            <ToggleButton
              value="meal"
              sx={{
                padding: "5px", // Default padding
                "@media screen and (max-width: 1202px)": {
                  padding: "1px", // Padding for screens smaller than 1202px
                  fontSize: "11px",
                },
                "&.Mui-selected": { backgroundColor: "#f2f2f2" },
              }}
            >
              Meal
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Container>
      {loading && data ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress style={{ marginTop: 10 }} />
        </Container>
      ) : (
        <>
          {open === "steps" && (
            <>
              {data && data.steps && data.steps.length > 0 ? (
                <StepsBrowseData stepArray={data.steps} />
              ) : (
                <Container>
                  <Typography
                    style={{
                      marginTop: 40,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    No Steps Data available
                  </Typography>
                </Container>
              )}
            </>
          )}
          {open === "water" && (
            <>
              {data && data.water && data.water.length > 0 ? (
                <WaterBrowseData waterArray={data.water} />
              ) : (
                <Container>
                  <Typography
                    style={{
                      marginTop: 40,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    No Water Data available
                  </Typography>
                </Container>
              )}
            </>
          )}

          {open === "weight" && (
            <>
              {data && data.weights && data.weights.length > 0 ? (
                <WeightBrowseData weightArray={data.weights} />
              ) : (
                <Container>
                  <Typography
                    style={{
                      marginTop: 40,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    No Weight Data available
                  </Typography>
                </Container>
              )}
            </>
          )}
          {open === "sleep" && (
            <>
              {data && data.sleep && data.sleep.length > 0 ? (
                <SleepBrowseData sleepArray={data.sleep} />
              ) : (
                <Container>
                  <Typography
                    style={{
                      marginTop: 40,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    No Sleep Data available
                  </Typography>
                </Container>
              )}
            </>
          )}
          {open === "meal" && (
            <UserActivity loading={loading} mealData={mealData} />
          )}
          {open === "workout" && (
            <WorkoutHistory
              selectedUser={selectedUser}
              loading={loading}
              workout={workout}
            />
          )}
        </>
      )}
    </>
  );
}

export default BrowseData;
