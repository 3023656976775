/** @format */

//// Function related to nutrition log---------------------------------/

export const nutritionLogUtilsFunction = {
  filterNutritionLogs,
};

/// Sorting The data based on the arrow key type-----------------/
async function sortNutritionLogs(
  nutritionLogDetails: any,
  createdAtSortOrder: string
) {
  // Toggle the sorting order between "asc" and "desc"
  const newSortOrder = createdAtSortOrder;

  // Sort the nutritionLogDetails array based on the new sorting order
  const sortedDetails = [...nutritionLogDetails].sort((a: any, b: any) => {
    // Check if either of the objects has no 'createdAt' field
    if (!a.createdAt || !b.createdAt) {
      // Put the object without 'createdAt' field on top when sorting in ascending order
      if (newSortOrder === "asc") {
        return !a.createdAt ? -1 : 1;
      }
      // Put the object without 'createdAt' field at the bottom when sorting in descending order
      else {
        return !a.createdAt ? 1 : -1;
      }
    }

    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();

    // For descending order: latest createdAt first, earliest createdAt last
    return newSortOrder === "desc" ? dateB - dateA : dateA - dateB;
  });
  return sortedDetails;
}

/// Function for filtering the nutitionlogs---------------------/
async function filterNutritionLogs(
  nutritionData: any,
  filteredType: any,
  filteredStatus: any,
  createdAtSortOrder: string
) {
  // Assuming setTotalNutritionLogDetails should always store the complete fetched details regardless of filtering

  if (
    filteredType != "" ||
    filteredStatus != "" ||
    createdAtSortOrder === "asc"
  ) {
    let filteredNutritionLogDetails = nutritionData;

    // Filter by type if filteredType is not empty
    if (filteredType !== "") {
      filteredNutritionLogDetails = filteredNutritionLogDetails.filter(
        (log: any) => log.type === filteredType
      );
    }

    // Filter by status if filteredStatus is not empty
    if (filteredStatus !== "") {
      filteredNutritionLogDetails = filteredNutritionLogDetails.filter(
        (log: any) => log.status === filteredStatus
      );
    }
    if (createdAtSortOrder === "asc") {
      const sortData = await sortNutritionLogs(
        nutritionData,
        createdAtSortOrder
      );
      return sortData;
    } else {
      return filteredNutritionLogDetails;
    }
  } else {
    return nutritionData;
  }
}
