/** @format */

import { accountService } from "../services";
import { config } from "../shared/constants/config";

export const fetchWrapper = {
  download,
  get,
  post,
  postFormData,
  put,
  delete: _delete,
};

function get(url: string) {
  const user = accountService.userValue;

  const requestOptions: RequestInit = {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(url),
    },
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function download(url: string) {
  const requestOptions: RequestInit = {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json", ...authHeader(url) },
  };
  return fetch(url, requestOptions);
}

function postFormData(url: string, body: any) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: { ...authHeader(url) },
    credentials: "include",
    body: body,
  };
  return fetch(url, requestOptions).then(handleResponse);
}

async function post(url: string, body: any) {
  const requestOptions: RequestInit = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader(url) },

    credentials: "include",
    body: JSON.stringify(body),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function put(url: string, body: any) {
  const requestOptions: RequestInit = {
    method: "PUT",
    headers: { "Content-Type": "application/json", ...authHeader(url) },
    credentials: "include",
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url: string) {
  const requestOptions: RequestInit = {
    method: "DELETE",
    credentials: "include",
    headers: { ...authHeader(url) },
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// helper functions
function authHeader(url: string): HeadersInit {
  // return auth header with jwt if user is logged in and request is to the api url
  const user = accountService.userValue;

  const isLoggedIn = user && user.jwtToken;
  const isApiUrl = url.startsWith(config.apiUrl);

  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${user.jwtToken}` };
  } else {
    return {};
  }
}

function handleResponse(response: Response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      // if ([401, 403].includes(response.status) && accountService.userValue) {
      //   accountService.logout();
      // }
      const error = (data && data.message) || response.statusText;

      return Promise.reject(error);
    }

    return data;
  });
}
