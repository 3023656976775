import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material"; // Import cross icon
import { additionalService } from "../../services/additional.service";

interface InformationFormProps {
  onClose: () => void;
  onSave: (data: any) => void;
  campaignId: string | null;
  type: string | null;
  sequence: any;
  initialData?: {
    uuid: string;
    campaign_id: string;
    sequence: number;
    type: string;
    input_fields: {
      title: string;
      body: string;
      imageUrl: string | null;
    };
  };
  editingInteractionId: any;
  onEdit: (data: any) => void;
}

const InformationForm: React.FC<InformationFormProps> = ({
  onClose,
  onSave,
  initialData,
  campaignId,
  type,
  sequence,
  editingInteractionId,
  onEdit,
}) => {
  const [formData, setFormData] = useState({
    title: initialData?.input_fields?.title || "",
    body: initialData?.input_fields?.body || "",
    image: null as File | null, // Handle new image file
    imageUrl: initialData?.input_fields?.imageUrl || null, // Use existing image URL if available
    imageName: initialData?.input_fields?.imageUrl
      ? initialData.input_fields.imageUrl.split("/").pop() || ""
      : null, // Extract image name from the URL
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialData?.input_fields?.imageUrl) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        imageName: initialData.input_fields.imageUrl?.split("/").pop() || "",
      }));
    }
  }, [initialData]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: file,
        imageName: file.name, // Set image name
      }));
    }
  };

  const handleImageClear = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: null,
      imageName: null,
      imageUrl: null, // Clear image URL if removing image
    }));
  };

  const handleInputChange = (field: string, value: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);

    let imageUrl = formData.imageUrl; // Use existing image URL if no new image is uploaded

    try {
      if (formData.image) {
        // If a new image is selected, upload it
        imageUrl = await additionalService.uploadImageOnAzure(formData.image);
      }

      // Save the form data with updated values
      onSave({
        // Use existing UUID or generate a random one
        campaign_id: campaignId, // Keep existing campaign_id
        sequence: sequence, // Keep existing sequence
        type: type, // Keep existing type
        input_fields: {
          title: formData.title,
          body: formData.body,
          imageUrl, // Either the new or existing image URL
        },
      });

      onClose(); // Close the modal or dialog
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleEdit = async () => {
    setLoading(true);

    let imageUrl = formData.imageUrl; // Use existing image URL if no new image is uploaded

    try {
      if (formData.image) {
        // If a new image is selected, upload it
        imageUrl = await additionalService.uploadImageOnAzure(formData.image);
      }

      // Save the form data with updated values
      onEdit({
        index: editingInteractionId,
        // Use existing UUID or generate a random one
        campaign_id: campaignId, // Keep existing campaign_id
        sequence: sequence, // Keep existing sequence
        type: type, // Keep existing type
        input_fields: {
          title: formData.title,
          body: formData.body,
          imageUrl, // Either the new or existing image URL
        },
      });

      onClose(); // Close the modal or dialog
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Information Form
      </Typography>
      <Box sx={{ mb: 2 }}>
        <input
          accept="image/*"
          id="image-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        <label htmlFor="image-upload">
          <Button variant="contained" component="span">
            Browse Image
          </Button>
        </label>
        {formData.imageName && (
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              {formData.imageName}
            </Typography>
            <IconButton onClick={handleImageClear} size="small">
              <ClearIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={formData.title}
        onChange={(e) => handleInputChange("title", e.target.value)}
      />
      <TextField
        label="Body"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        sx={{ mb: 2 }}
        value={formData.body}
        onChange={(e) => handleInputChange("body", e.target.value)}
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {initialData ? (
            <Button variant="contained" color="primary" onClick={handleEdit}>
              Update
              {/* Change button text based on mode */}
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Add
              {/* Change button text based on mode */}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default InformationForm;
