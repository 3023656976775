import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check"; // Icon to indicate selection
import { additionalService } from "../services/additional.service";
import { configureService } from "../services/configure.service";

const LlmListModal = ({
  open,
  handleClose,
  llmApiConfigureData,
  deleteConfigurationData,
  setCurrentConfigurationData,
  setLlmModalFormOpen,
  iconLoading,
}: any) => {
  const [credentialsData, setCredentialsData] = useState<any>(null);
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [loading, setLoading] = useState<boolean>(true); // To show loader while fetching data
  const [selectLoading, setSelectLoading] = useState<string | null>(null);
  // Fetch the OpenAI credentials when the modal opens
  useEffect(() => {
    if (open) {
      setLoading(true); // Start loader when fetching credentials
      additionalService.getOpenAiCredentials().then((response) => {
        if (response.success) {
          setCredentialsData(response.data.azureOpenAiGptVisionUrl);

          // Match region and set selectedConfigId
          const matchingItem = llmApiConfigureData.find((item: any) =>
            response.data.azureOpenAiGptVisionUrl.includes(item.region)
          );

          if (matchingItem) {
            setSelectedConfigId(matchingItem._id); // Set the matched config id
          }
        }
        setLoading(false); // Stop loader after credentials are fetched
      });
    }
  }, [open, llmApiConfigureData]);

  // Handle selection of a configuration
  const handleSelectCard = async (config: any) => {
    setSelectLoading(config._id);
    try {
      let data = {
        region: config.region,
        provider: config.provider,
        modelName: config.modelName,
      };
      const response = await additionalService.updateOpenAiCredentials(data);
      if (response.success) {
        setSelectedConfigId(config._id);
      }
    } catch (error) {
    } finally {
      setSelectLoading(null);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden", // Ensure no overflow on the modal itself
      }}
    >
      <Box
        sx={{
          width: "500px", // Set a fixed width for the modal box
          maxHeight: "80vh", // Limit modal height to avoid overflowing the viewport
          overflowY: "auto", // Enable scrolling when content exceeds height
          backgroundColor: "#fff", // White background
          padding: "20px", // Add some padding
          borderRadius: "8px", // Rounded corners
          boxShadow: 24, // Material UI shadow effect
          position: "relative",
        }}
      >
        {loading || iconLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px", // Fixed height for the loader area
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box>
              {llmApiConfigureData &&
                llmApiConfigureData.length > 0 &&
                llmApiConfigureData.map((item: any, index: any) => {
                  const isSelected = item._id === selectedConfigId;
                  const hasAzureGptRegion = credentialsData?.includes(
                    item.region
                  );

                  return (
                    <Card
                      key={index}
                      sx={{
                        mb: 2,
                        border: isSelected ? "2px solid blue" : "none",
                      }}
                    >
                      <CardContent>
                        <Typography variant="h6">
                          Provider: {item.provider}
                        </Typography>
                        <Typography variant="body2">
                          Instance Name: {item.region}
                        </Typography>
                        <Typography variant="body2">
                          Deployment Name: {item.deploymentName}
                        </Typography>
                        <Typography variant="body2">
                          Model Name: {item.modelName}
                        </Typography>

                        <IconButton
                          onClick={() => deleteConfigurationData(item._id)}
                        >
                          <DeleteIcon />
                        </IconButton>

                        <IconButton>
                          <EditIcon
                            onClick={() => {
                              setCurrentConfigurationData(item);
                              setLlmModalFormOpen(true);
                            }}
                          />
                        </IconButton>

                        {/* Button to select this card */}
                        {selectLoading === item._id ? (
                          <Container>
                            <CircularProgress />
                          </Container>
                        ) : (
                          <Button
                            variant="contained"
                            color={isSelected ? "primary" : "inherit"}
                            onClick={() => handleSelectCard(item)}
                          >
                            {isSelected ? "Selected" : "Select"}
                          </Button>
                        )}
                      </CardContent>
                    </Card>
                  );
                })}
            </Box>

            {/* Additional button inside a box */}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  handleClose();
                  setLlmModalFormOpen(true);
                }}
              >
                Add
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default LlmListModal;
