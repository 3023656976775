import React, { useEffect, useState } from "react";
import {
  CardContent,
  Typography,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { userDetailsUtils } from "../../utils/UserDetails";
import { accountService } from "../../services"; // Assume this is the service function for the API call
import { AppState } from "../../interface/User";
import { SelectedUserInterface } from "../../interface/User";
interface UserBasicInfo {
  selectedUser: SelectedUserInterface;

  handleOpen: any;
}

const InfoItem = ({ label, value }: any) => (
  <Typography sx={{ mb: 0.5 }} color="text.secondary">
    {label}: {value !== undefined && value !== null ? value : "Not Available"}
  </Typography>
);

const UserBasicInfo = ({
  selectedUser,

  handleOpen,
}: UserBasicInfo) => {
  const [saleStage, setsaleStage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedUser) {
      setsaleStage(selectedUser?.saleStage || "");
    }
  }, [selectedUser]);
  if (!selectedUser) return null;

  const {
    gender,
    yob,
    height,
    heightUnit: userHeightUnit,
    lastActive,
    onboardingDate,
    targetActivity,
    _id,
  } = selectedUser;

  const age = yob ? new Date().getFullYear() - yob : "Not Available";
  const formattedHeight =
    height !== undefined
      ? userHeightUnit === "cm"
        ? `${parseFloat(height.toFixed(1))} cm`
        : `${Math.floor(height / 30.48)}' ${Math.round(
            ((height / 30.48) % 1) * 12
          )}`
      : "Not Available";
  const formattedWeight =
    typeof selectedUser.weight === "number"
      ? `${selectedUser.weight.toFixed(2)} Kg`
      : "";

  const handlesaleStageChange = async (event: any) => {
    const selectedValue = event.target.value as string;
    setsaleStage(selectedValue);
    setIsLoading(true);
    let updatedUserData = { ...selectedUser, saleStage: selectedValue };
    try {
      // Make API call to update user type
      await accountService.userUpdate(updatedUserData);
    } catch (error) {
      console.error("Error updating user type:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Conditionally show saleStage */}
          {/* {saleStage && (
            <Typography sx={{ marginRight: 2 }}>{saleStage}</Typography>
          )} */}

          {/* Dropdown or Loader */}
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <FormControl size="small">
              <InputLabel>Sale Stage</InputLabel>
              <Select
                value={saleStage}
                onChange={handlesaleStageChange}
                sx={{ width: 150 }}
              >
                <MenuItem value="Lead">Lead</MenuItem>
                <MenuItem value="Opportunity">Opportunity</MenuItem>
                <MenuItem value="Customer">Customer</MenuItem>
                <MenuItem value="Cold">Cold</MenuItem>
              </Select>
            </FormControl>
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <InfoItem label="Gender" value={gender} />
        <InfoItem label="Age" value={age} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <InfoItem label="Weight" value={formattedWeight} />
        <InfoItem label="Height" value={formattedHeight} />
      </div>
      <InfoItem
        label="Last Active"
        value={userDetailsUtils.formatTimestamp(lastActive)}
      />
      <InfoItem
        label="BMR"
        value={
          userDetailsUtils
            .calculateBMR(
              selectedUser.weight,
              selectedUser.height,
              selectedUser.yob,
              selectedUser.gender
            )
            .toString() // Ensure it's a string for display
        }
      />

      {onboardingDate && (
        <InfoItem
          label="Onboarding Date"
          value={userDetailsUtils.formatTimestamp(onboardingDate)}
        />
      )}
      <InfoItem label="Current Goal" value={targetActivity} />
      <InfoItem label="Id" value={_id} />
      <Button
        color="primary"
        variant="text"
        onClick={handleOpen}
        sx={{
          marginLeft: -1,
          textTransform: "capitalize",
        }}
      >
        Onboarding Summary
      </Button>
    </>
  );
};

export default UserBasicInfo;
