import { Container } from "@mui/material";
import { useRef, useState } from "react";
import { Typography } from "@mui/material";
import {
  TableRow,
  TableCell,

} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Exercise from "./Exercise";
import IconButton from "@mui/material/IconButton";
interface ExerciseHistory {
  workoutData: any;
  currentDate: any;
}


export default function IndividualWorkouts({
  workoutData,
  currentDate,
}: ExerciseHistory) {
  const [open, setOpen] = useState(false);
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const handleDropdownKeyClick = () => {
    setOpen(!open);
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  };
  return (
    <>
      <TableRow
        key={workoutData.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{
            width: "50%",
            position: "sticky",
            top: 0,
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {workoutData.exercises && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={handleDropdownKeyClick}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
            <Typography style={{ marginLeft: 5,fontWeight:"bold" }}>
              {" "}
              {workoutData.workout_name}
            </Typography>
          </div>
        </TableCell>
      </TableRow>
      {open && <div ref={bottomRef}></div>}
      {workoutData.exercises && (
        <Exercise
          open={open}
          exerciseData={workoutData.exercises}
         
        />
      )}
      
    </>
  );
}