import React from "react";
import {
  CardContent,
  CircularProgress,
  Typography,
  Container,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Line,
  ResponsiveContainer,
} from "recharts";
import Weight from "../../modules/Weight";

interface UserGraphProps {
  dataType: string;
  dateRange: any;
  currentDate: Date;
  handlePrevWeek: () => void;
  handleNextWeek: () => void;
  handleTypeChange: (
    event: React.MouseEvent<HTMLElement>,
    newDataType: string
  ) => void;
  userTargets: any;
  selectedUser: any;
  targetLevels: any;

  stepsWeekArray: any[];
  weightsWeekArray: any[];
  proteinTotals: any[];
  calorieTotals: any[];
  sleepTotals: any[];
  waterWeekArray: any[];
  weekTargets: any[];
}

const UserGraph: React.FC<UserGraphProps> = ({
  dataType,
  dateRange,
  currentDate,
  handlePrevWeek,
  handleNextWeek,
  handleTypeChange,
  userTargets,
  selectedUser,
  targetLevels,
  stepsWeekArray,
  weightsWeekArray,
  proteinTotals,
  calorieTotals,
  sleepTotals,
  waterWeekArray,
  weekTargets,
}) => {
  // Define a type for the keys of maxValues
  type DataType =
    | "steps"
    | "weights"
    | "protein"
    | "calorie"
    | "sleep"
    | "water";

  // Calculate the maximum value for each data type
  const getMaxValue = (data: any[], key: string) => {
    let value = data.length
      ? Math.max(...data.map((item) => item[key] || 0))
      : 0;
    return value;
  };

  const maxValues: Record<DataType, number> = {
    steps: getMaxValue(stepsWeekArray, "steps"),
    weights: getMaxValue(weightsWeekArray, "weights"),
    protein: getMaxValue(proteinTotals, "total"),
    calorie: getMaxValue(calorieTotals, "total"),
    sleep: getMaxValue(sleepTotals, "sleep"),
    water: getMaxValue(waterWeekArray, "water"),
  };

  const calculateMaxYValue = (maxValue: number) => {
    const buffer = maxValue * 0.2; // Add a 20% buffer
    const roundedMaxValue = Math.ceil((maxValue + buffer) / 10) * 10; // Round up to the nearest 10
    return roundedMaxValue;
  };

  let maxYValue = calculateMaxYValue(maxValues[dataType as DataType]);

  // Dynamically get the target level for the current dataType
  const targetLevel = targetLevels[dataType as DataType];

  // Adjust maxYValue based on the target level
  if (maxYValue < targetLevel) {
    maxYValue = targetLevel + 200;
  }

  // Calculate ticks for the YAxis
  const calculateTicks = (maxValue: number) => {
    const tickCount = 5;
    const interval = Math.ceil(maxValue / (tickCount - 1));
    return Array.from({ length: tickCount }, (_, index) =>
      Math.round(interval * index)
    );
  };

  const yTicks = calculateTicks(maxYValue).filter((tick) => tick !== 0);

  // Helper to merge target into weekArray data
  const mergeTargetIntoArray = (array: any[], dataType: DataType) => {
    return array.map((item) => {
      const target = weekTargets.find((target) => target.date === item.date);
      return target ? { ...item, target: target.targets[dataType] } : item;
    });
  };

  // Merge targets into the corresponding week arrays
  const stepsWeekArrayWithTarget = mergeTargetIntoArray(
    stepsWeekArray,
    "steps"
  );
  const waterWeekArrayWithTarget = mergeTargetIntoArray(
    waterWeekArray,
    "water"
  );
  const proteinTotalsWithTarget = mergeTargetIntoArray(
    proteinTotals,
    "protein"
  );
  const calorieTotalsWithTarget = mergeTargetIntoArray(
    calorieTotals,
    "calorie"
  );

  const sleepTotalsWithTarget = mergeTargetIntoArray(sleepTotals, "sleep");

  // Determine the data to display based on dataType
  const selectedData =
    (dataType === "steps" && stepsWeekArrayWithTarget) ||
    (dataType === "weights" && weightsWeekArray) ||
    (dataType === "protein" && proteinTotalsWithTarget) ||
    (dataType === "calorie" && calorieTotalsWithTarget) ||
    (dataType === "sleep" && sleepTotalsWithTarget) ||
    waterWeekArrayWithTarget;

  return (
    <CardContent style={{ overflowX: "auto" }}>
      <>
        <Typography
          align="center"
          sx={{ fontSize: 20 }}
          color="#000"
          gutterBottom
        >
          Graphs
        </Typography>
        {dataType !== "weights" && (
          <Container sx={{ display: "flex", justifyContent: "space-between" }}>
            <ArrowBackIosIcon
              sx={{ cursor: "pointer" }}
              onClick={handlePrevWeek}
            />
            <Typography sx={{ fontSize: 18 }} color="#000" gutterBottom>
              {dateRange.starting} to {dateRange.ending}
            </Typography>
            <ArrowForwardIosIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                if (currentDate.getDate() === new Date().getDate()) {
                  return;
                } else {
                  handleNextWeek();
                }
              }}
            />
          </Container>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ToggleButtonGroup
            value={dataType}
            exclusive
            onChange={handleTypeChange}
            aria-label="data type"
          >
            {["steps", "water", "protein", "calorie", "sleep", "weights"].map(
              (type) => (
                <ToggleButton
                  key={type}
                  value={type}
                  aria-label={type}
                  sx={{
                    "@media screen and (max-width: 1272px)": {
                      padding: "1px", // Padding for screens smaller than 1272px
                      fontSize: "12px",
                    },
                    "&.Mui-selected": { backgroundColor: "#f2f2f2" },
                  }}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </ToggleButton>
              )
            )}
          </ToggleButtonGroup>
        </div>
        {dataType === "weights" && userTargets.targetWeight ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: 25,
            }}
          >
            <Weight
              selectedUser={selectedUser}
              targetWeight={userTargets.targetWeight}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: 15,
            }}
          >
            {targetLevels ? (
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart
                  data={selectedData}
                  margin={{ top: 5, left: -10, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis
                    dataKey="day"
                    tick={{ fontSize: 13 }}
                    tickFormatter={(day) => day.substring(0, 3)} // Display only the first three letters of each day
                  />
                  <YAxis
                    domain={[0, maxYValue]}
                    ticks={yTicks}
                    tickFormatter={(value) => value.toString()}
                  />
                  <Tooltip />

                  {/* Bar for actual values */}
                  <Bar
                    dataKey={
                      (dataType === "steps" && "steps") ||
                      (dataType === "weights" && "weights") ||
                      (dataType === "protein" && "total") ||
                      (dataType === "calorie" && "total") ||
                      (dataType === "sleep" && "sleep") ||
                      "water"
                    }
                    barSize={10}
                    fill="#1067bf"
                  />

                  {/* Line for target values */}
                  <Line
                    type="monotone"
                    dataKey="target"
                    stroke="#ff7300"
                    strokeWidth={2}
                    dot={false}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            ) : null}
          </div>
        )}
      </>
    </CardContent>
  );
};

export default UserGraph;
