import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";
const baseUrl = `${config.apiUrl}/api`;
export const userResponseService = { getUserResponses };

/// Function For adding new campagins ------------------------------------/
async function getUserResponses(campaign_id: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.get(
    `${baseUrl}/get-userResponses?campaign_id=${campaign_id}`
  );
}
