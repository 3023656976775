/** @format */

import { memo } from "react";
import {
  Grid,
  TextField,
  Button,
  Paper,
  CircularProgress,
  Container,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
interface FormNotification {
  sendUserNotification: (title: string, body: string, type: string) => any;
  loading: boolean;
}
// Validation Schema
const validationSchema = Yup.object().shape({
  title: Yup.string().required("This field can't be empty"),
  body: Yup.string().required("This field can't be empty"),
  type: Yup.string().required("This field can't be empty"),
});

function FormEmail({ sendUserNotification, loading }: FormNotification) {
  async function submitFunction(
    values: any,
    setSubmitting: any,
    resetForm: any
  ) {
    await sendUserNotification(values.title, values.body, values.type);

    // Once the notification is sent, reset the form and stop submitting
    resetForm();
    setSubmitting(false);
  }

  return (
    <Paper
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        height: "80vh",
      }}
    >
      <Formik
        initialValues={{ title: "", body: "", type: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submitFunction(values, setSubmitting, resetForm);
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          values,
          setFieldValue,
          handleChange,
        }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <div style={{ width: "50%" }}>
                <Field
                  as={TextField}
                  name="title"
                  label="Title"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  rows={1}
                  multiline
                  helpertext={<ErrorMessage name="title" />}
                  error={!!errors.title && touched.title}
                />
              </div>
              <div style={{ width: "50%", marginLeft: 10 }}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Type</InputLabel>
                  <Field
                    as={Select}
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    label="Type"
                    error={!!errors.type && touched.type}
                    helpertext={<ErrorMessage name="type" />}
                  >
                    <MenuItem value="support">support</MenuItem>
                    <MenuItem value="trainer">trainer</MenuItem>
                    <MenuItem value="admin">admin</MenuItem>
                    <MenuItem value="other">other</MenuItem>
                  </Field>
                </FormControl>
              </div>
            </div>
            <Field
              as={TextField}
              name="body"
              label="Body"
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              rows={12}
              helpertext={<ErrorMessage name="body" />}
              error={!!errors.body && touched.body}
            />

            {loading ? (
              <Container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ marginTop: 8 }} />
              </Container>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: "auto", width: "100%" }}
              >
                Send
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </Paper>
  );
}

export default memo(FormEmail);
