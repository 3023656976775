import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Paper,
  Box,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Snackbar,
  CircularProgress,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // Import the three dots icon
import Menu from "@mui/material/Menu"; // Import Menu component
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem component
import DeleteIcon from "@mui/icons-material/Delete"; // Import the delete icon
import { useLocation, useNavigate } from "react-router-dom";
import TopAppBar from "../modules/TopAppBar";
import { campaignService } from "../services/campaign.service";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv"; // Import CSVLink
import { userResponseService } from "../services/userresponse.service";
interface CampaignType {
  uuid: any;
  name: string;
  interactions: any;
  start_dt: any;
  end_dt: string;
  status: string;
}

const Campaign = () => {
  const location = useLocation();
  const { users, totalCount } = location.state || {};

  const [open, setOpen] = useState(false);
  const [campaigns, setCampaigns] = useState<CampaignType[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [campaignCreationLoading, setCampaignCreationLoading] = useState(false);
  const [newCampaignName, setNewCampaignName] = useState("");
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [deletingCampaignId, setDeletingCampaignId] = useState<string | null>(
    null
  ); // State to manage which campaign is being deleted
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [activeCampaignId, setActiveCampaignId] = useState<string | null>(null);
  const [duplicateLoading, setDuplicateLoading] = useState<boolean>(false);
  const [openNameDialog, setOpenNameDialog] = useState(false);
  const [newDuplicateCampaignName, setNewDuplicateCampaignName] = useState("");
  const navigate = useNavigate();
  const [csvData, setCsvData] = useState<any[]>([]); // Data for CSV
  const [downloadingCampaignId, setDownloadingCampaignId] = useState<
    string | null
  >(null); // Track which campaign is downloading
  const [duplicateCampaignId, setDuplicateCampaignId] = useState<string | null>(
    null
  );
  const csvLinkRef = useRef<any>(); // Reference for triggering CSV download
  const padString = (str: string, length: number) => {
    return str.length < length ? str + " ".repeat(length - str.length) : str;
  };
  const handleDownloadResponses = async (campaignId: string) => {
    setDownloadingCampaignId(campaignId);
    try {
      const response = await userResponseService.getUserResponses(campaignId);

      if (response.success) {
        // Transform the data into CSV format with proper spacing and without extra quotes
        const csvFormattedData = response.data.map((item: any) => {
          // Always show the raw userresponse, even if it's null
          const rawResponse = item.userresponse || ""; // Show the raw userresponse or empty string if it's null

          return {
            user_id: padString(item.user_id, 25), // Pad the user_id to a minimum width
            action: padString(item.action, 10), // Pad the action to a minimum width
            created_at: padString(item.created_at, 30), // Pad the created_at to a minimum width
            userresponse: padString(rawResponse, 80), // Pad the userresponse to a minimum width
          };
        });

        setCsvData(csvFormattedData);

        // Trigger the CSV download once data is fetched
        setTimeout(() => {
          if (csvLinkRef.current) {
            csvLinkRef.current.link.click();
          }
        }, 500); // Slight delay to ensure CSVLink is ready
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("Failed to fetch responses");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error fetching user responses");
      setSnackbarOpen(true);
    } finally {
      setDownloadingCampaignId(null); // Stop showing loader
    }
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    campaignId: string
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setActiveCampaignId(campaignId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setActiveCampaignId(null);
  };

  const handleDuplicateCampaignSameName = async (uuid: string) => {
    if (!activeCampaignId) return;
    setDuplicateLoading(true);
    setDuplicateCampaignId(uuid);
    try {
      const response = await campaignService.createDuplicateCampaign({
        campaign_id: activeCampaignId,
        new_campaign_name:
          campaigns.find((c) => c.uuid === activeCampaignId)?.name || "",
      });

      if (response.success) {
        setCampaigns([response.data, ...campaigns]);
        setSnackbarMessage("Campaign duplicated successfully.");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage(`Failed to duplicate campaign.${response.message}`);
        setSnackbarOpen(true);
      }
    } catch (error: any) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    } finally {
      setDuplicateLoading(false);
      setDuplicateCampaignId(null);
      handleMenuClose();
    }
  };

  const handleDuplicateCampaignDifferentName = () => {
    setOpenNameDialog(true);
  };

  const handleDuplicateWithNewName = async () => {
    if (!activeCampaignId || !newDuplicateCampaignName.trim()) return;
    setDuplicateLoading(true);

    try {
      const response = await campaignService.createDuplicateCampaign({
        campaign_id: activeCampaignId,
        new_campaign_name: newDuplicateCampaignName,
      });

      if (response.success) {
        setCampaigns([response.data, ...campaigns]);
        setSnackbarMessage("Campaign duplicated with a new name successfully.");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("Failed to duplicate campaign.");
        setSnackbarOpen(true);
      }
    } catch (error: any) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    } finally {
      setDuplicateLoading(false);
      setNewDuplicateCampaignName("");
      setOpenNameDialog(false);
      handleMenuClose();
    }
  };

  const handleAddCampaignClick = () => {
    setOpen(true);
  };

  async function fetchCampaigns() {
    setCampaignLoading(true);
    try {
      const response = await campaignService.getAllCampaign();

      if (response.success) {
        setCampaigns(response.data);
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      } else {
        setCampaigns([]);
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      }

      setCampaignLoading(false);
    } catch (error) {
      setCampaignLoading(false);
    }
  }

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateCampaign = async () => {
    if (newCampaignName.trim()) {
      try {
        setCampaignCreationLoading(true);
        let data = { name: newCampaignName, status: "draft" };
        const response = await campaignService.createNewCampaign(data);

        if (response.success) {
          // Add the interactions key with an empty array to the response.data object
          const updatedCampaign = {
            ...response.data,
            interactions: [],
          };

          setSnackbarMessage(response.message);
          setSnackbarOpen(true);

          setCampaigns([...campaigns, updatedCampaign]);
        }
      } catch (error: any) {
        setSnackbarMessage(error.message);
        setSnackbarOpen(true);
        setCampaignCreationLoading(false);
      }

      setNewCampaignName("");
      handleClose();
    }
  };

  const handleCampaignClick = (
    campaign: CampaignType,
    allUsers: any,
    totalNumberOfUsers: number
  ) => {
    // Initialize the URL with campaignId, campaignName, and interactions
    let url = `/campaigndetails?campaignId=${
      campaign.uuid
    }&campaignName=${encodeURIComponent(campaign.name)}&status=${
      campaign.status
    }`;

    // Conditionally add start_dt and end_dt if they are not null
    if (campaign.start_dt) {
      url += `&start_dt=${encodeURIComponent(campaign.start_dt)}`;
    }

    if (campaign.end_dt) {
      url += `&end_dt=${encodeURIComponent(campaign.end_dt)}`;
    }

    // Navigate to the constructed URL and pass users and totalCount via state
    // Navigate to the constructed URL and pass users and totalCount via state
    navigate(url, {
      state: {
        allUsers: allUsers,
        fetchedInteractions: campaign.interactions,
        totalCount: totalNumberOfUsers,
      },
    });
  };

  const handleDeleteCampaign = async (campaignId: string) => {
    setDeletingCampaignId(campaignId);
    try {
      const response = await campaignService.deleteCampaign(campaignId);
      if (response.success) {
        setSnackbarMessage(response.message);
        setSnackbarOpen(true);
        // Remove the deleted campaign from the list
        setCampaigns(campaigns.filter((c) => c.uuid !== campaignId));
      } else {
        setSnackbarMessage("Failed to delete campaign");
        setSnackbarOpen(true);
      }
    } catch (error: any) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    } finally {
      setDeletingCampaignId(null);
    }
  };

  function handleCloseSnackbar(): void {
    setSnackbarOpen(false);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "90vh", // Use 90vh to fit most of the viewport
        padding: 2,
      }}
    >
      <TopAppBar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCampaignClick}
          sx={{
            backgroundColor: "#007bff", // Blue color
            "&:hover": {
              backgroundColor: "#0056b3", // Darker blue on hover
            },
          }}
        >
          Add Campaign
        </Button>
      </Box>

      <Container
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Align content at the top
          paddingTop: 2,
          paddingBottom: 2, // Add padding at the bottom to avoid cutting off the last campaign
          height: "100%", // Fill the remaining height
          overflowY: "auto", // Enable vertical scrolling
        }}
      >
        {campaignLoading ? (
          <CircularProgress />
        ) : (
          <>
            {campaigns.map((campaign) => (
              <Paper
                key={campaign.uuid}
                sx={{
                  width: 300,
                  marginBottom: 2,
                  padding: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  textAlign: "center",
                  boxShadow: 3,
                  borderRadius: 2,
                  backgroundColor: "#ffffff",
                  border: "1px solid #ddd",
                  cursor: "pointer", // Indicate clickable area
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.02)",
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", color: "#333", flexGrow: 1 }}
                  onClick={() =>
                    handleCampaignClick(campaign, users, totalCount)
                  }
                >
                  {campaign.name}
                </Typography>
                {downloadingCampaignId === campaign.uuid ? (
                  <CircularProgress size={24} />
                ) : (
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={() => handleDownloadResponses(campaign.uuid)}
                  >
                    <DownloadIcon />
                  </IconButton>
                )}
                {deletingCampaignId === campaign.uuid ? (
                  <CircularProgress size={24} />
                ) : (
                  <IconButton
                    edge="end"
                    color="error"
                    onClick={() => handleDeleteCampaign(campaign.uuid)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                <IconButton
                  edge="end"
                  onClick={(event) => handleMenuClick(event, campaign.uuid)}
                >
                  <MoreVertIcon />
                </IconButton>
                {duplicateCampaignId === campaign.uuid ? (
                  <CircularProgress />
                ) : (
                  <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(
                      menuAnchorEl && activeCampaignId === campaign.uuid
                    )}
                    onClose={handleMenuClose}
                  >
                    <MenuItem
                      onClick={() => {
                        handleDuplicateCampaignSameName(campaign.uuid);
                      }}
                    >
                      "Create Duplicate Campaign (Same Name)"
                    </MenuItem>
                    <MenuItem onClick={handleDuplicateCampaignDifferentName}>
                      "Create Duplicate Campaign (Different Name)"
                    </MenuItem>
                  </Menu>
                )}
              </Paper>
            ))}
          </>
        )}
      </Container>

      {/* Modal for adding new campaign */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Campaign</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Campaign Name"
            type="text"
            fullWidth
            variant="standard"
            value={newCampaignName}
            onChange={(e) => setNewCampaignName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {campaignCreationLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleCreateCampaign}>Create</Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={openNameDialog} onClose={() => setOpenNameDialog(false)}>
        <DialogTitle>Enter New Campaign Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="new-campaign-name"
            label="Campaign Name"
            type="text"
            fullWidth
            variant="standard"
            value={newDuplicateCampaignName}
            onChange={(e) => setNewDuplicateCampaignName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNameDialog(false)}>Cancel</Button>
          {duplicateLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleDuplicateWithNewName}>Create</Button>
          )}
        </DialogActions>
      </Dialog>
      {/* CSVLink for programmatically downloading CSV */}
      <CSVLink
        data={csvData}
        filename="campaign-responses.csv"
        className="hidden"
        ref={csvLinkRef}
        target="_blank"
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2", // Use primary color from the theme
            color: "white", // Set text color to white
          },
        }}
      />
    </Box>
  );
};

export default Campaign;
