/** @format */

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { memo } from "react";
import Paper from "@mui/material/Paper";
import {
  WaterBrowseDataInterface,
  WaterDataInterface,
} from "../../interface/WaterInterface";

//// Main function For the sleepBrowsedata component -------------------------->
function WaterBrowseData({ waterArray }: WaterBrowseDataInterface) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: 2,
        height: "65vh",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                width: "50%",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              Date Time
            </TableCell>
            <TableCell
              style={{
                width: "50%",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              Water
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {waterArray?.map((row: WaterDataInterface, index: number) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ width: "50%", textAlign: "center" }}
              >
                {row.dateTime}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ width: "50%", textAlign: "center" }}
              >
                {row.water}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(WaterBrowseData);
