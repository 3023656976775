import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { extractJson } from "../utils/ExtractJson";

interface BasicInfoModalProps {
  open: boolean;
  onClose: () => void;
  selectedUser: any;
}

const BasicInfoModal: React.FC<BasicInfoModalProps> = ({
  open,
  onClose,
  selectedUser,
}) => {
  const [onboardingData, setOnboardingData] = useState<any>({});
  async function getData(selectedUser: any) {
    if (selectedUser && selectedUser.onboardingChatSummary) {
      try {
        const data = await extractJson(selectedUser.onboardingChatSummary);
        const parseData = JSON.parse(data);
        setOnboardingData(parseData);
      } catch (error) {
        console.error("Error parsing onboarding chat summary:", error);
        setOnboardingData({});
      }
    }
  }
  useEffect(() => {
    getData(selectedUser);
  }, [selectedUser]);

  const formatTimestamp = (timestamp: string) => {
    if (!timestamp) return "";
    const dateObject = new Date(timestamp);
    return dateObject.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const renderListItems = (items: any[]) => {
    return items.map((item, index) => (
      <span key={index}>
        {item}
        {index < items.length - 1 ? ", " : ""}
      </span>
    ));
  };
  function handleClose() {
    setOnboardingData({});
    onClose();
  }
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflow: "auto",
          maxHeight: "80vh",
        }}
      >
        {/* Close Icon */}
        <IconButton
          onClick={() => handleClose()}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        {/* Onboarding data */}
        {onboardingData ? (
          <>
            <Typography sx={{ mb: 1 }} color="text.secondary">
              <span style={{ fontWeight: "bold" }}>Allergies: </span>
              {onboardingData.allergies && onboardingData.allergies.length > 0
                ? onboardingData.allergies.join(", ")
                : "Not Available"}
            </Typography>

            <Typography sx={{ mb: 1 }} color="text.secondary">
              <span style={{ fontWeight: "bold" }}>Dietary Restrictions: </span>
              {onboardingData.dietary_restrictions &&
              onboardingData.dietary_restrictions.length > 0
                ? onboardingData.dietary_restrictions.join(", ")
                : "Not Available"}
            </Typography>

            <Typography sx={{ mb: 1 }} color="text.secondary">
              <span style={{ fontWeight: "bold" }}>
                Experience with Training:{" "}
              </span>
              {onboardingData.experience_with_training || "Not Available"}
            </Typography>

            <Typography sx={{ mb: 1 }} color="text.secondary">
              <span style={{ fontWeight: "bold" }}>Ideal Fitness Goals: </span>
              {onboardingData.ideal_fitness_goals || "Not Available"}
            </Typography>

            <Typography sx={{ mb: 1 }} color="text.secondary">
              <span style={{ fontWeight: "bold" }}>Preferred Diet: </span>
              {onboardingData.preferred_diet &&
              onboardingData.preferred_diet.length > 0
                ? onboardingData.preferred_diet.join(", ")
                : "Not Available"}
            </Typography>

            {/* Medical Conditions in table format */}
            {onboardingData.medical_conditions &&
            onboardingData.medical_conditions.length > 0 ? (
              <Box sx={{ mt: 2 }}>
                <Typography color="text.secondary" sx={{ mb: 1 }}>
                  <span style={{ fontWeight: "bold" }}>
                    Medical Conditions:
                  </span>
                </Typography>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="medical conditions">
                    <TableHead>
                      <TableRow>
                        <TableCell>Condition</TableCell>
                        <TableCell>Notes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {onboardingData.medical_conditions.map(
                        (condition: any, index: any) => (
                          <TableRow key={index}>
                            <TableCell>{condition.name}</TableCell>
                            <TableCell>
                              {condition.notes.join(", ") || "N/A"}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <Typography sx={{ mt: 2 }} color="text.secondary">
                Medical Conditions: Not Available
              </Typography>
            )}
          </>
        ) : (
          <Typography sx={{ mt: 2 }}>Onboarding Data: Not Available</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default BasicInfoModal;
