import { Box, Grid } from "@mui/material";
import MultiSection from "./Multi/MultiSection";
import SingleSection from "./Single/SingleSection";
import Saved from "./Saved/Saved";
import SingleRecipePrompt from "./Prompt/SingleRecipePrompt";
import MultiRecipePrompt from "./Prompt/MultiRecipePrompt";
import Prod from "./prod/Prod";
import { SingleRecipe } from "../../interface/RecipeInterface";
interface MiddleSectionInterface {
  renderMiddleSection: string;
  setRenderMiddleSection: (type: string) => void;
  setSelectedEditingRecipe: (type: SingleRecipe | null) => void;
  selecteEditingdRecipe: SingleRecipe | null;
  setUpdateScreenName: (type: string) => void;
  updateScreenName: string;
}
function DetailsSection({
  renderMiddleSection,
  setRenderMiddleSection,
  setSelectedEditingRecipe,
  selecteEditingdRecipe,
  setUpdateScreenName,
  updateScreenName,
}: MiddleSectionInterface) {
  const renderGridItem = () => {
    switch (renderMiddleSection) {
      case "single":
        return (
          <SingleSection
            selectedEditingRecipe={selecteEditingdRecipe}
            setSelectedEditingRecipe={setSelectedEditingRecipe}
            setRenderMiddleSection={setRenderMiddleSection}
            setUpdateScreenName={setUpdateScreenName}
            updateScreenName={updateScreenName}
          />
        );
      case "multi":
        return <MultiSection />;
      case "saved":
        return (
          <Grid
            item
            xs={12}
            sx={{
              maxHeight: "88vh",

              overflowY: "scroll",
            }}
          >
            <Saved
              setRenderMiddleSection={setRenderMiddleSection}
              setSelectedEditingRecipe={setSelectedEditingRecipe}
              setUpdateScreenName={setUpdateScreenName}
              updateScreenName={updateScreenName}
            />
          </Grid>
        );
      case "prod":
        return (
          <Grid
            item
            xs={12}
            sx={{
              maxHeight: "88vh",

              overflowY: "scroll",
            }}
          >
            <Prod
              setRenderMiddleSection={setRenderMiddleSection}
              setSelectedEditingRecipe={setSelectedEditingRecipe}
              setUpdateScreenName={setUpdateScreenName}
              updateScreenName={updateScreenName}
            />
          </Grid>
        );
      case "singlerecipeprompt":
        return (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SingleRecipePrompt />
          </Grid>
        );
      case "multirecipeprompt":
        return (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MultiRecipePrompt />
          </Grid>
        );

      default:
        return null; // Return null or some default content
    }
  };

  return renderGridItem();
}

export default DetailsSection;
