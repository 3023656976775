import React, { useState } from "react";
import { Box, Grid, Paper, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FreeTextPreview from "./preview/free_textPreview";
import StarRatingPreview from "./preview/star_ratingPreview";
import MultipleChoiceForm from "./MultipleChoiceInteraction";
import MultipleChoicePreview from "./preview/multiple_choicePreview";

const Preview = ({ interactions }: { interactions: any[] }) => {
  // State to keep track of the current interaction index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to handle moving to the previous interaction
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : interactions.length - 1
    );
  };

  // Function to handle moving to the next interaction
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < interactions.length - 1 ? prevIndex + 1 : 0
    );
  };

  const renderInteractionCard = (interaction: any) => {
    const { type, input_fields } = interaction;

    switch (type) {
      case "free_text":
        return <FreeTextPreview input_fields={input_fields} />;

      case "multiple_choice":
        return <MultipleChoicePreview input_fields={input_fields} />;

      case "rating":
        return <StarRatingPreview input_fields={input_fields} />;

      case "information":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 2,
              borderRadius: 2,
              boxShadow: 3,

              overflowY: "scroll",
            }}
          >
            {/* Image */}
            {input_fields.imageUrl && (
              <img
                src={input_fields.imageUrl}
                alt="Meme"
                style={{
                  width: "100%",
                  maxHeight: 200,
                  objectFit: "cover",
                  borderRadius: 8,
                }}
              />
            )}

            {/* Title */}
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              {input_fields.title}
            </Typography>

            {/* Subtitle */}
            <Typography variant="body2" color="textSecondary">
              {input_fields.body}
            </Typography>
          </Box>
        );

      default:
        return <Typography>Unknown Interaction Type</Typography>;
    }
  };

  return (
    <Grid item xs={12} md={3}>
      <Paper
        sx={{
          padding: 2,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: "#ffffff",
          border: "1px solid #ddd",
          height: "82vh", // Fixed height for the preview section
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // Ensure content and arrows are spaced
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "#333",

            textAlign: "center", // Center the title
          }}
        >
          Preview
        </Typography>

        {/* Interaction Preview Box */}
        <Box
          sx={{
            flexGrow: 1, // Allows the box to grow to fill available space
            overflowY: "auto", // Allows vertical scrolling for long content
            mb: 2, // Adds margin at the bottom for spacing
          }}
        >
          {interactions.length === 0 ? (
            <Typography>No interactions added yet.</Typography>
          ) : (
            <Box
              sx={{
                padding: 2,
              }}
            >
              {renderInteractionCard(interactions[currentIndex])}
            </Box>
          )}
        </Box>

        {/* Navigation Arrows */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            padding: "8px",
            borderRadius: "8px",
            boxShadow: "0 1px 3px rgba(0,0,0,0.2)", // Button shadow effect
          }}
        >
          <IconButton
            onClick={handlePrevious}
            disabled={interactions.length === 0}
            sx={{
              color: "#333", // Icon color
              "&:disabled": { color: "#ccc" }, // Disabled color
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            disabled={interactions.length === 0}
            sx={{
              color: "#333", // Icon color
              "&:disabled": { color: "#ccc" }, // Disabled color
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Paper>
    </Grid>
  );
};

export default Preview;
