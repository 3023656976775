import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { accountService } from "../services";
import { routeService } from "../services/route.service";
import { CircularProgress, Container } from "@mui/material";

const PrivateHomeScreenRoute = ({ children }) => {
  const [permission, setPermission] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const getPermission = async () => {
      try {
        const userResponse = await accountService.userValue;
        setUser(userResponse);
        if (userResponse) {
          const response = await routeService.getDashboardPermission(
            userResponse.role
          );

          setPermission(response);
        }
      } catch (error) {
        console.error("Error fetching permissions", error);
      } finally {
        setLoading(false);
      }
    };

    getPermission();
  }, []);

  if (loading) {
    // Show a loading indicator while fetching permissions
    return (
      <Container
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress sx={{ marginTop: 20 }} />
      </Container>
    );
  }

  if (!user) {
    // If user is not logged in, redirect to login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If user is an admin, grant access to all routes
  if (permission.includes("full_access")) {
    return children;
  }

  // Check if the current route is in the user's permissions array
  const currentPath = location.pathname.replace(/^\//, "");
  if (permission.includes(currentPath)) {
    return children;
  }

  // If not, redirect to homepage
  return <Navigate to="/" replace />;
};

export default PrivateHomeScreenRoute;
