/** @format */

import { memo } from "react";
import { Divider, Paper, Container, Typography, Box } from "@mui/material";
// Add other necessary imports
import { ActivitiesInterface } from "../interface/ActivitiesInterface";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import WhatshotIcon from "@mui/icons-material/Whatshot";
interface Activities {
  activities: ActivitiesInterface[];
  currentDate: Date;
}

function ActivitiesCard({ activities, currentDate }: Activities) {
  return (
    <>
      {activities.map((activity: ActivitiesInterface) => (
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Paper
            elevation={3}
            style={{
              padding: "10px",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",

              marginTop: 50,
              width: "80%",
            }}
          >
            <Container
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontWeight: "bold" }}>
                {activity.name}
              </Typography>
              <Divider style={{ marginTop: 5, width: "99%" }} />
            </Container>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 10,
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <AccessTimeIcon style={{ color: "#1067bf" }} />
                  <Typography style={{ marginLeft: 5 }}>Duration :</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <WhatshotIcon style={{ color: "#FFA500" }} />
                  <Typography style={{ marginLeft: 5 }}>
                    Calories burnt :
                  </Typography>
                </div>
              </div>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography style={{ textAlign: "center" }}>
                    {Math.floor(activity.duration / 60)} min
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography style={{ textAlign: "center" }}>
                    {activity.caloriesBurned ? activity.caloriesBurned : "-"}
                  </Typography>
                </div>
              </Container>
            </Box>
          </Paper>
        </Container>
      ))}
    </>
  );
}

export default memo(ActivitiesCard);
