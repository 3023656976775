import { config } from "../shared/constants/config";
import { NutritionLogResponse } from "../interface/NutritionLogInterface";
import { fetchWrapper } from "../helpers";
import { SingleRecipe } from "../interface/RecipeInterface";
import { accountService } from "./account.service";
const baseUrl = `${config.apiUrl}/api`;
export const recipeService = {
  getSingleRecipesFromAi,

  getRecipesFromDb,
  addRecipes,
  getImageForDalle,
  deleteRecipes,
  tranferRecipesToProd,
  updateRecipePrompt,
  getRecipePromptFromJson,
  updateRecipe,
  updateRecipeManually,
  getUniqueCategories,
};

//// Function For handeling the api for getting the nutrition logs-----------------/
async function getSingleRecipesFromAi(
  prompt: string,
  recipeDetails: string
): Promise<any> {
  return fetchWrapper.post(`${baseUrl}/get-recipe-details`, {
    prompt,
    recipeDetails,
  });
}

//// Function For handeling the unique categories-----------------/
async function getUniqueCategories(): Promise<any> {
  return fetchWrapper.get(`${baseUrl}/get-categories`);
}

//// Function For handeling the api for getting the recipes from JsonFile----------/
async function getRecipesFromDb(dbType: string) {
  return fetchWrapper.get(`${baseUrl}/get-recipes?dbType=${dbType}`);
}

/// Function for handeling the api for adding the recipes in the saved db-----------/
async function addRecipes(dbType: string, recipeData: any) {
  return fetchWrapper.post(`${baseUrl}/add-recipes`, {
    recipeData: recipeData,
    dbType: dbType,
  });
}

/// Function for handeling the api for updating the recipe in the  db-----------/
async function updateRecipe(dbType: string, recipeData: any) {
  return fetchWrapper.put(`${baseUrl}/update-recipe`, {
    recipeData: { ...recipeData },
    dbType: dbType,
  });
}

/// Function for handeling the api for transfering the recipes to prod-----------/
async function tranferRecipesToProd(recipeData: any) {
  return fetchWrapper.put(`${baseUrl}/add-toprod-recipes`, {
    recipeData: recipeData,
  });
}

async function getImageForDalle(imagePrompt: string[], recipeType: string) {
  return fetchWrapper.post(`${baseUrl}/get-recipe-images`, {
    imagePrompt,
    recipeType,
  });
}
/// Function for handeling the api for deleting the recipes in the mongo-----------/
async function deleteRecipes(dbType: string, idArray: any) {
  return fetchWrapper.post(`${baseUrl}/delete-recipes`, {
    dbType: dbType,
    idArray: idArray,
  });
}
//// function for updating the subscription data---------------------------/
async function updateRecipePrompt(data: any, type: string) {
  let finalData = { ...data, type: type };
  return fetchWrapper.post(`${baseUrl}/update-recipe-prompt`, {
    ...finalData,
  });
}

//// function for updating the subscription data---------------------------/
async function updateRecipeManually(data: any) {
  return fetchWrapper.put(`${baseUrl}/edit-recipe-manually`, {
    ...data,
  });
}

//// Funciton for getting the recipe data ----------------------------------------------------------------------------/

async function getRecipePromptFromJson() {
  // New: GET request to the specified endpoint
  const cachebuster = Math.floor(Math.random() * 200) + 1;
  const storageNameResponse = await accountService.getStorageName();
  const response = await fetch(
    `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/recipe-prompts.json?cachebuster=${cachebuster}`
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json(); // Assuming the response is JSON

  // Here you can combie or manipulate the data from both requests as needed
  // For this examnple, we're just returning the JSON data from the GET request
  return data;
}
