/** @format */

import { ActivitiesInterface } from "../interface/ActivitiesInterface";

//// Exporting the activities utility function-------------------------------------------/
export const activitiesUtilityFunctions = {
  getCurrentDayActivities,
};

///// Function For matching the date of activities -------------------------------------/

function isSameDay(date1: Date, date2: Date) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

//// Function for getting the activities of a particular day------------------------------/
async function getCurrentDayActivities(
  activities: ActivitiesInterface[],
  currentDate: Date
): Promise<ActivitiesInterface[] | []> {
  try {
    const activitiesToShow = activities.filter((activity) =>
      isSameDay(new Date(activity.dateTime), currentDate)
    );
    if (activitiesToShow.length > 0) {
      return activitiesToShow;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}
