import {
  Avatar,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import EggIcon from "@mui/icons-material/Egg";
interface SingleMeal {
  row: any;
  setImageUrl: any;
  setImageModal: any;
}
const placeholderImage = require("../assets/placeholder.png");
export default function SingleMeal({
  row,
  setImageModal,
  setImageUrl,
}: SingleMeal) {
  const [expanded, setExpanded] = React.useState(false);
  const [imageError, setImageError] = React.useState(false);

  const handleToggleClick = () => {
    setExpanded(!expanded);
    setImageError(false);
  };
  const handleError = (e: any) => {
    e.target.src = placeholderImage; // Path to your placeholder image
    setImageError(true);
  };
  return (
    <div
      style={{
        marginBottom: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card style={{ width: "90%" }}>
        <CardContent style={{ display: expanded ? "none" : "block" }}>
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontWeight: "bold" }}>
              {row.mealName}
            </Typography>
          </Container>
          <Divider style={{ width: "98%" }} />
          <Grid container style={{ marginTop: 10 }}>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              {/* Half width */}
              <div
                style={{
                  width: "100%",
                  height: 150,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  onClick={() => {
                    if (imageError || row.image === placeholderImage) return; // Check if imageError occurred or if the image is the placeholder image
                    setImageUrl(row.image);
                    setImageModal(true);
                  }}
                  src={row.image}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }} // Adjusted style
                  onError={handleError}
                  alt="image"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {" "}
              {/* Half width */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <WhatshotIcon
                      style={{ marginRight: 5, color: "#1067bf", fontSize: 20 }}
                    />
                    <Typography variant="body2" align="center">
                      Total Calories:
                    </Typography>
                  </div>

                  <Typography variant="body2" align="center">
                    {row.singleMealData.calorie}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <EggIcon
                      style={{ marginRight: 5, color: "#FFD180", fontSize: 20 }}
                    />
                    <Typography variant="body2" align="center">
                      Total Protein:{" "}
                    </Typography>
                  </div>
                  <Typography variant="body2" align="center">
                    {row.singleMealData.protein != null
                      ? Number.isInteger(row.singleMealData.protein)
                        ? row.singleMealData.protein
                        : parseFloat(row.singleMealData.protein.toFixed(1))
                      : ""}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <div
            onClick={handleToggleClick}
            style={{
              cursor: "pointer",
              alignItems: "center",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Typography style={{ marginRight: "2%" }}>Show</Typography>
            <Avatar
              style={{ backgroundColor: "grey", width: "20px", height: "20px" }}
            >
              <KeyboardArrowDownIcon
                style={{ fontSize: "20px", color: "white" }}
                onClick={handleToggleClick}
              />
            </Avatar>
          </div>
        </CardContent>
        {expanded && (
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                textAlign: "center",
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              {row.mealName}
            </Typography>
            <Divider style={{ width: "98%" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Calories</Typography>
              </div>
              <div>
                <Typography>{row.singleMealData.calorie} kcals</Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Protein</Typography>
              </div>
              <div>
                <Typography>
                  {" "}
                  {row.singleMealData.protein != null
                    ? Number.isInteger(row.singleMealData.protein)
                      ? row.singleMealData.protein
                      : parseFloat(row.singleMealData.protein.toFixed(1))
                    : ""}{" "}
                  g
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Quantity</Typography>
              </div>
              <div>
                <Typography>
                  {" "}
                  {row.singleMealData.quantity != null &&
                  row.singleMealData.quantity !== 0
                    ? Number.isInteger(row.singleMealData.quantity)
                      ? row.singleMealData.quantity
                      : parseFloat(row.singleMealData.quantity.toFixed(1))
                    : "-"}{" "}
                  {row.singleMealData.quantity !== 0 &&
                  row.singleMealData.units === "g"
                    ? "grams"
                    : row.singleMealData.quantity !== 0
                    ? row.singleMealData.units
                    : ""}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Carbohydrates</Typography>
              </div>
              <div>
                <Typography>
                  {" "}
                  {row.singleMealData.carbohydrates != null
                    ? Number.isInteger(row.singleMealData.carbohydrates)
                      ? row.singleMealData.carbohydrates
                      : parseFloat(row.singleMealData.carbohydrates.toFixed(1))
                    : ""}{" "}
                  g
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Fat</Typography>
              </div>
              <div>
                <Typography>
                  {" "}
                  {row.singleMealData.fat != null
                    ? Number.isInteger(row.singleMealData.fat)
                      ? row.singleMealData.fat
                      : parseFloat(row.singleMealData.fat.toFixed(1))
                    : ""}{" "}
                  g
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Fiber</Typography>
              </div>
              <div>
                <Typography>
                  {" "}
                  {row.singleMealData.fiber != null
                    ? Number.isInteger(row.singleMealData.fiber)
                      ? row.singleMealData.fiber
                      : parseFloat(row.singleMealData.fiber.toFixed(1))
                    : ""}{" "}
                  g
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Sugar</Typography>
              </div>
              <div>
                <Typography>
                  {row.singleMealData.sugar != null
                    ? Number.isInteger(row.singleMealData.sugar)
                      ? row.singleMealData.sugar
                      : parseFloat(row.singleMealData.sugar.toFixed(1))
                    : ""}{" "}
                  g
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Poly Saturated Fat</Typography>
              </div>
              <div>
                <Typography>
                  {row.singleMealData.poly_unsaturated_fat != null
                    ? Number.isInteger(row.singleMealData.poly_unsaturated_fat)
                      ? row.singleMealData.poly_unsaturated_fat
                      : parseFloat(
                          row.singleMealData.poly_unsaturated_fat.toFixed(1)
                        )
                    : ""}{" "}
                  g
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Mono Saturated Fat</Typography>
              </div>
              <div>
                <Typography>
                  {row.singleMealData.mono_unsaturated_fat != null
                    ? Number.isInteger(row.singleMealData.mono_unsaturated_fat)
                      ? row.singleMealData.mono_unsaturated_fat
                      : parseFloat(
                          row.singleMealData.mono_unsaturated_fat.toFixed(1)
                        )
                    : ""}{" "}
                  g
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Saturated Fat</Typography>
              </div>
              <div>
                <Typography>
                  {row.singleMealData.saturated_fat != null
                    ? Number.isInteger(row.singleMealData.saturated_fat)
                      ? row.singleMealData.saturated_fat
                      : parseFloat(row.singleMealData.saturated_fat.toFixed(1))
                    : ""}{" "}
                  g
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography>Trans Fat</Typography>
              </div>
              <div>
                <Typography>
                  {row.singleMealData.trans_fat != null
                    ? Number.isInteger(row.singleMealData.trans_fat)
                      ? row.singleMealData.trans_fat
                      : parseFloat(row.singleMealData.trans_fat.toFixed(1))
                    : ""}{" "}
                  g
                </Typography>
              </div>
            </div>
            <div
              style={{
                cursor: "pointer",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "90%",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: "grey",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  <KeyboardArrowUpIcon
                    style={{ fontSize: "30px", color: "white" }}
                    onClick={handleToggleClick}
                  />
                </Avatar>
              </div>
            </div>
          </CardContent>
        )}
      </Card>
    </div>
  );
}
