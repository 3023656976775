//// function for selecting the exercise for a particular month -------------------/
export async function weekExerciseDetails(exercise: any, currentDate: any) {
  // Clone the exercise object to avoid mutation

  const clonedExercise = { ...exercise };

  // Get the start and end dates of the previous 7 days including the current date
  const currentDateObj = new Date(currentDate);
  const firstDayOfWeek = new Date(currentDateObj);
  firstDayOfWeek.setDate(currentDateObj.getDate() - 6); // Go back 6 days to include the current date
  firstDayOfWeek.setHours(0, 0, 0, 0); // Set time to start of the day (midnight)

  const lastDayOfWeek = new Date(currentDateObj);
  lastDayOfWeek.setHours(23, 59, 59, 999); // Set time to end of the day (just before midnight)

  // Filter sets based on the previous 7 days
  const setsInWeek = clonedExercise.sets.filter((set: any) => {
    const setDateTime = new Date(set.dateTime);
    return setDateTime >= firstDayOfWeek && setDateTime <= lastDayOfWeek;
  });

  // Transform sets into the desired format
  const formattedExercises: any[] = [];

  setsInWeek.forEach((set: any) => {
    let weight = set.weight !== null ? set.weight : "Bodyweight";

    if (set.weightUnit === "kg" && set.weight != null) {
      // Convert weight to pounds if weightUnit is "kg"
      weight = (set.weight * 2.20462).toFixed(1);
    }

    // Format the date as "DD/MM"
    const setDateTime = new Date(set.dateTime);
    const formattedDate = `${setDateTime
      .getDate()
      .toString()
      .padStart(2, "0")}/${(setDateTime.getMonth() + 1)
      .toString()
      .padStart(2, "0")}`;

    // Find if the date object already exists in the formattedExercises array
    const existingDateIndex = formattedExercises.findIndex(
      (dateObj) => dateObj.dateTime === formattedDate
    );
    if (existingDateIndex === -1) {
      // If the date object does not exist, create a new one and add it to the array
      formattedExercises.push({
        dateTime: formattedDate,
        rep: [set.reps],
        [`set1`]: { weight: weight, rep: set.reps },
      });
    } else {
      // If the date object already exists, add weight and rep to the existing set
      const setIndex = formattedExercises[existingDateIndex].rep.length + 1;
      formattedExercises[existingDateIndex][`set${setIndex}`] = {
        weight: weight,
        rep: set.reps,
      };
      formattedExercises[existingDateIndex].rep.push(set.reps);
    }
  });

  // Calculate the date range
  const startDateString = `${firstDayOfWeek
    .getDate()
    .toString()
    .padStart(2, "0")}/${(firstDayOfWeek.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${firstDayOfWeek.getFullYear()}`;
  const endDateString = `${lastDayOfWeek
    .getDate()
    .toString()
    .padStart(2, "0")}/${(lastDayOfWeek.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${lastDayOfWeek.getFullYear()}`;

  // Create the final response object with formattedExercise, biggestArray, and date range
  const finalResponse = {
    formattedExercise: formattedExercises,
    biggestArray: Array.from(
      {
        length: formattedExercises.reduce(
          (max, obj) =>
            Math.max(
              max,
              Object.keys(obj).filter((key) => key.startsWith("set")).length
            ),
          0
        ),
      },
      (_, i) => i + 1
    ),
    dateRange: {
      startDate: startDateString,
      endDate: endDateString,
    },
  };

  // Return the final response
  return finalResponse;
}

//// function for selecting the exercise for a particular month -------------------/
export async function weekCardioDetails(exercise: any, currentDate: any) {
  // Clone the exercise object to avoid mutation

  const clonedExercise = { ...exercise };

  // Get the start and end dates of the current week
  const currentDateObj = new Date(currentDate);
  const firstDayOfWeek = new Date(currentDateObj);
  firstDayOfWeek.setDate(currentDateObj.getDate() - 6); // Go back 6 days to include the current date
  firstDayOfWeek.setHours(0, 0, 0, 0); // Set time to start of the day (midnight)

  const lastDayOfWeek = new Date(currentDateObj);
  lastDayOfWeek.setHours(23, 59, 59, 999); // Set time to end of the day (just before midnight)

  // Filter sets based on the current week
  const setsInWeek = clonedExercise.sets.filter((set: any) => {
    const setDateTime = new Date(set.dateTime);
    return setDateTime >= firstDayOfWeek && setDateTime <= lastDayOfWeek;
  });

  // Transform sets into the desired format
  const formattedExercises: any[] = [];

  setsInWeek.forEach((set: any) => {
    const durationInMinutes = Math.round(set.set_duration / 60); // Convert duration from seconds to minutes

    // Format the date as "DD/MM"
    const setDateTime = new Date(set.dateTime);
    const formattedDate = `${setDateTime.getDate()}/${
      setDateTime.getMonth() + 1
    }`;

    // Find if the date object already exists in the formattedExercises array
    const existingDateIndex = formattedExercises.findIndex(
      (dateObj) => dateObj.dateTime === formattedDate
    );
    if (existingDateIndex === -1) {
      // If the date object does not exist, create a new one and add it to the array
      formattedExercises.push({
        dateTime: formattedDate,
        duration: [durationInMinutes],
        [`set1`]: { duration: durationInMinutes },
      });
    } else {
      // If the date object already exists, add duration to the existing set
      const setIndex =
        formattedExercises[existingDateIndex].duration.length + 1;
      formattedExercises[existingDateIndex][`set${setIndex}`] = {
        duration: durationInMinutes,
      };
      formattedExercises[existingDateIndex].duration.push(durationInMinutes);
    }
  });

  let maxSetsCount = 0;
  formattedExercises.forEach((exercise) => {
    const exerciseSetsCount = Object.keys(exercise).filter((key) =>
      key.startsWith("set")
    ).length;
    if (exerciseSetsCount > maxSetsCount) {
      maxSetsCount = exerciseSetsCount;
    }
  });

  let biggestArray: number[] = [];
  if (maxSetsCount > 0) {
    biggestArray = Array.from({ length: maxSetsCount }, (_, i) => i + 1);
  }
  // Calculate the date range
  const startDateString = `${firstDayOfWeek
    .getDate()
    .toString()
    .padStart(2, "0")}/${(firstDayOfWeek.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${firstDayOfWeek.getFullYear()}`;
  const endDateString = `${lastDayOfWeek
    .getDate()
    .toString()
    .padStart(2, "0")}/${(lastDayOfWeek.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${lastDayOfWeek.getFullYear()}`;

  // Create the final response object with formattedExercise and biggestArray
  const finalResponse = {
    formattedExercise: formattedExercises,
    biggestArray: biggestArray,
    dateRange: {
      startDate: startDateString,
      endDate: endDateString,
    },
  };

  // Return the final response
  return finalResponse;
}
