interface WorkoutPlanErrors {
  planName?: string;
  startDate?: string;
  endDate?: string;
  workouts?: string;

  // Dynamic keys for workout and exercise errors
  [key: string]: string | undefined;
}

export const validateWorkoutPlan = (
  workoutPlan: any,
  planNameInput: any,
  firstSelectedDate: any
) => {
  let errors: WorkoutPlanErrors = {};
  // Validation for checking whethere the start date is not > end date or vice versa
  if (workoutPlan.startDate && workoutPlan.endDate) {
    /// Only inside this when both the dates has been given

    const startDate = new Date(workoutPlan.startDate);
    const endDate = new Date(workoutPlan.endDate);

    /// only will go inside if when startDate is > endDate (that is not practially possible : start date will always be lower then the end date)
    if (startDate > endDate) {
      /// Storing the date of when both the dates has been changed by the user----------/
      const startDateTime = new Date(firstSelectedDate.startDateTime);
      const endDateTime = new Date(firstSelectedDate.endDateTime);

      /// Comparison so that error can be shown on the field that has been updated later
      /// Means if startDate has changed before then error we have to show on endDate field and vice versa
      if (startDateTime < endDateTime) {
        errors.endDate = "End date must be after the start date";
      } else {
        errors.startDate = "Start date must be before the end date";
      }
    }
  }
  //------------------------------------------------------------ Validate plan name
  if (!planNameInput.trim()) {
    errors.planName = "Plan name is required";
  } else {
    if (workoutPlan.workouts.length === 0 && errors.planName !== "") {
      /// When user has not selected any single workout----------------------------/
      errors.workouts = "At least one workout is required";
    } else if (workoutPlan.workouts.length > 0) {
      /// Loop through every workout to check for which workout we need to return the error---/
      workoutPlan.workouts.forEach((workout: any, workoutIndex: any) => {
        if (!workout.name.trim()) {
          errors[`workoutName_${workoutIndex}`] = "Workout name is required";
        }

        // Validate exercises within each workout
        if (
          workout.exercises.length === 0 &&
          !errors[`workoutName_${workoutIndex}`]
        ) {
          /// When user has not selected any exercise for that workout-------------/
          errors.exercise = "At least one exercise is required";
        } else {
          /// Loop through each exercise to throw error for that exercise on which error has happened
          workout.exercises.forEach((exercise: any, exerciseIndex: any) => {
            if (!exercise.id) {
              errors[`exerciseId_${workoutIndex}_${exerciseIndex}`] =
                "Exercise Name is required";
            }
          });
        }
      });
    }
  }

  return errors;
};
