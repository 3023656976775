// src/msalConfig.js
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "1dea8848-9f07-4bf0-8812-f7d44625fbdb",
    authority:
      "https://login.microsoftonline.com/2440e181-e44b-4835-94dc-76dd5b24c62a",
    redirectUri: "https://trainer.reshapeapp.ai/redirect.html",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
