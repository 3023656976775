import { Container } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import SetTrackModal from "./ExerciseTrackModal";
interface ExerciseHistory {
  workoutData: any;
  currentDate: any;

  isLast: boolean;
}

//// Function For The when meal has ingredients----------------------------------------------/
const CollapsibleExercises: React.FC<{
  open: any;
  exerciseData: any;


}> = ({ open, exerciseData}) => {
  const [setTrackModalOpen, setSetTrackModalOpen] = useState(false); // State to manage modal open/close

  const handleTrackButtonClick = () => {
    setSetTrackModalOpen(true); // Open modal when track button is clicked
  };
  return(
  <Collapse in={open} timeout="auto" unmountOnExit >
  <TableRow >
    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
     
        <Box sx={{ margin: 1 }}>
          <Typography variant="h6" gutterBottom component="div">
            Exercise Name
          </Typography>
          <Table size="small" aria-label="purchases">
            <TableBody>
              {exerciseData.map((exercise: any, index: any) => (
                <Box
                  key={index}
                  sx={{
                    marginBottom: 2,
                    border: "1px solid #e0e0e0",
                    borderRadius: "4px",
                  }}
                >
                  <TableRow style={{display:"flex",flexDirection:"row",width:"100%",}}>
                    <TableCell style={{display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center",width:"100%"}}>
                      <Typography style={{ fontWeight: "500",textAlign:"center" }}>
                        {exercise.exercise}
                      </Typography>
                      <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                      <div style={{ backgroundColor: "skyblue", padding: "1px", borderRadius: "4px" }}>
  <Typography style={{ fontWeight: "500",fontSize:13,marginLeft:2,marginRight:2 }}>
    {exercise.category}
  </Typography>
  
</div>               <div style={{ backgroundColor: "#FF0000", padding: "1px", borderRadius: "4px",marginLeft:5,width:50 }}>
  <Typography style={{ fontWeight: "500",fontSize:13,textAlign:"center",color:"white" }}>
    {exercise.assignedSets} {" "} {"sets"}
  </Typography>
  
</div>
                    </div>
                    </TableCell>
                   
                    {/* ... more cells for other properties ... */}
                  </TableRow>
                </Box>
              ))}
            </TableBody>
          </Table>
        </Box>
    
    </TableCell>

  </TableRow>  </Collapse>)};
export default function TrainerWorkouts({
  workoutData,
  currentDate,

}: ExerciseHistory) {

  const [open, setOpen] = useState(false);
 



  const handleDropdownKeyClick = () => {
    setOpen(!open);

  };

 
  
  return (
    <>
      <TableRow
        key={workoutData.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{
            width: "50%",
            position: "sticky",
            top: 0,
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {workoutData.exercises && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={handleDropdownKeyClick}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
            <Typography style={{ marginLeft: 5,fontWeight:"bold" }}>
              {" "}
              {workoutData.workout_name}
            </Typography>
          </div>
        </TableCell>
      </TableRow>
   
      {workoutData.exercises && (
        <CollapsibleExercises
          open={open}
          exerciseData={workoutData.exercises}
         
       
        />
      )}
    
    </>
  );
}
