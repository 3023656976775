/** @format */

import { memo, useEffect, useState } from "react";

import {
  Modal,
  Box,
  Typography,
  Button,
  Stack,
  IconButton,
  Divider,
  FormControl,
  FormControlLabel,
  Checkbox,
  Collapse,
  FormGroup,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import { NutritionLogDataInterface } from "../interface/NutritionLogInterface";

interface SortingModal {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  totalnutritionLogDetails: NutritionLogDataInterface[];
  nutritionLogDetails: NutritionLogDataInterface[];
  setNutritionLogDetails: any;
  setFilteredType: any;
  filteredType: any;
  filteredStatus: any;
  setFilteredStatus: any;
}

function NutritionFilterModal({
  modalOpen,
  setModalOpen,
  filteredType,
  setFilteredType,

  totalnutritionLogDetails,
  setNutritionLogDetails,
  filteredStatus,
  setFilteredStatus,
}: SortingModal) {
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  /// Filterattion Function for type------------------------------------------------------------------->
  const typeShow = (type: any) => {
    let filteredData;

    if (type == null) {
      // If the same type button is clicked again, clear the filter
      setFilteredType("");
      filteredData = totalnutritionLogDetails;
    } else {
      setFilteredType(type);
      // Filter data based on the selected type
      filteredData = totalnutritionLogDetails.filter(
        (detail: NutritionLogDataInterface) => detail.type === type
      );
    }

    if (filteredStatus !== "") {
      // If status filter is active, further filter data based on status
      filteredData = filteredData.filter(
        (detail: NutritionLogDataInterface) => detail.status === filteredStatus
      );
    }

    setNutritionLogDetails(filteredData);
  };

  const statusShow = (status: string | null) => {
    let filteredData;

    if (status == null) {
      // If the same status button is clicked again, clear the filter
      setFilteredStatus("");
      filteredData = totalnutritionLogDetails;
    } else {
      setFilteredStatus(status);
      // Filter data based on the selected status
      filteredData = totalnutritionLogDetails.filter(
        (detail: NutritionLogDataInterface) => detail.status === status
      );
    }

    if (filteredType !== "") {
      // If type filter is active, further filter data based on type
      filteredData = filteredData.filter(
        (detail: NutritionLogDataInterface) => detail.type === filteredType
      );
    }

    setNutritionLogDetails(filteredData);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%", // Adjusted width for small screens
          maxWidth: 400, // Max width for larger screens
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          id="modal-title"
          style={{ fontWeight: "800", marginBottom: 3 }}
        >
          Type
        </Typography>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={filteredType}
            exclusive
            onChange={(event, newType) => typeShow(newType)} // Use an anonymous function to pass the new type
            aria-label="Filter by type"
            sx={{ width: "100%" }} // This makes the entire button group take full width of its container
          >
            <ToggleButton value="voice" sx={{ width: "50%" }}>
              Voice
            </ToggleButton>
            <ToggleButton value="image" sx={{ width: "50%" }}>
              Image
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            color="primary"
            value={filteredStatus}
            exclusive
            onChange={(event, newStatus) => statusShow(newStatus)} // Use an anonymous function to pass the new type
            aria-label="Filter by type"
            sx={{ width: "100%" }} // This makes the entire button group take full width of its container
          >
            <ToggleButton value="saved" sx={{ width: "33%" }}>
              Saved
            </ToggleButton>
            <ToggleButton value="not_saved" sx={{ width: "33%" }}>
              Not Saved
            </ToggleButton>
            <ToggleButton value="error" sx={{ width: "33%" }}>
              Error
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Box>
    </Modal>
  );
}

export default memo(NutritionFilterModal);
